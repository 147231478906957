/* eslint-disable @typescript-eslint/no-explicit-any */
import fastMemoize from 'fast-memoize'

import { bool } from './styled-props'
import background from '../styles-system/background'
import border from '../styles-system/border'
import color from '../styles-system/color'
import emSpace from '../styles-system/em-space'
import flexbox from '../styles-system/flexbox'
import grid from '../styles-system/grid'
import layout from '../styles-system/layout'
import position from '../styles-system/position'
import shadow from '../styles-system/shadow'
import space from '../styles-system/space'
import stylable from '../styles-system/stylable'
import { systemProps } from '../styles-system/system-props'
import typography from '../styles-system/typography'

const props = fastMemoize((isSvg?: boolean) => [
  ...systemProps(isSvg),
  'sx',
  'variant'
])

const paddingXProps = [
  'p',
  'px',
  'pl',
  'pr',
  'paddingX',
  'paddingLeft',
  'paddingRight',
  'emPaddingX',
  'emPaddingLeft',
  'emPaddingRight'
]

const paddingYProps = [
  'p',
  'py',
  'pt',
  'pb',
  'paddingY',
  'paddingTop',
  'paddingBottom',
  'emPaddingY',
  'emPaddingTop',
  'emPaddingBottom'
]

const marginXProps = [
  'm',
  'mx',
  'ml',
  'mr',
  'marginX',
  'marginLeft',
  'marginRight',
  'emMarginX',
  'emMarginLeft',
  'emMarginRight'
]

const marginYProps = [
  'm',
  'my',
  'mt',
  'mb',
  'marginY',
  'marginTop',
  'marginBottom',
  'emMarginY',
  'emMarginTop',
  'emMarginBottom'
]

const positionProps = [...position.propNames]
const layoutPropNames = [...layout.propNames, 'gap']
const typographyPropName = [...typography.propNames]
const flexboxPropNames = [...flexbox.propNames, 'gap']
export const spacePropNames = [...space.propNames, ...emSpace.propNames]
const gridPropNames = [...grid.propNames]
const borderPropNames = [...border.propNames, 'boxShadow']
const backgroundPropNames = [...background.propNames]
const colorPropNames = [...color.propNames]
const shadowPropNames = [...shadow.propNames]
const stylablePropsNames = [...stylable.propNames]
const svgPropNames = ['fill', 'stroke', 'strokeWidth', 'fillRule']
const styleSystemPropNames = [
  ...positionProps,
  ...layoutPropNames,
  ...typographyPropName,
  ...flexboxPropNames,
  ...spacePropNames,
  ...borderPropNames,
  ...backgroundPropNames,
  ...colorPropNames,
  ...shadowPropNames,
  ...stylablePropsNames,
  ...gridPropNames
]
const themePropNames = ['size', 'variant']

const _getProps =
  (test: (v: string) => boolean) =>
  (
    props: {
      [x: string]: any
    } = {}
  ) => {
    const next: any = {}
    for (const key in props) {
      if (test(key || '')) (next as any)[key] = props[key]
    }
    return next
  }
const PRE = (isSvg?: boolean) => new RegExp(`^(${props(isSvg).join('|')})$`)
const TYPOGRAPHY = new RegExp(`^(${typographyPropName.join('|')})$`)
const FLEX = new RegExp(`^(${flexboxPropNames.join('|')})$`)
const PADDING = new RegExp(
  `^(${[...new Set([...paddingXProps, ...paddingYProps])].join('|')})$`
)
const PADDING_X = new RegExp(`^(${paddingXProps.join('|')})$`)
const PADDING_Y = new RegExp(`^(${paddingYProps.join('|')})$`)
const MARGIN = new RegExp(
  `^(${[...new Set([...marginXProps, ...marginYProps])].join('|')})$`
)
const MARGIN_X = new RegExp(`^(${marginXProps.join('|')})$`)
const MARGIN_Y = new RegExp(`^(${marginYProps.join('|')})$`)
const POSITION = new RegExp(`^(${positionProps.join('|')})$`)
const SPACE = new RegExp(`^(${spacePropNames.join('|')})$`)
const LAYOUT = new RegExp(`^(${layoutPropNames.join('|')})$`)
const GRID = new RegExp(`^(${gridPropNames.join('|')})$`)
const BORDER = new RegExp(`^(${borderPropNames.join('|')})$`)
const BACKGROUND = new RegExp(`^(${backgroundPropNames.join('|')})$`)
const COLOR = new RegExp(`^(${colorPropNames.join('|')})$`)
const ARIA = new RegExp('aria-\\w')
const DATA = new RegExp('data-\\w')
const SVG = new RegExp(`^(${svgPropNames.join('|')})$`)
const SHADOW = new RegExp(`^(${shadowPropNames.join('|')})$`)
const STYLE_SYSTEM = new RegExp(`^(${styleSystemPropNames.join('|')})$`)
const THEME_PROPS = new RegExp(`^(${themePropNames.join('|')})$`)
const THEMEABLE_PROPS = new RegExp(
  `^(${[
    'resetCss',
    'css',
    '__css',
    '__themeKey',
    'type',
    '__inputProps',
    'state',
    'disableBorderEffect',
    'numberOfColumnGrouping',
    ...styleSystemPropNames,
    ...themePropNames
  ].join('|')})$`
)

export const getSystemProps = (isSvg?: boolean): any =>
  _getProps(k => PRE(isSvg).test(k))
export const getTypography = _getProps(k => TYPOGRAPHY.test(k))
export const omitTypography = _getProps(k => !TYPOGRAPHY.test(k))

export const getFlex = _getProps(k => FLEX.test(k))
export const omitFlex = _getProps(k => !FLEX.test(k))
export const getPadding = _getProps(k => PADDING.test(k))
export const omitPadding = _getProps(k => !PADDING.test(k))
export const getPaddingX = _getProps(k => PADDING_X.test(k))
export const omitPaddingX = _getProps(k => !PADDING_X.test(k))
export const getPaddingY = _getProps(k => PADDING_Y.test(k))
export const omitPaddingY = _getProps(k => !PADDING_Y.test(k))
export const getMargin = _getProps(k => MARGIN.test(k))
export const omitMargin = _getProps(k => !MARGIN.test(k))
export const getMarginX = _getProps(k => MARGIN_X.test(k))
export const omitMarginX = _getProps(k => !MARGIN_X.test(k))
export const getMarginY = _getProps(k => MARGIN_Y.test(k))
export const omitMarginY = _getProps(k => !MARGIN_Y.test(k))
export const getSpace = _getProps(k => SPACE.test(k))
export const omitSpace = _getProps(k => !SPACE.test(k))
export const getLayout = _getProps(k => LAYOUT.test(k))
export const omitLayout = _getProps(k => !LAYOUT.test(k))
export const getPosition = _getProps(k => POSITION.test(k))
export const omitPosition = _getProps(k => !POSITION.test(k))
export const getGrid = _getProps(k => GRID.test(k))
export const omitGrid = _getProps(k => !GRID.test(k))
export const getBorder = _getProps(k => BORDER.test(k))
export const omitBorder = _getProps(k => !BORDER.test(k))
export const getBackground = _getProps(k => BACKGROUND.test(k))
export const omitBackground = _getProps(k => !BACKGROUND.test(k))
export const getColor = _getProps(k => COLOR.test(k))
export const omitColor = _getProps(k => !COLOR.test(k))
export const getAriaPrefix = _getProps(k => ARIA.test(k))
export const getDataPrefix = _getProps(k => DATA.test(k))
export const getSvg = _getProps(k => SVG.test(k))
export const omitSvg = _getProps(k => !SVG.test(k))
export const getShadow = _getProps(k => SHADOW.test(k))
export const omitShadow = _getProps(k => !SHADOW.test(k))
export const getStyleSystemProps = _getProps(k => STYLE_SYSTEM.test(k))
export const omitStyleSystemProp = _getProps(k => !STYLE_SYSTEM.test(k))
export const omitThemeProps = _getProps(k => !THEME_PROPS.test(k))
export const getThemeableProps = _getProps(k => THEMEABLE_PROPS.test(k))

export const positions = bool(
  'position',
  ['static', 'absolute', 'fixed', 'relative', 'sticky'],
  true
)

export function containsOmitPropSymbol(props: { [x: string]: any }): boolean {
  return Object.keys(props).some(p => props[p] === '-')
}

export function propValueIsSet(
  props: { [x: string]: any },
  field: string | string[]
): boolean {
  function test(f: string) {
    return f in props && (props[f] === 0 || typeof props[f] === 'string')
  }
  return Array.isArray(field) ? field.some(test) : test(field)
}

export function propsEqualityCheck(name: string) {
  return function (prevProps: any, nextProps: any): boolean {
    const nextPropsKeys = Object.keys(nextProps)
    const prevPropsKeys = Object.keys(prevProps)
    if (nextPropsKeys.length !== prevPropsKeys.length) {
      // eslint-disable-next-line no-console
      console.log(` ---- ${name} props are not equal ----`)
      return false
    }
    return nextPropsKeys.every(k => {
      const isEqual = (prevProps as any)[k] === (nextProps as any)[k]
      if (!isEqual) {
        // eslint-disable-next-line no-console
        console.log(
          ` ---- ${name} [${k}] not equal \r prevProps: ${
            (prevProps as any)[k]
          } \r nextProps: ${(nextProps as any)[k]} \r ----`
        )
      }
      return isEqual
    })
  }
}
