/* eslint-disable @typescript-eslint/ban-ts-comment */

export const styles = {
  root: {
    variant: 'text.body',
    color: 'text'
  },
  h1: {
    variant: 'text.heading',
    fontSize: 'sizes.heading.h1'
  },
  h2: {
    variant: 'text.heading',
    fontSize: 'sizes.heading.h2'
  },
  h3: {
    variant: 'text.heading',
    fontSize: 'sizes.heading.h3'
  },
  h4: {
    variant: 'text.heading',
    fontSize: 'sizes.heading.h4'
  },
  h5: {
    variant: 'text.heading',
    fontSize: 'sizes.heading.h5'
  },
  h6: {
    variant: 'text.heading',
    fontSize: 'sizes.heading.h6'
  },
  p: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
    emMarginBottom: 'sizes.space.md',
    '&:last-child': {
      margin: 0
    }
  },
  a: {
    color: 'primary',
    cursor: 'pointer',
    '&:hover': {
      color: 'secondary'
    }
  },
  pre: {
    fontFamily: 'monospace',
    p: 'sizes.space.md',
    color: 'text',
    bg: 'muted',
    overflow: 'auto',
    code: {
      color: 'inherit'
    }
  },
  code: {
    fontFamily: 'monospace'
  },
  inlineCode: {
    fontFamily: 'monospace',
    fontSize: 'xs',
    color: 'black',
    bg: 'muted',
    px: 'xxs',
    border: '1px solid #a6a6a6',
    borderRadius: 'default'
  },
  kbd: {
    bg: 'text',
    borderRadius: 'default',
    color: 'white',
    fill: 'white',
    paddingX: '.25em',
    paddingY: '.1em',
    fontFamily: 'monospace'
  },
  samp: {
    fontFamily: 'monospace'
  },
  table: {
    width: '100%',
    my: 'sizes.space.md',
    borderCollapse: 'separate',
    borderSpacing: 0,
    // @ts-ignore
    [['th', 'td']]: {
      textAlign: 'left',
      py: '4px',
      pr: '4px',
      pl: 0,
      borderColor: 'muted',
      borderBottomStyle: 'solid'
    }
  },
  th: {
    verticalAlign: 'bottom',
    borderBottomWidth: '2px'
  },
  td: {
    verticalAlign: 'top',
    borderBottomWidth: '1px'
  },
  hr: {
    border: 0,
    borderBottom: '1px solid',
    borderColor: 'muted'
  },
  img: {
    maxWidth: '100%'
  },
  abbr: {
    borderBottom: '1px dotted',
    cursor: 'help',
    textDecoration: 'none'
  },
  i: {
    fontStyle: 'italic'
  },
  em: {
    fontStyle: 'italic'
  },
  b: {
    fontWeight: 'bold'
  },
  var: {
    fontStyle: 'italic'
  },
  cite: {
    fontStyle: 'italic'
  },
  mark: {
    bg: 'highlights.6',
    borderRadius: 'default',
    paddingX: '.25em',
    paddingY: '.1em'
  },
  strong: {
    fontWeight: 'bold'
  },
  commonSupSub: {
    fontSize: '0.75em',
    lineHeight: 0,
    position: 'relative',
    verticalAlign: 'baseline'
  },
  sub: {
    variant: 'styles.commonSupSub',
    bottom: '-0.25em'
  },
  sup: {
    variant: 'styles.commonSupSub',
    top: '-0.5em'
  },
  ins: {
    textDecorationSkip: 'ink edges'
  },
  u: {
    extDecorationSkip: 'ink edges'
  },
  card: {
    p: 'sizes.space.md',
    boxShadow: 'defaultRise'
  },
  'component-wrapper': {
    border: 'neutral.light',
    '&:hover, &.is-active': {
      ':not(.is-disable):not(.is-selected)': {
        border: 'black',
        boxShadow: 'black.inset'
      }
    },
    '&.is-selected': {
      border: 'primary',
      boxShadow: 'primary.shadow'
    },
    '&:focus-within': {
      border: 'primary',
      boxShadow: 'primary.focus'
    },
    '&.is-disable': {
      bg: 'neutral.lighter',
      ':not(.is-selected)': {
        border: 'neutral.light',
        boxShadow: 'unset'
      }
    }
  }
}

export type StylesThemeVariants = keyof typeof styles
