import fastMemoize from 'fast-memoize'
import React, { createContext, useContext, useMemo, useState } from 'react'

import { useSafeThemeUI } from '../theme-provider/use-theme-ui'

export type IdProviderProps = {
  children: React.ReactNode
  prefix?: string
}

const defaultPrefix = 'id'

export const generateId = (prefix = defaultPrefix): string =>
  process.env.NODE_ENV === 'test'
    ? `${prefix}`
    : `${prefix}-${Math.random().toString(32).substr(2, 6)}`

const Context = createContext(generateId)

export const getIdGenerator = fastMemoize(
  (prefix = ''): ((localPrefix?: string) => string) => {
    const count: Record<string, number> = {}
    return (localPrefix = defaultPrefix) => {
      let nextVal = 0
      if (localPrefix in count) {
        nextVal = ++count[localPrefix]
      } else {
        count[localPrefix] = nextVal
      }
      return `${prefix}-${localPrefix}${
        process.env.NODE_ENV === 'test' ? '' : '-' + nextVal
      }`
    }
  }
)

export function IdProvider({
  children,
  prefix = ''
}: IdProviderProps): JSX.Element {
  const theme = useSafeThemeUI()
  const _prefix = (prefix || theme.contextPath || theme.contextId || '')
    ?.toLowerCase()
    .replaceAll('.', '-')
  const genId = useMemo(() => getIdGenerator(_prefix), [_prefix])
  return <Context.Provider value={genId}>{children}</Context.Provider>
}

export function useUniqueId(prefix = defaultPrefix): string {
  const genId = useContext(Context)
  const [id] = useState(() => genId(prefix))
  return id
}
