import type { Theme } from '../../theme-provider/types'
import { THEME_UI_DEFAULT_KEY } from '../../theme-provider/types'

const fontWeights = {
  light: 300,
  body: 400, //    cosmos font-weight-regular
  heading: 500,
  semiBold: 600,
  bold: 700 //     cosmos font-weight-bold
}

export type LhCrop = number | number[]

export type HeadingThemeKey = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

export type FontsTheme = Pick<
  Theme,
  'fonts' | 'fontSizes' | 'fontWeights' | 'lineHeights' | 'letterSpacings'
> & {
  lhCrops: {
    body: LhCrop
    heading: Record<HeadingThemeKey, LhCrop>
    monospace: LhCrop
  }
}

// When supporting responsive array first indices needs to be an array, even if the line crop margin on for the top and bottom is the same
const helveticaHeaderLhCrop = {
  h1: [[0.32, 0.38], null, [0.32, 0.36]],
  h2: [[0.32, 0.36], null, null, null, null, 0.34],
  h3: [[0.34, 0.34], null, [0.32, 0.36]],
  h4: [[0.3, 0.36], 0.35, [0.32, 0.34], [0.32, 0.36]],
  h5: [
    [0.3, 0.34],
    [0.32, 0.36]
  ],
  h6: [[0.34, 0.34], 0.35, [0.3, 0.36], [0.34, 0.36]]
}

export const fonts = {
  fonts: {
    body: 'Helvetica, Arial, sans-serif',
    heading: 'Helvetica, Arial, sans-serif',
    monospace: 'Menlo, monospace',
    news: 'PT Serif',
    costar: 'CostarBrownLight'
  },
  lhCrops: {
    body: {
      [THEME_UI_DEFAULT_KEY]: [0.3, 0.29],
      xxs: [0.5, 0.28],
      'xs-2': 0.35,
      'xs-3': 0.28
    },
    'text-container-expander': 0.3,
    heading: {
      h1: helveticaHeaderLhCrop.h1,
      h2: helveticaHeaderLhCrop.h2,
      h3: helveticaHeaderLhCrop.h3,
      h4: helveticaHeaderLhCrop.h4,
      h5: helveticaHeaderLhCrop.h5,
      h6: helveticaHeaderLhCrop.h6
    },
    monospace: 0.5 // Menlo
  },
  fontWeights,
  lineHeights: {
    body: 1.3,
    heading: 1.4
  },
  letterSpacings: {
    heading: '-0.05em',
    caps: '0.1em'
  }
}
