/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FC, PropsWithChildren } from 'react'
import React, { useMemo } from 'react'

import type { FlexProps } from './types'
import type { PrivateThemeUiProps } from '../../../styles-system/create-component'
import { bool } from '../../../utils/styled-props'
import { BoxPrivate } from '../box/box'

const flexDirection = bool(
  'flexDirection',
  ['row', 'column', 'rowReverse', 'columnReverse'],
  true
)

type FlexPrivateProps = PropsWithChildren<
  FlexProps & PrivateThemeUiProps & { inline?: boolean }
>

// typechecking causes error TS2590: Expression produces a union type that is too complex to represent.
export const FlexPrivate = React.forwardRef<HTMLDivElement, FlexPrivateProps>(
  function FlexPrivate(props, ref) {
    const { __css, displayProps, inline, rest } = useMemo(() => {
      const { __css, display, inline, ...rest } = props
      return {
        __css,
        displayProps: display,
        inline,
        rest
      }
    }, [props])

    const baseStyles = useMemo(
      () => ({
        display: displayProps ? displayProps : inline ? 'inline-flex' : 'flex',
        ...__css,
        ...flexDirection(rest) // should supersede flexDirection in __css
      }),
      [__css, displayProps, inline, rest]
    )
    return <BoxPrivate ref={ref} {...rest} __css={baseStyles} />
  }
)

const Flex: FC<FlexProps> = React.forwardRef<HTMLDivElement, FlexProps>(
  function Flex(props, ref) {
    return <FlexPrivate ref={ref} {...props} />
  }
)

export default Flex
