import type {
  TableVariants,
  TableVariantTheme
} from '../../components/table/types'
import type { ThemeUICSSObject } from '../../theme-provider/types'
import {
  h1ResponsiveFontSizes,
  largeResponsiveSpace,
  mediumResponsiveSizes,
  smallResponsiveSizes,
  smallResponsiveSpace,
  standardResponsiveSizes,
  standardResponsiveSpace,
  xSmallResponsiveSizes
} from '../const'

const commonDemographicsHeader = {
  '& tr:first-of-type': {
    color: 'black',
    th: { bg: 'neutral.light' },
    'th:not(:first-of-type)': {
      borderLeft: 'lightGray'
    }
  },
  '& tr:not(:first-of-type)': {
    color: 'primary',
    th: { bg: 'neutrals.21' },
    '.col-group-cell-0': {
      borderLeft: 'lightGray',
      px: standardResponsiveSpace
    },
    '.col-group-cell-1': {
      px: 0
    },
    '.col-group-cell-2': {
      px: standardResponsiveSpace
    }
  }
}

export const tables: TableVariants = {
  caption: {
    variant: 'text.body',
    lhCrop: 'body',
    fontSize: h1ResponsiveFontSizes,
    fontWeight: 'bold'
  } as ThemeUICSSObject,
  wrapper: {
    padding: 'sm',
    borderRadius: 'lg',
    boxShadow: 'textRise'
  } as ThemeUICSSObject,
  table: {
    color: 'black',
    fontSize: standardResponsiveSizes
  } as ThemeUICSSObject,
  body: {
    fontWeight: 'light'
  } as ThemeUICSSObject,
  cell: {
    px: standardResponsiveSizes,
    emPaddingY: 'xs-2'
  } as ThemeUICSSObject,
  head: { borderBottom: 'primaryThick', color: 'primary' } as ThemeUICSSObject,
  headCell: {
    px: standardResponsiveSizes,
    emPaddingY: 'xs-2'
  } as ThemeUICSSObject,
  row: {} as ThemeUICSSObject,
  foot: {
    borderTop: 'grayThick'
  } as ThemeUICSSObject,
  suiteDetailSummary: {
    table: {
      color: 'black',
      tableLayout: 'auto',
      width: '100%',
      fontWeight: 'body',
      '@media screen and (max-width: 1009px)': {
        fontSize: 'xxs'
      },
      '@media screen and (min-width: 1010px) and (max-width: 1179px)': {
        fontSize: 'xs'
      },
      '@media screen and (min-width: 1180px) and (max-width: 1524px)': {
        fontSize: 'xxs'
      },
      '@media screen and (min-width: 1525px) and (max-width: 1919px)': {
        fontSize: '13px'
      },
      '@media screen and (min-width: 1920px)': {
        fontSize: 'xs'
      }
    },
    body: {
      '& tr td': { paddingY: '1px' },
      '& tr td:first-child': {
        color: 'rgb(135, 135, 135)'
      },
      '& td:not(:first-of-type)': {
        emPaddingLeft: 'xs'
      }
    },
    head: {
      '& th:not(:first-of-type)': {
        emPaddingLeft: 'xs'
      }
    },
    headCell: {
      color: 'rgb(5, 89, 179)',
      fontWeight: 'body',
      borderBottom: 'unset'
    }
  } as TableVariantTheme,
  suiteDetail: {
    table: {
      color: 'black',
      fontSize: '13px',
      tableLayout: 'auto',
      width: '100%',
      fontWeight: 'body',
      border: '1px solid #d7d7d7'
    },
    body: {
      '& tr:nth-of-type(2n)': {
        td: { bg: '#efefef' }
      },
      '& td:not(:last-of-type)': {
        borderRight: '1px solid #d7d7d7'
      }
    },
    cell: {
      emPadding: 'xs'
    },
    head: {
      '& th:not(:last-of-type)': {
        borderRight: '1px solid #d7d7d7'
      }
    },
    headCell: {
      bg: '#154271',
      fontWeight: 'bold',
      color: 'white',
      emPadding: 'xs'
    }
  } as TableVariantTheme,
  peerDetail: {
    table: {
      variant: 'tables.table',
      fontSize: standardResponsiveSizes,
      tableLayout: 'auto',
      borderCollapse: 'separate'
    },
    body: {
      fontWeight: 'light',
      '& tr td:first-of-type': {
        ml: '3px'
      },
      '& tr:nth-of-type(even)': {
        td: { bg: 'neutrals.21' }
      }
    },
    cell: {
      px: smallResponsiveSpace,
      textAlign: 'left',
      emPaddingY: 'xs-2'
    },
    head: {
      color: 'primary'
    },
    headCell: {
      px: smallResponsiveSpace,
      borderBottom: 'unset',
      bg: 'neutrals.20',
      textAlign: 'left',
      emPaddingY: 'xxs'
    },
    row: {
      cursor: 'pointer',
      '& td .csg-peer-detail-remove-button': {
        visibility: 'hidden'
      },
      '&:hover': {
        td: {
          bg: '#eef7ff !important',
          '.csg-peer-detail-remove-button': {
            visibility: 'visible'
          }
        }
      }
    }
  } as TableVariantTheme,
  peerDetailSummaryTable: {
    table: {
      variant: 'tables.table',
      borderCollapse: 'separate',
      fontSize: standardResponsiveSizes,
      tableLayout: 'auto',
      width: '100%'
    },
    body: {
      fontWeight: 'light',
      '& tr:nth-of-type(even)': {
        td: { bg: 'neutrals.21' }
      }
    },
    cell: {
      variant: 'tables.peerDetail.cell',
      textAlign: 'right'
    },
    head: {
      variant: 'tables.peerDetail.head',
      '& tr th:not(:first-of-type)': {
        textAlign: 'center'
      }
    },
    headCell: {
      variant: 'tables.peerDetail.headCell'
    },
    row: {
      '& td:first-of-type': {
        verticalAlign: 'bottom'
      }
    }
  } as TableVariantTheme,
  peerUnitMix: {
    table: {
      variant: 'tables.table',
      fontSize: xSmallResponsiveSizes,
      tableLayout: 'auto',
      width: '100%'
    },
    body: {
      fontWeight: 'light',
      '& tr td:first-of-type': {
        textAlign: 'left'
      },
      '& tr:nth-of-type(even)': {
        td: { bg: 'neutrals.21' }
      }
    },
    cell: {
      variant: 'tables.peerDetail.cell',
      textAlign: 'right'
    },
    head: {
      variant: 'tables.peerDetail.head',
      '& tr th:not(:first-of-type)': {
        textAlign: 'right'
      }
    },
    headCell: {
      variant: 'tables.peerDetail.headCell'
    },
    row: {
      '& td:first-of-type': {
        verticalAlign: 'bottom'
      }
    },
    foot: {
      borderTop: 'lightGrayThin',
      '& tr td:first-of-type': {
        textAlign: 'left'
      }
    }
  } as TableVariantTheme,
  demographicsDataTable: {
    table: {
      variant: 'tables.demographicsRadiusTable.table'
    },
    headCell: {
      emPaddingY: 'xxs',
      paddingX: 'xxl'
    },
    head: {
      '& tr:first-of-type': {
        color: 'black',
        th: { bg: 'neutral.light' },
        'th:not(:first-of-type)': {
          borderLeft: 'neutral'
        },
        'th:first-of-type': {
          paddingLeft: 'md'
        }
      },
      '& tr:not(:first-of-type)':
        commonDemographicsHeader['& tr:not(:first-of-type)']
    },
    body: {
      '.parent-row': {
        color: 'primary',
        fontWeight: 'bold',
        td: {
          emPaddingY: 'xxs',
          paddingX: 'md'
        }
      },
      '& tr:not(.parent-row)': {
        td: {
          emPaddingY: 'xxs',
          paddingX: 'xxl',
          bg: 'neutrals.20'
        }
      },
      '& td:not(:first-of-type)': {
        borderLeft: 'neutral'
      }
    }
  } as TableVariantTheme,
  demographicsRadiusTable: {
    table: {
      variant: 'tables.table',
      fontSize: standardResponsiveSizes,
      tableLayout: 'auto',
      width: '100%',
      fontWeight: 'body'
    },
    body: {
      '& tr td': {
        color: 'black'
      },
      '& tr td:first-of-type': {
        color: 'neutral.dark',
        paddingLeft: '0',
        fontWeight: 'light'
      },
      '& tr td:not(:first-of-type)': {
        paddingRight: '0',
        textAlign: 'right'
      },
      '& tr:last-of-type td': {
        paddingBottom: '0'
      }
    },
    cell: {
      variant: 'tables.peerDetail.cell',
      emPaddingY: 'sm'
    },
    head: {
      variant: 'tables.peerDetail.head',
      '& tr th:not(:first-of-type)': {
        textAlign: 'right',
        borderBottom: 'lightGrayThick',
        paddingRight: '0'
      },
      '& tr th:first-of-type': {
        visibility: 'hidden'
      }
    },
    headCell: {
      borderBottom: 'unset',
      emPaddingBottom: 'sm',
      paddingTop: '0'
    },
    row: {
      '& td:first-of-type': {
        verticalAlign: 'bottom'
      }
    },
    foot: {
      borderTop: 'lightGrayThin',
      '& tr td:first-of-type': {
        textAlign: 'left'
      }
    }
  } as TableVariantTheme,
  consumerSpending: {
    table: {
      variant: 'tables.table',
      fontSize: standardResponsiveSizes
    },
    body: {
      fontWeight: 'light',
      '& tr:nth-of-type(even)': {
        td: { bg: 'neutrals.21' }
      },
      '& .col-group-cell-0': {
        borderLeft: 'lightGray',
        px: standardResponsiveSpace
      },
      '& .col-group-cell-1': {
        px: 0
      },
      '& .col-group-cell-2': {
        px: standardResponsiveSpace
      },
      '& tr.csg-rc-table-row-level-0': {
        fontWeight: 'bold'
      },
      '& tr:last-of-type': {
        td: { bg: 'blue.9' }
      }
    },
    cell: {
      px: standardResponsiveSpace,
      textAlign: 'left',
      emPaddingY: 'xxs'
    },
    head: commonDemographicsHeader,
    headCell: {
      px: standardResponsiveSizes,
      borderBottom: 'unset',
      textAlign: 'left',
      emPaddingY: 'xxs'
    },
    row: {
      '&:hover': {
        td: {
          bg: '#eef7ff !important'
        }
      }
    }
  } as TableVariantTheme,
  consumerSpendingCan: {
    table: {
      variant: 'tables.consumerSpending.table'
    },
    body: {
      variant: 'tables.consumerSpending.body',
      '& td:first-of-type': {
        paddingLeft: largeResponsiveSpace
      },
      '& tr.csg-rc-table-row-level-0': {
        fontWeight: 'light'
      },
      '& tr:last-of-type': {
        'td:first-of-type': {
          paddingLeft: standardResponsiveSizes
        },
        fontWeight: 'bold',
        td: { bg: 'blue.9' }
      }
    },
    cell: {
      variant: 'tables.consumerSpending.cell'
    },
    head: {
      variant: 'tables.consumerSpending.head'
    },
    headCell: {
      variant: 'tables.consumerSpending.headCell'
    },
    row: {
      variant: 'tables.consumerSpending.row'
    }
  } as TableVariantTheme,
  consumerSpendingGbr: {
    table: {
      variant: 'tables.consumerSpending.table'
    },
    body: {
      variant: 'tables.consumerSpending.body',
      '& tr:last-of-type': {
        fontWeight: 'bold',
        td: { bg: 'blue.9' }
      }
    },
    cell: {
      variant: 'tables.consumerSpending.cell'
    },
    head: {
      variant: 'tables.consumerSpending.head'
    },
    headCell: {
      variant: 'tables.consumerSpending.headCell'
    },
    row: {
      variant: 'tables.consumerSpending.row'
    }
  } as TableVariantTheme,
  daytimeEmployment: {
    table: {
      variant: 'tables.consumerSpending.table'
    },
    body: {
      variant: 'tables.consumerSpending.body',
      '& tr.csg-rc-table-row-level-1 td:first-of-type': {
        paddingLeft: 'xl'
      },
      '& tr.csg-rc-table-row-level-0': {
        fontWeight: 'bold',
        td: { bg: 'blue.9' }
      }
    },
    cell: {
      variant: 'tables.consumerSpending.cell'
    },
    head: {
      variant: 'tables.consumerSpending.head'
    },
    headCell: {
      variant: 'tables.consumerSpending.headCell'
    },
    row: {
      variant: 'tables.consumerSpending.row'
    }
  } as TableVariantTheme,
  daytimeEmploymentCan: {
    table: {
      variant: 'tables.daytimeEmployment.table'
    },
    body: {
      variant: 'tables.daytimeEmployment.body'
    },
    cell: {
      variant: 'tables.daytimeEmployment.cell'
    },
    head: {
      variant: 'tables.daytimeEmployment.head'
    },
    headCell: {
      variant: 'tables.daytimeEmployment.headCell'
    },
    row: {
      variant: 'tables.daytimeEmployment.row'
    }
  } as TableVariantTheme,
  daytimeEmploymentGbr: {
    table: {
      variant: 'tables.daytimeEmployment.table'
    },
    body: {
      variant: 'tables.daytimeEmployment.body'
    },
    cell: {
      variant: 'tables.daytimeEmployment.cell'
    },
    head: {
      variant: 'tables.daytimeEmployment.head',
      '& tr:not(:first-of-type)': {
        color: 'black',
        th: { bg: 'neutrals.21' },
        '.col-group-cell-0': {
          borderLeft: 'lightGray'
        }
      }
    },
    headCell: {
      variant: 'tables.daytimeEmployment.headCell'
    },
    row: {
      variant: 'tables.daytimeEmployment.row',
      '&[data-row-key="0"]': {
        display: 'none'
      }
    }
  } as TableVariantTheme,
  employmentTable: {
    table: {
      variant: 'tables.consumerSpending.table',
      fontSize: mediumResponsiveSizes
    },
    body: {
      fontWeight: 'body',
      '& tr td:first-of-type': {
        pl: largeResponsiveSpace
      },
      '& tr td:last-of-type': {
        pr: largeResponsiveSpace
      },
      '& tr:nth-of-type(odd)': {
        td: { bg: 'neutrals.21' }
      },
      '& tr:last-of-type': {
        td: {
          fontWeight: 'bold',
          bg: 'blue.9'
        }
      }
    },
    cell: {
      px: standardResponsiveSizes,
      textAlign: 'left',
      emPaddingY: 'xxs'
    },
    head: {
      color: 'primary',
      '.col-group-cell-parent': {
        ml: largeResponsiveSpace,
        mr: largeResponsiveSpace,
        borderBottom: 'medium solid lightgray'
      },
      '& tr th:last-of-type': {
        pr: largeResponsiveSpace
      }
    },
    headCell: {
      color: 'neutral.dark',
      px: standardResponsiveSizes,
      borderBottom: 'unset',
      bg: 'neutral.light',
      textAlign: 'left',
      emPaddingY: 'xxs'
    },
    row: {
      tr: { px: '50px ' },
      '&:hover': {
        td: {
          bg: '#eef7ff !important'
        }
      }
    }
  } as TableVariantTheme,
  trafficCount: {
    table: {
      variant: 'tables.consumerSpending.table'
    },
    body: {
      fontWeight: 'body',
      '& tr td:first-of-type': {
        pl: largeResponsiveSpace
      },
      '& tr td:last-of-type': {
        pr: largeResponsiveSpace
      },
      '& tr:nth-of-type(odd)': {
        td: { bg: 'neutrals.21' }
      }
    },
    cell: {
      px: standardResponsiveSizes,
      textAlign: 'left',
      emPaddingY: 'xxs'
    },
    head: {
      color: 'primary',
      '& tr th:first-of-type': {
        pl: largeResponsiveSpace
      },
      '& tr th:last-of-type': {
        pr: largeResponsiveSpace
      }
    },
    headCell: {
      px: standardResponsiveSizes,
      borderBottom: 'unset',
      bg: 'neutrals.21',
      textAlign: 'left',
      emPaddingY: 'xxs'
    },
    row: {
      tr: { px: '50px ' },
      '&:hover': {
        td: {
          bg: '#eef7ff !important'
        }
      }
    }
  } as TableVariantTheme,
  groupDetail: {
    table: {
      variant: 'tables.table',
      fontSize: smallResponsiveSizes,
      tableLayout: 'auto',
      width: '100%',
      borderCollapse: 'separate'
    },
    body: {
      variant: 'tables.peerDetail.body',
      fontWeight: 'body',
      '& .expanded': {
        fontWeight: 'bold'
      },
      '& .col-group-cell-0': {
        borderLeft: 'lightGray'
      },
      '& tr:nth-of-type(even)': {
        td: { bg: 'white' }
      },
      '& tr:nth-of-type(odd)': {
        td: { bg: 'neutral.lighter' }
      }
    },
    cell: {
      variant: 'tables.peerDetail.cell',
      borderTop: '1px solid transparent',
      borderBottom: '1px solid transparent'
    },
    head: {
      variant: 'tables.peerDetail.head',
      color: 'black',
      '& tr:last-of-type': {
        color: 'primary'
      },
      '& tr:last-of-type th': {
        color: 'primary',
        paddingBottom: 'xs'
      },
      '& tr th': {
        paddingTop: 'xs',
        paddingBottom: 0
      },
      '& .col-group-cell-0': {
        borderLeft: 'lightGray'
      }
    },
    headCell: {
      variant: 'tables.peerDetail.headCell',
      padding: 'xs'
    },
    row: {
      height: '30px',
      lineHeight: '21px',
      verticalAlign: 'middle',
      th: {
        backgroundColor: 'neutral.light',
        px: 0,
        '& .sortable:hover': {
          opacity: '0.6'
        }
      },
      td: {
        py: 0,
        px: 'lg'
      },
      '&:hover td': {
        bg: '#e3f3fc !important',
        borderTop: '1px solid #d9d9d9',
        borderBottom: '1px solid #d9d9d9 !important',
        a: { textDecoration: 'underline' }
      }
    },
    foot: {
      variant: 'tables.peerDetail.foot'
    }
  } as TableVariantTheme
}

export type TablesThemeVariants = keyof typeof tables
