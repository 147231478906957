import {
  h1ResponsiveFontSizes,
  h2ResponsiveFontSizes,
  h3ResponsiveFontSizes,
  h4ResponsiveFontSizes,
  h5ResponsiveFontSizes,
  h6ResponsiveFontSizes
} from '../const'

export const headings = {
  h1: {
    variant: 'text.heading',
    fontSize: h1ResponsiveFontSizes
  },
  h2: {
    variant: 'text.heading',
    fontSize: h2ResponsiveFontSizes
  },
  h3: {
    variant: 'text.heading',
    fontSize: h3ResponsiveFontSizes
  },
  h4: {
    variant: 'text.heading',
    fontSize: h4ResponsiveFontSizes
  },
  h5: {
    variant: 'text.heading',
    fontSize: h5ResponsiveFontSizes
  },
  h6: {
    variant: 'text.heading',
    fontSize: h6ResponsiveFontSizes
  }
}

export type HeadingsThemeVariants = keyof typeof headings
