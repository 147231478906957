/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled'
import type { SystemStyleObject } from '@styled-system/css'
import { createShouldForwardProp } from '@styled-system/should-forward-prop'
import memoizerific from 'memoizerific'
import type { ComponentType, PropsWithChildren } from 'react'
import React from 'react'

import {
  border,
  color,
  emSpace,
  flexbox,
  grid,
  isSVGElement,
  position,
  resolveLayout,
  shadow,
  space,
  stylable,
  systemProps,
  typography
} from './system-props'
import type { ThemeUiProps } from '../components/_primitives/_types/baseTypes'
import css from '../theme-provider/css'
import type {
  Theme,
  ThemeUICSSObject,
  ThemeUIStyleObject
} from '../theme-provider/types'
import styleVariant from '../theme-provider/variant'
import {
  EMPTY_OBJECT,
  memoizedGet as get,
  objectFilter,
  omit
} from '../utils/object'
import type { Dict } from '../utils/types'

const listElements = ['nav', 'ul', 'li', 'ol', 'dl', 'dt', 'dd']

export function getBaseReset(
  tag?: keyof JSX.IntrinsicElements | ComponentType<any>
): ThemeUICSSObject {
  return {
    margin: 'unset',
    padding: 'unset',
    border: 'unset',
    background: 'unset',
    font: 'unset',
    fontFamily: 'inherit',
    fontSize: '100%',
    boxSizing: 'border-box',
    outline: 'none',
    ...(listElements.includes(`${tag}`) ? { listStyle: 'none' } : {})
  } as Dict<string>
}
function resetCss(tag?: keyof JSX.IntrinsicElements | ComponentType<any>) {
  const baseResetCss = getBaseReset(tag)
  return function (props: { resetCss: SystemStyleObject; theme: Theme }) {
    const { resetCss = EMPTY_OBJECT } = props
    const _resetCss = {
      ...objectFilter(
        resetCss as any,
        v => v !== null && v !== undefined && `${v}`.trim() !== ''
      ),
      ...omit(baseResetCss, Object.keys(resetCss as any))
    }
    return css(_resetCss as ThemeUICSSObject)(props)
  }
}

function sx(props: { sx: SystemStyleObject; theme: Theme }) {
  return css(props.sx as ThemeUICSSObject)(props)
}
function base(props: { __css: SystemStyleObject; theme: Theme }) {
  return css(props.__css as ThemeUICSSObject)(props)
}
function variant(props: { theme: Theme; variant: string; __themeKey: string }) {
  const { theme, variant, __themeKey } = props
  const styles: ThemeUIStyleObject = get(
    theme,
    __themeKey ? __themeKey + '.' + variant : variant,
    get(theme, variant)
  )
  return css(styles)
}

const shouldForwardPropNonSvg = createShouldForwardProp(systemProps(false))
const shouldForwardPropSvg = createShouldForwardProp(systemProps(true))

const tui = memoizerific(1000)(function (
  tag: keyof JSX.IntrinsicElements | ComponentType<any>,
  variants = EMPTY_OBJECT
) {
  return styled(tag as ComponentType<any>, {
    shouldForwardProp: (propName: PropertyKey) => {
      const shouldForward = isSVGElement(tag)
        ? shouldForwardPropSvg
        : shouldForwardPropNonSvg
      return shouldForward(propName as string) || propName === 'automation-id'
    }
  })(
    resetCss(tag),
    styleVariant({
      variants
    }),
    base as any,
    variant as any,
    // we include these styled-system css func evaluators to allow css props being past
    // to an element to be included in the resulting css class.
    space,
    emSpace,
    color,
    typography,
    resolveLayout(tag),
    position,
    border,
    shadow,
    flexbox,
    grid,
    stylable,
    sx as any,
    props => props.css
  )
})

export type PrivateThemeUiProps = ThemeUiProps & {
  __themeKey?: string
  __css?: object
  __variants?: object
  __inputProps?: ThemeUICSSObject
}
// DO NOT switch to standard EMPTY_OBJECT
const emptyObject = {}

/**
 * Creates a styled version of a component.
 *
 * @param as The component to style
 * @param displayName An optional display name override
 * @param variants Style variants available to the new component
 * @returns a {@link StyledComponent}
 *
 * @see [@emotion/styled](https://emotion.sh/docs/styled)
 */
export default function createComponent(
  as: keyof JSX.IntrinsicElements | ComponentType<any> = 'div',
  displayName?: string,
  variants = emptyObject
): any {
  const Component = tui(as, variants)
  const render = React.forwardRef<
    HTMLElement,
    PropsWithChildren<PrivateThemeUiProps>
  >((props, ref) => <Component ref={ref} {...props} />)
  render.displayName = displayName ? displayName : 'div'
  return render
}

/**
 * Creates multiple styled components with the same display name.
 *
 * @param components The components to style
 * @param displayName The component's display name
 * @returns A map from original component names to their styled counterparts
 *
 * @see {@link createComponent}
 */
export function createComponents<
  T extends ReadonlyArray<keyof JSX.IntrinsicElements>
>(
  components: T,
  displayName?: string,
  variants?: Record<string, any>
): Record<T[number], any> {
  return components.reduce(
    (acc, curr) => {
      acc[curr as T[number]] = createComponent(curr, displayName, variants)
      return acc
    },
    {} as Record<T[number], any>
  )
}
