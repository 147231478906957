/* eslint-disable @typescript-eslint/no-explicit-any */
// forked from https://github.com/chakra-ui/chakra-ui/blob/main/packages/utils/src/react-helpers.ts
import type { Ref } from 'react'
import React, { isValidElement } from 'react'

import { isFunction } from './assertion'

export type CreateContextOptions = {
  /**
   * If `true`, React will throw if context is `null` or `undefined`
   * In some cases, you might want to support nested context, so you can set it to `false`
   */
  strict?: boolean
  /**
   * Error message to throw if the context is `undefined`
   */
  errorMessage?: string
  /**
   * The display name of the context
   */
  name?: string
}

type CreateContextReturn<T> = [React.Provider<T>, () => T, React.Context<T>]

/**
 * Creates a named context, provider, and hook.
 *
 * @param options create context options
 */
export function createContext<ContextType>(
  options: CreateContextOptions = {},
  context?: ContextType
): CreateContextReturn<ContextType> {
  const {
    strict = true,
    errorMessage = 'useContext: `context` is undefined. Seems you forgot to wrap component within the Provider',
    name
  } = options

  const Context = React.createContext<ContextType | undefined>(context)

  Context.displayName = name

  function useContext() {
    const context = React.useContext(Context)

    if (!context && strict) {
      throw new Error(errorMessage)
    }

    return context
  }

  return [
    Context.Provider,
    useContext,
    Context
  ] as CreateContextReturn<ContextType>
}

/**
 * Gets only the valid children of a component,
 * and ignores any nullish or falsy child.
 *
 * @param children the children
 */
export function getValidChildren(
  children: React.ReactNode
): React.ReactElement[] {
  return React.Children.toArray(children).filter(child =>
    React.isValidElement(child)
  ) as React.ReactElement[]
}

type ReactRef<T> = React.Ref<T> | React.RefObject<T> | React.MutableRefObject<T>

/**
 * Assigns a value to a ref function or object
 *
 * @param ref the ref to assign to
 * @param value the value
 */
export function assignRef<T = any>(
  ref: ReactRef<T> | undefined,
  value: T
): void {
  if (ref == null) return

  if (isFunction(ref)) {
    ref(value)
    return
  }

  try {
    // @ts-ignore
    ref.current = value
  } catch (error) {
    throw new Error(`Cannot assign value '${value}' to ref '${ref}'`)
  }
}

/**
 * Combine multiple React refs into a single ref function.
 * This is used mostly when you need to allow consumers forward refs to
 * internal components
 *
 * @param refs refs to assign to value to
 */
export function mergeRefs<T>(...refs: (ReactRef<T> | undefined)[]) {
  return (value: T): void => {
    refs.forEach(ref => assignRef(ref, value))
  }
}

export function hasRef<T>(subject: any): subject is {
  ref: Ref<T>
} {
  return !!subject && subject.hasOwnProperty('ref')
}

export const reactNodeToString = function (reactNode: React.ReactNode): string {
  let string = ''
  if (typeof reactNode === 'string') {
    string = reactNode
  } else if (typeof reactNode === 'number') {
    string = reactNode.toString()
  } else if (reactNode instanceof Array) {
    reactNode.forEach(function (child) {
      string += reactNodeToString(child)
    })
  } else if (isValidElement(reactNode)) {
    string += reactNodeToString(reactNode.props.children)
  }
  return string
}
