import { THEME_UI_DEFAULT_KEY } from '../../theme-provider/types'

const base = {
  boxShadow: 'cosmos',
  borderRadius: '0',
  width: '100%',
  minWidth: '400px'
}

const centerPosition = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(max(-50%,-50vh))'
}

const smallDialog = { maxWidth: '578px' }
const mediumDialog = { maxWidth: '701px' }
const largeDialog = { maxWidth: '820px' }

export const layout = {
  header: {
    color: 'white',
    backgroundColor: 'black'
  },
  display: {
    variant: 'text.display'
  },
  body: {
    variant: 'styles.root'
  },
  footer: {
    color: 'white',
    backgroundColor: 'black'
  },
  container: {
    [THEME_UI_DEFAULT_KEY]: {
      maxWidth: '1600px'
    },
    'high-res-desktop': {
      maxWidth: '1950px'
    },
    'full-width': {
      maxWidth: '100%'
    },
    'input-form': {
      maxWidth: '610px'
    }
  },
  modal: {
    cosmos: {
      ...base,
      ...centerPosition,
      ...smallDialog,
      medium: {
        ...base,
        ...centerPosition,
        ...mediumDialog
      },
      large: {
        ...base,
        ...centerPosition,
        ...largeDialog
      }
    }
  }
}

export type LayoutThemeVariants = keyof typeof layout
