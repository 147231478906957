import { standardButtonHover } from '../../components/button/variants'
import type {
  ObjectWithDefault,
  ThemeUICSSObject
} from '../../theme-provider/types'
import { THEME_UI_DEFAULT_KEY } from '../../theme-provider/types'
import { omit } from '../../utils/object'

export const standardAnchor: ThemeUICSSObject = {
  color: 'inherit',
  bg: 'transparent',
  cursor: 'pointer',
  p: 'unset',
  textDecoration: 'none',
  '&.is-disabled': {
    pointerEvents: 'none'
  }
}

const defaultDisable: ThemeUICSSObject = {
  '&.is-disabled': {
    color: 'neutral.medium',
    cursor: 'default'
  }
}

export const anchorUnderlineHover: ThemeUICSSObject = {
  textDecoration: 'underline'
}

export const hoverAnchor: ThemeUICSSObject = {
  '&:hover:not(.is-disabled):not(.is-toggled):not(.disable-hover)': {
    textDecoration: 'underline',
    opacity: 1,
    cursor: 'pointer'
  }
}

export const anchors: ObjectWithDefault<ThemeUICSSObject> = {
  [THEME_UI_DEFAULT_KEY]: {
    ...standardAnchor,
    '&:hover:not(.is-disabled):not(.is-toggled):not(.disable-hover)': {
      opacity: '0.6'
    }
  },
  underline: {
    ...standardAnchor,
    '&:hover:not(.is-disabled):not(.is-toggled):not(.disable-hover)':
      anchorUnderlineHover
  },
  'no-hover': standardAnchor,
  'hover-primary': {
    ...standardAnchor,
    '&:hover:not(.is-disabled):not(.is-toggled):not(.disable-hover)': {
      opacity: '0.6',
      color: 'primary'
    },
    ...defaultDisable
  },
  'text-button': {
    ...omit(standardAnchor, ['p']),
    border: 'black',
    borderRadius: 'default',
    '&:hover:not(.is-disabled):not(.is-toggled):not(.disable-hover)':
      standardButtonHover
  },
  'primary-button': {
    ...omit(standardAnchor, ['p', 'bg']),
    bg: 'primary',
    border: 'primary',
    color: 'white',
    borderRadius: 'default',
    '&:hover:not(.is-disabled):not(.is-toggled):not(.disable-hover)': {
      ...standardButtonHover,
      color: 'white'
    }
  },
  primary: {
    color: 'primary',
    fontWeight: 'bold',
    ...hoverAnchor,
    ...defaultDisable
  },
  'primary-icon': {
    color: 'primary',
    fontWeight: 'bold',
    '&:hover:not(.is-disabled):not(.is-toggled):not(.disable-hover)': {
      opacity: 0.6,
      cursor: 'pointer'
    },
    ...defaultDisable
  },
  secondary: {
    ...hoverAnchor
  }
}
