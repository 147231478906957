import input from '../../components/_primitives/input/variants'
import textarea from '../../components/_primitives/text-area/variants'
import checkbox from '../../components/checkbox/variants'
import radio from '../../components/radio/variants'
import select from '../../components/react-select/variants'
const forms = {
  checkbox,
  input,
  select,
  textarea,
  radio
}
export * from './anchors'
export * from './alert'
export { default as getBorders } from './borders'
export {
  default as getShadows,
  borderToShadows,
  generateBoxShadow
} from './shadows'
export { default as getGradients } from './gradients'
export { default as tags } from './tags'
export * from './cards'
export * from './colors'
export * from './fonts'
export * from './grids'
export * from './headings'
export * from './layout'
export * from './lists'
export * from './sizes'
export * from './styles'
export * from './tables'
export * from './tabs'
export * from './text'
export * from './tooltips'
export * from './transitions'
export * from './elevations'
export * from './scrollbar'
export * from '../../components/badge/variants'
export { default as getSizes } from './sizes'
export { borderWidths } from './border-widths'
export { default as getRadii, radii } from './radii'
export {
  default as buttonsTheme,
  standardButtonHover,
  standardButtonDisabled
} from '../../components/button/variants'
export { forms }
