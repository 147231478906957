/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { CSSProperties, FC } from 'react'
import React, { useCallback, useRef } from 'react'
import { Transition } from 'react-transition-group'
import type { TransitionStatus } from 'react-transition-group/Transition'

import type { FadeProps } from './types'
import useForkRef from '../../../hooks/use-fork-ref'
import type { Theme } from '../../../theme-provider/types'
import { useSafeThemeUI } from '../../../theme-provider/use-theme-ui'
import { duration } from '../../../themes/theme-keys/transitions'
import { getTransitionProps, reflow } from '../transitions/utils'

const styles: Partial<Record<TransitionStatus, CSSProperties>> = {
  entering: {
    opacity: 1
  },
  entered: {
    opacity: 1
  }
}

const defaultTimeout = {
  enter: duration.enteringScreen,
  exit: duration.leavingScreen
}

/**
 * The Fade transition is used by the [Modal](/components/modal/) component.
 * It uses [react-transition-group](https://github.com/reactjs/react-transition-group) internally.
 */
const Fade: FC<FadeProps> = React.forwardRef<
  HTMLElement,
  FadeProps & { theme?: Theme }
>(function Fade(props, ref) {
  const {
    addEndListener,
    appear = true,
    children,
    easing,
    in: inProp,
    onEnter,
    onEntered,
    onEntering,
    onExit,
    onExited,
    onExiting,
    style,
    timeout = defaultTimeout,
    //@ts-ignore
    TransitionComponent = Transition,
    ...other
  } = props
  const theme = useSafeThemeUI(props)
  const enableStrictModeCompat = true
  const nodeRef = useRef<HTMLElement>(null)
  //@ts-ignore
  const foreignRef = useForkRef(children?.ref, ref)
  const handleRef = useForkRef(nodeRef, foreignRef)

  const normalizedTransitionCallback =
    (callback?: (node: HTMLElement, isAppearing: boolean) => void) =>
    (isAppearing: boolean) => {
      if (callback) {
        const node = nodeRef.current
        if (node) {
          // onEnterXxx and onExitXxx callbacks have a different arguments.length value.
          if (isAppearing === undefined) {
            // @ts-ignore
            callback(node)
          } else {
            callback(node, !!isAppearing)
          }
        }
      }
    }
  const handleEntering = normalizedTransitionCallback(onEntering)

  const handleEnter = normalizedTransitionCallback((node, isAppearing) => {
    reflow(node) // So the animation always start from the start.

    const transitionProps = getTransitionProps(
      { style, timeout, easing },
      {
        mode: 'enter'
      }
    )

    node.style.webkitTransition = theme.transitions
      ? theme.transitions.create('opacity', transitionProps)
      : 'opacity'
    node.style.transition = theme.transitions
      ? theme.transitions.create('opacity', transitionProps)
      : 'opacity'

    if (onEnter) {
      onEnter(node, isAppearing)
    }
  })

  const handleEntered = normalizedTransitionCallback(onEntered)

  const handleExiting = normalizedTransitionCallback(onExiting)

  const handleExit = normalizedTransitionCallback(node => {
    const transitionProps = getTransitionProps(
      { style, timeout, easing },
      {
        mode: 'exit'
      }
    )

    node.style.webkitTransition = theme.transitions
      ? theme.transitions.create('opacity', transitionProps)
      : 'opacity'
    node.style.transition = theme.transitions
      ? theme.transitions.create('opacity', transitionProps)
      : 'opacity'

    if (onExit) {
      onExit(node)
    }
  })

  const handleExited = normalizedTransitionCallback(onExited)

  const handleAddEndListener = useCallback(
    (next: () => void) => {
      const element = nodeRef.current
      if (addEndListener && element) {
        // Old call signature before `react-transition-group` implemented `nodeRef`
        addEndListener(element, next)
      }
    },
    [addEndListener]
  )

  return (
    <TransitionComponent
      appear={appear}
      in={inProp}
      nodeRef={enableStrictModeCompat ? nodeRef : undefined}
      onEnter={handleEnter}
      onEntered={handleEntered}
      onEntering={handleEntering}
      onExit={handleExit}
      onExited={handleExited}
      onExiting={handleExiting}
      addEndListener={handleAddEndListener}
      timeout={timeout}
      {...other}
    >
      {(state: TransitionStatus, childProps: any) => {
        return children
          ? React.cloneElement(children, {
              style: {
                opacity: 0,
                visibility:
                  state === 'exited' && !inProp ? 'hidden' : undefined,
                ...styles[state],
                ...style,
                ...children.props.style
              },
              ref: handleRef,
              ...childProps
            })
          : null
      }}
    </TransitionComponent>
  )
})

export default Fade
