import type React from 'react'

import { createContext } from '../../utils/react-helpers'

type ContainerContext = {
  containerRef: React.RefObject<HTMLDivElement | undefined | null>
}

const [ContainerContextProvider, useContainerContext] =
  createContext<ContainerContext>({
    strict: false,
    name: 'ContainerContext'
  })

export { ContainerContextProvider, useContainerContext }
