import type { TooltipVariants } from '../../components/tooltip/types'
import { THEME_UI_DEFAULT_KEY } from '../../theme-provider/types'

export const tooltips: TooltipVariants = {
  [THEME_UI_DEFAULT_KEY]: {
    variant: 'text.body',
    bg: 'white',
    borderRadius: 'default',
    color: 'text',
    px: 'sm',
    py: 'xs',
    fontSize: 'xs',
    wordWrap: 'break-word',
    boxShadow: 'defaultRise',
    elevation: 200
  },
  // intended to match chrome default title tooltip dark mode
  title: {
    variant: 'text.body',
    bg: 'gray.dark', // match chrome default title tooltip back ground
    color: 'neutral.light',
    borderRadius: 'none',
    fontSize: '0.6em',
    letterSpacing: '0.05em',
    py: '0.25em',
    px: '0.5em',
    wordWrap: 'break-word',
    maxWidth: '500px',
    border: 'neutral.light'
  },
  pendo: {
    variant: `tooltips.${THEME_UI_DEFAULT_KEY}`,
    bg: 'secondary',
    color: 'white',
    fontSize: 'xs',
    borderRadius: 'none',
    boxShadow: 'primary.rise',
    elevation: 300
  },
  suite: {
    variant: `tooltips.${THEME_UI_DEFAULT_KEY}`,
    bg: 'white',
    boxShadow: 'defaultRise'
  },
  peerDetail: {
    borderRadius: 'sm',
    boxShadow: 'primary.rise'
  },
  suppression: {
    variant: `tooltips.${THEME_UI_DEFAULT_KEY}`,
    bg: 'neutrals.22',
    maxWidth: 280,
    border: '1px solid rgba(0,0,0,0.2)',
    boxShadow: 'textThick'
  },
  'info-tooltips': {
    bg: 'white',
    color: 'text',
    borderRadius: 0,
    fontSize: 'sm',
    p: 'lg',
    maxWidth: 350,
    minHeight: '32px',
    boxShadow: 'dropShadow',
    border: 'neutral.medium'
  }
}

export type TooltipsThemeVariants = keyof typeof tooltips
