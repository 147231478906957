/* eslint-disable @typescript-eslint/no-explicit-any */
// forked from https://github.com/chakra-ui/chakra-ui/blob/main/packages/utils/src/dom.ts
import type { MutableRefObject } from 'react'

import type { Booleanish, EventKeys } from './types'

export type BasicTarget<T = HTMLElement> =
  | (() => T | null)
  | T
  | null
  | MutableRefObject<T | null | undefined>

type TargetElement = HTMLElement | Document | Window

export function getTargetElement(
  target?: BasicTarget<TargetElement>,
  defaultElement?: TargetElement
): TargetElement | undefined | null {
  if (!target) {
    return defaultElement
  }

  let targetElement: TargetElement | undefined | null

  if (typeof target === 'function') {
    targetElement = target()
  } else if ('current' in target) {
    targetElement = target.current
  } else {
    targetElement = target
  }

  return targetElement
}

export function getOwnerWindow(
  node?: HTMLElement | null
): Window & typeof globalThis {
  return node instanceof Element
    ? getOwnerDocument(node).defaultView ?? window
    : window
}

export function getOwnerDocument(node?: HTMLElement | null): Document {
  return node instanceof Element ? node.ownerDocument ?? document : document
}

export function canUseDOM(): boolean {
  return !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  )
}

export const isBrowser = canUseDOM()

/**
 * Get the normalized event key across all browsers
 * @param event keyboard event
 */
export function normalizeEventKey(event: React.KeyboardEvent): any {
  const { key, keyCode } = event

  const isArrowKey =
    keyCode >= 37 && keyCode <= 40 && key.indexOf('Arrow') !== 0

  const eventKey = isArrowKey ? `Arrow${key}` : key

  return eventKey as EventKeys
}

export const dataAttr = (condition: boolean | undefined): Booleanish =>
  (condition ? '' : undefined) as Booleanish

export const ariaAttr = (
  condition: boolean | undefined
): boolean | undefined => (condition ? true : undefined)

export const cx = (...classNames: any[]): string =>
  classNames.filter(Boolean).join(' ')

export function getActiveElement(node?: HTMLElement): HTMLElement {
  const doc = getOwnerDocument(node)
  return doc?.activeElement as HTMLElement
}

export function contains(parent: HTMLElement, child: HTMLElement): boolean {
  return parent === child || parent.contains(child)
}

export function findMatchingElement(
  element?: Element | null,
  targetElement?: Element | null,
  /** specified the number of level to traverse accending must be positive */
  level = 0
): boolean {
  return (
    element === targetElement ||
    (level > 0
      ? findMatchingElement(element?.parentElement, targetElement, level - 1)
      : false)
  )
}
