export enum SizeEnum {
  'xs-3' = 'xs-3',
  'xs-2' = 'xs-2',
  'xxs' = 'xxs',
  'xs' = 'xs',
  'sm' = 'sm',
  'md' = 'md',
  'lg' = 'lg',
  'xl' = 'xl',
  'xxl' = 'xxl',
  'xl+2' = 'xl+2',
  'xl+3' = 'xl+3',
  'xl+4' = 'xl+4',
  'xl+5' = 'xl+5',
  'xl+6' = 'xl+6'
}
