import classNames from 'classnames'
import type { FC } from 'react'
import React, { useEffect, useMemo } from 'react'

import type { PaneProps } from './types'
import type { PrivateThemeUiProps } from '../../../styles-system/create-component'
import { isElevationThemeKeys } from '../../../themes/theme-keys/elevations'
import { EMPTY_OBJECT } from '../../../utils'
import { noop } from '../../../utils/function'
import {
  getBackground,
  getBorder,
  getColor,
  omitBackground,
  omitBorder,
  omitColor
} from '../../../utils/props-utils'
import { BoxPrivate } from '../../_primitives/box/box'
import type { BoxProps } from '../../_primitives/box/types'

export type LocalPaneProps = Omit<PaneProps, 'border'> &
  PrivateThemeUiProps & {
    styledBorder?: boolean | 'shadow' | string
  }

export const PanePrivate: FC<LocalPaneProps> = React.forwardRef<
  HTMLDivElement,
  LocalPaneProps
>(function PanePrivate(props, ref) {
  const { restProps, restCss, restSx } = useMemo(() => {
    const {
      variant,
      styledBorder,
      elevation,
      square,
      __css: __cssProps = EMPTY_OBJECT,
      sx,
      ...rest
    } = props
    const combineBackground = {
      ...getBackground(rest),
      ...getBackground(__cssProps),
      ...getBackground(sx ?? EMPTY_OBJECT)
    }
    const combineBorder = {
      ...getBorder(rest),
      ...getBorder(__cssProps),
      ...getBorder(sx ?? EMPTY_OBJECT)
    }
    const combineColor = {
      ...getColor(rest),
      ...getColor(__cssProps),
      ...getColor(sx ?? EMPTY_OBJECT)
    }
    return {
      restProps: {
        variant,
        elevation,
        ...combineColor,
        ...combineBackground,
        ...combineBorder,
        ...omitBorder(omitColor(omitBackground(rest)))
      } as BoxProps & PrivateThemeUiProps,
      restCss: {
        outline: 'none',
        ...(!variant &&
        styledBorder !== 'shadow' &&
        typeof styledBorder !== 'boolean'
          ? { border: styledBorder }
          : styledBorder === 'shadow'
            ? {
                boxShadow: 'defaultRise'
              }
            : styledBorder === true
              ? {
                  border: 'neutral.medium'
                }
              : EMPTY_OBJECT),
        ...(!variant && isElevationThemeKeys(elevation)
          ? {
              elevation: elevation,
              boxShadow: 'defaultRise'
            }
          : EMPTY_OBJECT),
        bg: 'white',
        borderRadius: square ? 0 : 'md',
        ...omitBorder(omitColor(omitBackground(__cssProps)))
      },
      restSx: omitBorder(omitColor(omitBackground(sx ?? EMPTY_OBJECT)))
    }
  }, [props])

  const {
    __themeKey = 'layout',
    variant = 'pane',
    className,
    onMounted = noop,
    ...rest
  } = restProps

  useEffect(() => {
    const timer = setTimeout(() => onMounted(), 200)
    return () => clearTimeout(timer)
  }, [onMounted])

  return (
    <BoxPrivate
      ref={ref}
      __themeKey={__themeKey}
      variant={variant}
      relative
      className={classNames(
        {
          'csg-tui-pane': true
        },
        className
      )}
      __css={restCss}
      sx={restSx}
      {...rest}
    />
  )
})

const Pane: FC<PaneProps> = React.forwardRef<HTMLDivElement, PaneProps>(
  function Pane(props, ref) {
    const { border, ...rest } = props
    return <PanePrivate ref={ref} styledBorder={border} {...rest} />
  }
)

export default Pane
