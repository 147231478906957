import type { ColorModesScale } from '@theme-ui/core'
import type CSS from 'csstype'

/* eslint-disable @typescript-eslint/no-explicit-any */
import type { NestedScaleDict } from '../../theme-provider/types'
import KolorWheel from '../../utils/kolor-wheel'

const generateColor = (color1: string, color2: string) => {
  const base = new KolorWheel(color1)
  const target = base.abs(color2, 10)
  return target.resultList.map((i: any) => i.getHex())
}

export const baseColorPallets = [
  'red',
  'orange',
  'yellow',
  'green',
  'teal',
  'blue',
  'indigo',
  'purple',
  'pink'
]

export const baseColors = (
  red = ['#FFB4B4', '#8A2100'],
  orange = ['#FFEAD4', '#ff7e00'],
  yellow = ['#FFFCD4', '#788D00'],
  green = ['#EFFFD9', '#004900'],
  teal = ['#E6FFFA', '#006161'],
  blue = ['#e3ecff', '#130080'],
  indigo = ['#FFDFFF', '#3C366B'],
  purple = ['#FFF1FF', '#800080'],
  pink = ['#FFF5F7', '#702459']
): {
  neutrals: string[]
  primaries: string[]
  successes: string[]
  errors: string[]
  alerts: string[]
  highlights: string[]
  transparent: string
  red: string[]
  orange: string[]
  yellow: string[]
  green: string[]
  teal: string[]
  blue: string[]
  indigo: string[]
  purple: string[]
  pink: string[]
  opaqueWhite: string[]
  opaqueBlack: string[]
} => ({
  neutrals: [
    '#000', //     0 cosmos black
    '#0c0c0c', //  1
    '#181818', //  2
    '#242424', //  3
    '#303030', //  4
    '#3c3c3c', //  5
    '#404040', //  6 cosmos neutral darker
    '#555555', //  7
    '#616161', //  8
    '#6d6d6d', //  9
    '#797979', // 10
    '#757575', // 11 cosmos neutral dark
    '#919191', // 12
    '#9d9d9d', // 13
    '#aaaaaa', // 14
    '#bfbfbf', // 15 cosmos neutral medium
    '#c2c2c2', // 16
    '#cecece', // 17 cosmos neutral
    '#dadada', // 18
    '#eaeaea', // 19 cosmos neutral light
    '#f2f2f2', // 20
    '#f5f5f5', // 21 cosmos neutral lighter
    '#fff' // 22
  ],
  primaries: [
    '#1b3a6e', // 0 cosmos secondary
    '#154283', // 1
    '#0e4d9a', // 2
    '#0559b3', // 3 cosmos primary
    '#207ad8', // 4
    '#669bd1', // 5 cosmos primary medium
    '#e3f3fc' //  6 cosmos primary light
  ],
  successes: [
    '#017b30', // 0 cosmos green
    '#01b227', // 1
    '#01e90b', // 2
    '#41fe24', // 3
    '#8dff5b', // 4
    '#c6ff92', // 5
    '#ecffc9' // 6
  ],
  errors: [
    '#cf0201', // 0 cosmos red
    '#f60f02', // 1
    '#fc3a24', // 2
    '#fc664c', // 3
    '#fc8f74', // 4
    '#fcb39c', // 5
    '#ffbfbe' // 6
  ],
  alerts: [
    '#ec4a08', // 0 cosmos accent
    '#f75d1f', // 1
    '#f9753f', // 2
    '#fa8d60', // 3
    '#fba581', // 4
    '#fcbca2', // 5
    '#fdd4c3' // 6
  ],
  highlights: [
    '#ffb400', // 0 cosmos yellow
    '#ffbd1f', // 1
    '#ffc63e', // 2
    '#ffd05e', // 3
    '#ffd97d', // 4
    '#ffe29c', // 5
    '#ffebbb', // 6
    '#f28f20', // 7
    '#2186a5' // 8
  ],
  transparent: 'transparent',
  red: [...generateColor(red[1], red[0])],
  orange: [...generateColor(orange[1], orange[0])],
  yellow: [...generateColor(yellow[1], yellow[0])],
  green: [...generateColor(green[1], green[0])],
  teal: [...generateColor(teal[1], teal[0])],
  blue: [...generateColor(blue[1], blue[0])],
  indigo: [...generateColor(indigo[1], indigo[0])],
  purple: [...generateColor(purple[1], purple[0])],
  pink: [...generateColor(pink[1], pink[0])],
  opaqueWhite: [
    'rgba(255, 255, 255, 0)',
    'rgba(255, 255, 255, 0.1)',
    'rgba(255, 255, 255, 0.2)',
    'rgba(255, 255, 255, 0.3)',
    'rgba(255, 255, 255, 0.4)',
    'rgba(255, 255, 255, 0.5)',
    'rgba(255, 255, 255, 0.6)',
    'rgba(255, 255, 255, 0.7)',
    'rgba(255, 255, 255, 0.8)',
    'rgba(255, 255, 255, 0.9)',
    'rgba(255, 255, 255, 1)'
  ],
  opaqueBlack: [
    'rgba(0, 0, 0, 0)',
    'rgba(0, 0, 0, 0.1)',
    'rgba(0, 0, 0, 0.2)',
    'rgba(0, 0, 0, 0.3)',
    'rgba(0, 0, 0, 0.4)',
    'rgba(0, 0, 0, 0.5)',
    'rgba(0, 0, 0, 0.6)',
    'rgba(0, 0, 0, 0.7)',
    'rgba(0, 0, 0, 0.8)',
    'rgba(0, 0, 0, 0.9)',
    'rgba(0, 0, 0, 1)'
  ]
})

const _baseColors = baseColors()

/** Add Cosmos tokens here **/
export const colors: ColorModesScale = {
  primary: {
    __default: '#0559b3', //    primaries.3   cosmos primary
    medium: '#669BD1', //       primaries.5   cosmos primary medium
    light: '#E3F3FC' //         primaries.6   cosmos primary light
  } as NestedScaleDict<CSS.Property.Color>,
  secondary: {
    __default: '#1b3a6e', //      primaries.0   cosmos secondary
    dark: '#103060',
    darker: '#24335A'
  } as NestedScaleDict<CSS.Property.Color>,
  neutral: {
    __default: '#cecece', //    neutrals.17 cosmos neutral
    lighter: '#f5f5f5', //      neutrals.21 cosmos neutral lighter
    light: '#eaeaea', //        neutrals.19 cosmos neutral light
    medium: '#bfbfbf', //       neutrals.15 cosmos neutral medium
    dark: '#757575', //         neutrals.11 cosmos neutral dark
    darker: '#404040' //        neutrals.6  cosmos neutral darker
  } as NestedScaleDict<CSS.Property.Color>,
  muted: '#e5e5e5', //          neutrals.21   cosmos neutral lighter
  accent: {
    __default: '#ec4a08', //    alerts.0 cosmos accent
    background: '#fdede6'
  },
  highlight18: '#a37200', // Cosmos 2.0 colour - requires a defined token
  text: {
    __default: '#404040', //    neutrals.6    suite standard
    secondary: '#757575' //     neutrals.11   cosmos neutral dark
  },
  textSecondary: '#757575', //  neutrals.11   cosmos neutral dark
  'text-light-bg': 'black',
  'text-dark-bg': 'white',
  border: '#bfbfbf', //         neutrals.15   cosmos neutral medium
  white: '#fff', //             neutrals.22
  black: '#000', //             neutrals.0
  gray: {
    dark: '#292A2D' // chrome default title tooltip back ground
  },
  chart: {
    __default: '#f28f20',
    highlight: {
      '1': '#669BD1',
      '2': '#2186a5',
      '3': '#7db654',
      '4': '#fde11f',
      '5': '#1e3a6f',
      '6': '#733d94',
      '7': '#d2232a'
    }
  } as NestedScaleDict<CSS.Property.Color>,
  legend: {
    __default: '#0559B3',
    highlight: {
      '1': '#1b3a6e',
      '2': '#cf0201',
      '3': '#59942a',
      '4': '#017b30',
      '5': '#ffb400',
      '6': '#fff7e6',
      '7': '#ff850f',
      '8': '#fde11f',
      '9': '#614488',
      '10': '#a8e3da',
      '11': '#00a88c',
      '12': '#669bd1',
      '13': '#4f95d4',
      '14': '#b39ccd',
      '15': '#ab8fcf',
      '16': '#8768ab',
      '17': '#bfbfbf',
      '18': '#a37200',
      '19': '#4ca0fa',
      '20': '#1e3a6f'
    }
  } as NestedScaleDict<CSS.Property.Color>,
  background: 'transparent',
  'white-bg': '#fff',
  danger: '#cf0201', //         error.0       cosmos red
  success: '#017b30', //        successes.0   cosmos green
  warning: '#ffb400', //        highlights.0  cosmos yellow
  'app-background': '#f5f5f5', //     neutral.lighter
  'content-background': 'transparent',
  'content-wrapper-background': '#fff',
  ..._baseColors,
  modes: {
    dark: {
      muted: '#757575', //          neutrals.11
      'text-light-bg': 'white',
      'text-dark-bg': 'black',
      'white-bg': 'transparent',
      background: '#333333',
      accent: '#fcbca2', //         alerts.5
      text: '#f2f2f2', //           neutrals.20
      textSecondary: '#bfbfbf', //  neutrals.15
      primary: {
        __default: '#5D8AEB', //    used https://convertingcolors.com/
        medium: '#9FD1FF', //       used https://convertingcolors.com/
        light: '#CAEAFC' //         used https://convertingcolors.com/
      },
      neutral: {
        __default: '#404040',
        lighter: '#757575',
        light: '#bfbfbf',
        medium: '#eaeaea',
        dark: '#f5f5f5',
        darker: '#cecece'
      },
      secondary: '#e3f3fc', //      primaries.6
      black: '#fff',
      white: '#000',
      danger: '#fc664c', //         error.3       cosmos red
      success: '#41fe24', //        successes.3   cosmos green
      warning: '#ffe29c', //        highlights.1  cosmos yellow
      neutrals: _baseColors.neutrals.slice().reverse(),
      primaries: _baseColors.primaries.slice().reverse(),
      successes: _baseColors.successes.slice().reverse(),
      errors: _baseColors.errors.slice().reverse(),
      alerts: _baseColors.alerts.slice().reverse(),
      highlights: _baseColors.highlights.slice().reverse(),
      red: _baseColors.red.slice().reverse(),
      orange: _baseColors.orange.slice().reverse(),
      yellow: _baseColors.yellow.slice().reverse(),
      green: _baseColors.green.slice().reverse(),
      teal: _baseColors.teal.slice().reverse(),
      blue: _baseColors.blue.slice().reverse(),
      indigo: _baseColors.indigo.slice().reverse(),
      purple: _baseColors.purple.slice().reverse(),
      pink: _baseColors.pink.slice().reverse(),
      opaqueWhite: _baseColors.opaqueWhite.slice().reverse(),
      opaqueBlack: _baseColors.opaqueBlack.slice().reverse()
    }
  }
}

export type ColorKeys =
  | 'primary'
  | 'primary.medium'
  | 'primary.light'
  | 'secondary'
  | 'neutral'
  | 'neutral.lighter'
  | 'neutral.light'
  | 'neutral.medium'
  | 'neutral.dark'
  | 'neutral.darker'
  | 'muted'
  | 'accent'
  | 'text'
  | 'text.secondary'
  | 'white'
  | 'black'
  | 'app-background'
  | 'content-background'
  | 'content-wrapper-background'

export const colorOptions: Record<ColorKeys, string> = {
  primary: 'Primary',
  'primary.medium': 'Primary Medium',
  'primary.light': 'Primary Light',
  secondary: 'Secondary',
  neutral: 'Neutral',
  'neutral.lighter': 'Neutral Lighter',
  'neutral.light': 'Neutral Light',
  'neutral.medium': 'Neutral medium',
  'neutral.dark': 'Neutral Dark',
  'neutral.darker': 'Neutral Darker',
  muted: 'Muted',
  accent: 'Accent',
  text: 'Text',
  'text.secondary': 'Text Secondary',
  white: 'White',
  black: 'Black',
  'app-background': 'App Background',
  'content-background': 'Content Background',
  'content-wrapper-background': 'Content Wrapper Background'
}

export const colorOptionKeys = Object.keys(colorOptions)
