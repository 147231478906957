/* eslint-disable @typescript-eslint/no-explicit-any */

import shadow from '../styles-system/shadow'
import {
  elevations,
  isElevationThemeKeys
} from '../themes/theme-keys/elevations'
import { isNumber } from '../utils/assertion'
import { get } from '../utils/object'
import toPx from '../utils/to-px'

export default function (
  value: string | number,
  scale: any,
  props: any,
  variant?: string
): string | undefined {
  const _value = isElevationThemeKeys(value)
    ? get(props.theme, `elevations.${value}`, (elevations as any)[value])
    : value
  const styleVariant = get(props, variant as string, { boxShadow: variant })
  let boxShadow: { variant: string } | string | undefined = props
    ? styleVariant && styleVariant.boxShadow
      ? get(props, `shadows.${styleVariant.boxShadow}`)
      : props.boxShadow
        ? get(scale, props.boxShadow)
        : props.__css
          ? get(scale, props.__css.boxShadow)
          : undefined
    : undefined
  if (typeof boxShadow === 'object' && boxShadow.variant) {
    boxShadow = get(scale, boxShadow.variant.replace('shadows.', ''))
  }

  if (typeof boxShadow === 'string') {
    const retVal = boxShadow.includes('rgba')
      ? boxShadow.slice(boxShadow.indexOf('rgba'))
      : boxShadow.includes('#')
        ? boxShadow.slice(boxShadow.indexOf('#'))
        : shadow
    return isElevationThemeKeys(value) || isNumber(_value)
      ? `0px ${toPx(_value) / 2}px ${toPx(_value)}px 0px ${retVal}`
      : boxShadow
  }
  return
}
