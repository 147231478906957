import type { ThemeUIContextValue } from '@theme-ui/core'
import { useThemeUI as useBaseThemeUI } from '@theme-ui/core'

import type { Theme } from './types'
import { EMPTY_THEME } from '../themes/const'
import { isEmptyObject } from '../utils/assertion'

export function useSafeThemeUI(props?: { theme?: Theme }) {
  const { theme } = useBaseThemeUI()
  if (isEmptyObject(theme)) {
    return props?.theme || EMPTY_THEME
  }
  return theme as Theme
}

export default function (): Omit<ThemeUIContextValue, 'theme'> & {
  theme?: Theme
} {
  return useBaseThemeUI() as Omit<ThemeUIContextValue, 'theme'> & {
    theme?: Theme
  }
}
