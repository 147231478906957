import fastMemoize from 'fast-memoize'

import type { ScaleKey } from './types'

export enum FontScales {
  'none',
  'lp1-16',
  'lp2-8',
  'lp8-2',
  'mn3-6',
  'mj2-9',
  'a4-3',
  'mn2-16',
  'lp1-15',
  'mn2-15',
  'gr-2',
  'mj2-8',
  'mn3-5',
  'mn2-14',
  'mj3-4',
  'lp1-14',
  'lp2-7',
  'p4-3',
  'mn2-13',
  'mj2-7',
  'p5-2',
  'lp1-13',
  'mn2-12',
  'mn3-4',
  'mn2-11',
  'mj2-6',
  'lp1-12',
  'lp2-6',
  'a4-2',
  'mj3-3',
  'mn2-10',
  'lp1-11',
  'mj2-5',
  'mn2-9',
  'p4-2',
  'mn3-3',
  'lp1-10',
  'lp2-5',
  'mn2-8',
  'gr-1',
  'mj2-4',
  'lp1-9',
  'mn2-7',
  'mj3-2',
  'lp1-8',
  'lp2-4',
  'lp8-1',
  'p5-1',
  'mn2-6',
  'mn3-2',
  'mj2-3',
  'a4-1',
  'lp1-7',
  'mn2-5',
  'lp1-6',
  'lp2-3',
  'p4-1',
  'mn2-4',
  'mj2-2',
  'lp1-5',
  'mj3-1',
  'mn2-3',
  'lp1-4',
  'lp2-2',
  'mn3-1',
  'lp1-3',
  'mn2-2',
  'mj2-1',
  'lp1-2',
  'lp2-1',
  'mn2-1',
  'lp1-1',
  'base',
  'lp1+1',
  'mn2+1',
  'lp1+2',
  'lp2+1',
  'lp1+3',
  'mj2+1',
  'mn2+2',
  'lp1+4',
  'lp2+2',
  'mn3+1',
  'lp1+5',
  'mn2+3',
  'lp1+6',
  'lp2+3',
  'mj3+1',
  'mj2+2',
  'lp1+7',
  'mn2+4',
  'p4+1',
  'lp1+8',
  'lp2+4',
  'lp8+1',
  'lp1+9',
  'mn2+5',
  'a4+1',
  'lp1+10',
  'lp2+5',
  'mj2+3',
  'mn3+2',
  'lp1+11',
  'mn2+6',
  'lp1+12',
  'lp2+6',
  'p5+1',
  'lp1+13',
  'mj3+2',
  'mn2+7',
  'lp1+14',
  'lp2+7',
  'mj2+4',
  'gr+1',
  'lp1+15',
  'lp1+16',
  'lp2+8',
  'lp8+2',
  'mn2+8',
  'lp1+17',
  'mn3+3',
  'lp1+18',
  'lp2+9',
  'p4+2',
  'lp1+19',
  'mn2+9',
  'mj2+5',
  'lp1+20',
  'lp2+10',
  'mn2+10',
  'lp2+11',
  'mj3+3',
  'a4+2',
  'lp2+12',
  'lp8+3',
  'mj2+6',
  'mn2+11',
  'mn3+4',
  'lp2+13',
  'lp2+14',
  'mn2+12',
  'lp2+15',
  'p5+2',
  'mj2+7',
  'mn2+13',
  'lp2+16',
  'lp8+4',
  'p4+3',
  'lp2+17',
  'mj3+4',
  'mn2+14',
  'mn3+5',
  'lp2+18'
}

export enum SpaceScales {
  'none',
  'lp1-14',
  'lp2-7',
  'a4-6',
  'p5-5',
  'mj3-9',
  'p4-7',
  'mj2-17',
  'mn3-11',
  'gr-4',
  'mj2-16',
  'mn3-10',
  'mj3-8',
  'mj2-15',
  'a4-5',
  'p4-6',
  'lp1-13',
  'mj2-14',
  'mn3-9',
  'p5-4',
  'mj3-7',
  'mj2-13',
  'mn3-8',
  'gr-3',
  'p4-5',
  'mj2-12',
  'lp1-12',
  'lp2-6',
  'a4-4',
  'mj3-6',
  'mn2-20',
  'mj2-11',
  'mn3-7',
  'mn2-19',
  'p5-3',
  'mj2-10',
  'mn2-18',
  'lp1-11',
  'p4-4',
  'mj3-5',
  'mn2-17',
  'mn3-6',
  'mj2-9',
  'mn2-16',
  'a4-3',
  'lp1-10',
  'lp2-5',
  'mn2-15',
  'gr-2',
  'mj2-8',
  'mn3-5',
  'mn2-14',
  'mj3-4',
  'p4-3',
  'mn2-13',
  'lp1-9',
  'mj2-7',
  'p5-2',
  'mn2-12',
  'mn3-4',
  'mn2-11',
  'mj2-6',
  'lp1-8',
  'lp2-4',
  'lp8-1',
  'a4-2',
  'mj3-3',
  'mn2-10',
  'mj2-5',
  'mn2-9',
  'lp1-7',
  'p4-2',
  'mn3-3',
  'mn2-8',
  'gr-1',
  'mj2-4',
  'lp1-6',
  'lp2-3',
  'mn2-7',
  'mj3-2',
  'p5-1',
  'mn2-6',
  'lp1-5',
  'mn3-2',
  'mj2-3',
  'a4-1',
  'mn2-5',
  'lp1-4',
  'lp2-2',
  'p4-1',
  'mn2-4',
  'mj2-2',
  'mj3-1',
  'lp1-3',
  'mn2-3',
  'mn3-1',
  'lp1-2',
  'lp2-1',
  'mn2-2',
  'mj2-1',
  'mn2-1',
  'lp1-1',
  'base',
  'lp1+1',
  'mn2+1',
  'lp1+2',
  'lp2+1',
  'mj2+1',
  'mn2+2',
  'lp1+3',
  'mn3+1',
  'mn2+3',
  'lp1+4',
  'lp2+2',
  'mj3+1',
  'mj2+2',
  'mn2+4',
  'lp1+5',
  'p4+1',
  'lp1+6',
  'lp2+3',
  'mn2+5',
  'a4+1',
  'mj2+3',
  'lp1+7',
  'mn3+2',
  'mn2+6',
  'lp1+8',
  'lp2+4',
  'lp8+1',
  'p5+1',
  'lp1+9',
  'mj3+2',
  'mn2+7',
  'mj2+4',
  'gr+1',
  'lp1+10',
  'lp2+5',
  'mn2+8',
  'lp1+11',
  'mn3+3',
  'lp1+12',
  'lp2+6',
  'p4+2',
  'mn2+9',
  'mj2+5',
  'lp1+13',
  'lp1+14',
  'lp2+7',
  'mn2+10',
  'lp1+15',
  'mj3+3',
  'a4+2',
  'lp1+16',
  'lp2+8',
  'lp8+2',
  'mj2+6',
  'mn2+11',
  'lp1+17',
  'mn3+4',
  'lp1+18',
  'lp2+9',
  'mn2+12',
  'lp1+19',
  'lp1+20',
  'lp2+10',
  'p5+2',
  'mj2+7',
  'mn2+13',
  'p4+3',
  'lp2+11',
  'mj3+4',
  'mn2+14',
  'mn3+5',
  'lp2+12',
  'lp8+3',
  'mj2+8',
  'gr+2',
  'lp2+13',
  'mn2+15',
  'lp2+14',
  'mn2+16',
  'a4+3',
  'lp2+15',
  'mj2+9',
  'mn3+6',
  'lp2+16',
  'lp8+4',
  'mn2+17',
  'mj3+5',
  'lp2+17',
  'p4+4',
  'mn2+18',
  'mj2+10',
  'lp2+18',
  'lp2+19',
  'p5+3',
  'mn2+19',
  'lp2+20',
  'lp8+5',
  'mn3+7',
  'mj2+11',
  'mn2+20',
  'mj3+6',
  'a4+4',
  'lp8+6'
}

function generateIntegersArray(l: number) {
  return Array.from({ length: l }, (v, k) => k + 1)
}
export const ScalesStepsFromBase = generateIntegersArray(20)

export const ScalesInfo: Record<ScaleKey, { name: string; factor: number }> = {
  lp8: { name: 'linear', factor: 8 },
  lp2: { name: 'linear', factor: 2 },
  lp1: { name: 'linear', factor: 1 },
  mn2: { name: 'minor-second', factor: 1.067 },
  mj2: { name: 'major-second', factor: 1.125 },
  mn3: { name: 'minor-third', factor: 1.2 },
  mj3: { name: 'major-third', factor: 1.25 },
  p4: { name: 'perfect forth', factor: 1.333 },
  a4: { name: 'augmented forth', factor: 1.414 },
  p5: { name: 'perfect fifth', factor: 1.5 },
  gr: { name: 'golden ratio', factor: 1.618 }
}

const nextUpScale =
  (factor: number, base: number, isLinear?: boolean) => (cur: number) =>
    isLinear
      ? base + factor * cur
      : generateIntegersArray(cur).reduce(acc => acc * factor, base)
const nextDownScale =
  (factor: number, base: number, isLinear?: boolean) => (cur: number) =>
    isLinear
      ? base - factor * cur
      : generateIntegersArray(cur).reduce(acc => acc / factor, base)

// lp8 - linear increment 8 px
// lp2 - linear increment 2 px
// lp1 - linear increment 1 px
// MN2 - minor-second scale 1.067 ratio
// MN2 - minor-second scale 1.067 ratio
// MJ2 - major-second scale 1.125 ratio
// MN3 - minor-third scale 1.2 ratio
// MJ3 - major-third scale 1.25 ratio
// P4 - perfect forth scale 1.333 ratio
// A4 - augmented forth scale 1.414 ratio
// P5 - perfect fifth scale 1.5 ratio
// GR - golden ratio scale 1.618 ratio
export const generateScaleObject = (
  prefix: ScaleKey,
  factor: number,
  base: number,
  isPxUnit?: boolean,
  minPx = 8, // use 8 for fonts, 2 for space
  maxPx = 60 // use 60 for fonts, 40 for space.
): {
  [x: string]: number
} => {
  const isLinear = prefix.startsWith('l')
  const _base = !isPxUnit ? base * 16 : base
  const _nextUpScale = nextUpScale(factor, _base, isLinear)
  const _nextDownScale = nextDownScale(factor, _base, isLinear)

  const shouldInclude = (value: number) => value >= minPx && value <= maxPx

  const upScale = ScalesStepsFromBase.reduce(
    (acc, cur) => {
      const nextValue = _nextUpScale(cur)
      return shouldInclude(nextValue)
        ? {
            ...acc,
            [`${prefix}+${cur}`]: Number(
              (isPxUnit ? nextValue : nextValue / 16).toFixed(3)
            )
          }
        : acc
    },
    {} as Record<string, number>
  )
  const downScale = ScalesStepsFromBase.reduce(
    (acc, cur) => {
      const nextValue = _nextDownScale(cur)
      return shouldInclude(nextValue)
        ? {
            ...acc,
            [`${prefix}-${cur}`]: Number(
              (isPxUnit ? nextValue : nextValue / 16).toFixed(3)
            )
          }
        : acc
    },
    {} as Record<string, number>
  )

  return {
    ...upScale,
    ...downScale
  }
}

// lp8 - linear increment 8 px
// lp2 - linear increment 2 px
// lp1 - linear increment 1 px
// MN2 - minor-second scale 1.067 ratio
// MJ2 - major-second scale 1.125 ratio
// MN3 - minor-third scale 1.2 ratio
// MJ3 - major-third scale 1.25 ratio
// P4 - perfect forth scale 1.333 ratio
// A4 - augmented forth scale 1.414 ratio
// P5 - perfect fifth scale 1.5 ratio
// GR - golden ratio scale 1.618 ratio
export const generateScalableUnits = fastMemoize(
  (
    base: number,
    scaleUnit: 'rem' | 'em' | 'px',
    minPx = 8, // use 8 for fonts, 2 for space
    maxPx = 60 // use 60 for fonts, 40 for space.
  ) => {
    const isPxUnit = scaleUnit === 'px'
    const fullScale: Record<string, number> = {
      none: 0,
      ...generateScaleObject('lp1', 1, base, isPxUnit, minPx, maxPx),
      ...generateScaleObject('lp2', 2, base, isPxUnit, minPx, maxPx),
      ...generateScaleObject('lp8', 8, base, isPxUnit, minPx, maxPx),
      ...generateScaleObject('mn2', 1.067, base, isPxUnit, minPx, maxPx),
      ...generateScaleObject('mj2', 1.125, base, isPxUnit, minPx, maxPx),
      ...generateScaleObject('mn3', 1.2, base, isPxUnit, minPx, maxPx),
      ...generateScaleObject('mj3', 1.25, base, isPxUnit, minPx, maxPx),
      ...generateScaleObject('p4', 1.333, base, isPxUnit, minPx, maxPx),
      ...generateScaleObject('a4', 1.414, base, isPxUnit, minPx, maxPx),
      ...generateScaleObject('p5', 1.5, base, isPxUnit, minPx, maxPx),
      ...generateScaleObject('gr', 1.61803398875, base, isPxUnit, minPx, maxPx),
      base: base
    }
    const sortedKeys = Object.keys(fullScale).sort(
      (a, b) => fullScale[a] - fullScale[b]
    )
    return sortedKeys.reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: fullScale[cur] === 0 ? '0' : `${fullScale[cur]}${scaleUnit}`
      }),
      {} as Record<string, string>
    )
  }
)

const generateScaleArray = (
  factor: number,
  base: number,
  isLinear?: boolean,
  isPxUnit?: boolean,
  minPx = 8, // use 8 for fonts, 2 for space
  maxPx = 60 // use 60 for fonts, 40 for space.
) => {
  const _base = !isPxUnit ? base * 16 : base
  const _nextUpScale = nextUpScale(factor, _base, isLinear)
  const _nextDownScale = nextDownScale(factor, _base, isLinear)
  const shouldInclude = (value: number) => value >= minPx && value <= maxPx
  const upScale = ScalesStepsFromBase.reduce((acc, cur) => {
    const nextValue = _nextUpScale(cur)
    return shouldInclude(nextValue)
      ? [...acc, Number((isPxUnit ? nextValue : nextValue / 16).toFixed(3))]
      : acc
  }, Array<number>())

  const downScale = ScalesStepsFromBase.reduce((acc, cur) => {
    const nextValue = _nextDownScale(cur)
    return shouldInclude(nextValue)
      ? [...acc, Number((isPxUnit ? nextValue : nextValue / 16).toFixed(3))]
      : acc
  }, Array<number>())
  return [...upScale, ...downScale]
}

export const generateFullScaleArray = fastMemoize(
  (
    base: number,
    scaleUnit?: 'rem' | 'em' | 'px',
    minPx = 8, // use 8 for fonts, 2 for space
    maxPx = 60 // use 60 for fonts, 40 for space.
  ) => {
    const isPxUnit = scaleUnit === 'px'
    const fullScale: number[] = [
      0,
      ...generateScaleArray(1, base, true, isPxUnit, minPx, maxPx),
      ...generateScaleArray(2, base, true, isPxUnit, minPx, maxPx),
      ...generateScaleArray(8, base, true, isPxUnit, minPx, maxPx),
      ...generateScaleArray(1.067, base, false, isPxUnit, minPx, maxPx), // MN2 - minor-second scale 1.067 ratio
      ...generateScaleArray(1.125, base, false, isPxUnit, minPx, maxPx), // MJ2 - major-second scale 1.125 ratio
      ...generateScaleArray(1.2, base, false, isPxUnit, minPx, maxPx), // MN3 - minor-third scale 1.2 ratio
      ...generateScaleArray(1.25, base, false, isPxUnit, minPx, maxPx), // MJ3 - major-third scale 1.25 ratio
      ...generateScaleArray(1.333, base, false, isPxUnit, minPx, maxPx), // P4 - perfect forth scale 1.333 ratio
      ...generateScaleArray(1.414, base, false, isPxUnit, minPx, maxPx), // A4 - augmented forth scale 1.414 ratio
      ...generateScaleArray(1.5, base, false, isPxUnit, minPx, maxPx), // P5 - perfect fifth scale 1.5 ratio
      ...generateScaleArray(1.61803398875, base, false, isPxUnit, minPx, maxPx), // GR - golden ratio scale 1.618 ratio
      base
    ]
    return scaleUnit
      ? fullScale
          .sort((a, b) => a - b)
          .map(i => (i === 0 ? '0' : `${i}${scaleUnit}`))
      : fullScale.sort((a, b) => a - b)
  }
)
