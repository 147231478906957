/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import fastMemoize from 'fast-memoize'
import type { ComponentType } from 'react'

import border from './border'
import color from './color'
import emSpace from './em-space'
import flexbox from './flexbox'
import grid from './grid'
import layout from './layout'
import position from './position'
import shadow from './shadow'
import space from './space'
import stylable from './stylable'
import { system } from './system-core'
import typography from './typography'

export const isSVGElement = (
  tag: keyof JSX.IntrinsicElements | ComponentType<any>
): boolean => tag === 'svg' || tag === 'line' || tag === 'circle'

export const systemProps = fastMemoize((isSvg?: boolean) => [
  ...space.propNames,
  ...emSpace.propNames,
  ...color.propNames,
  ...(isSvg
    ? // we want to allow height and width props to propagate to element attribute
      layout.propNames.filter((i: string) => i !== 'height' && i !== 'width')
    : layout.propNames),
  ...position.propNames,
  ...flexbox.propNames,
  ...typography.propNames,
  ...border.propNames,
  ...shadow.propNames,
  ...grid.propNames,
  ...stylable.propNames
])

const { width, height, ...rest } = layout

export const resolveLayout = (
  tag: keyof JSX.IntrinsicElements | ComponentType<any>
): any =>
  // For svg tags we remove styled-system width and height css func because
  // width and height are invalid in a svg element css class
  isSVGElement(tag) ? system(rest) : layout

export {
  border,
  color,
  emSpace,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography,
  stylable
}
