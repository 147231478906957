/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ModalContainer, ModalType } from './types'
import getScrollbarSize from '../../../utils/get-scrollbar-size'
import ownerDocument from '../../../utils/owner-document'
import ownerWindow from '../../../utils/owner-window'

// Is a vertical scrollbar displayed?
function isOverflowing(container: Element) {
  const doc = ownerDocument(container)

  if (doc.body === container) {
    return ownerWindow(doc).innerWidth > doc.documentElement.clientWidth
  }

  return container.scrollHeight > container.clientHeight
}

export function ariaHidden(node: Element, show: boolean) {
  if (show) {
    node.setAttribute('aria-hidden', 'true')
  } else {
    node.removeAttribute('aria-hidden')
  }
}

function getPaddingRight(node: Element) {
  return (
    parseInt((window.getComputedStyle(node) as any)['padding-right'], 10) || 0
  )
}

function ariaHiddenSiblings(
  container: Element,
  mountNode: Element | null | undefined,
  currentNode: Element | null | undefined,
  nodesToExclude: (Element | null | undefined)[] = [],
  show: boolean
) {
  const blacklist = [mountNode, currentNode, ...nodesToExclude]
  const blacklistTagNames = ['TEMPLATE', 'SCRIPT', 'STYLE']
  ;[].forEach.call(container.children, (node: Node) => {
    if (
      node &&
      node.nodeType === 1 &&
      blacklist.indexOf(node as Element) === -1 &&
      blacklistTagNames.indexOf((node as Element).tagName) === -1
    ) {
      ariaHidden(node as Element, show)
    }
  })
}

function findIndexOf(
  containerInfo: ModalContainer[],
  callback: (item: ModalContainer) => boolean
) {
  let idx = -1
  containerInfo.some((item, index) => {
    if (callback(item)) {
      idx = index
      return true
    }
    return false
  })
  return idx
}

function handleContainer(
  containerInfo: ModalContainer,
  props: { disableScrollLock?: boolean; scrollBarWidth?: number }
) {
  const restoreStyle: {
    value: string | null
    key: string
    el: HTMLDivElement
  }[] = []
  const restorePaddings: string[] = []
  const container = containerInfo.container
  let fixedNodes: NodeListOf<Element>

  if (!props.disableScrollLock && container) {
    if (isOverflowing(container)) {
      const _container = container as HTMLDivElement
      // Compute the size before applying overflow hidden to avoid any scroll jumps.
      const scrollbarSize = props.scrollBarWidth
        ? props.scrollBarWidth
        : getScrollbarSize()

      restoreStyle.push({
        value: _container.style.paddingRight,
        key: 'padding-right',
        el: _container
      })

      // Use computed style, here to get the real padding to add our scrollbar width.
      _container.style.paddingRight = `${
        getPaddingRight(container) + scrollbarSize
      }px`

      // .csg-tui-fixed is a global helper.
      fixedNodes = ownerDocument(container).querySelectorAll('.csg-tui-fixed')
      ;[].forEach.call(fixedNodes, (node: HTMLDivElement) => {
        restorePaddings.push(node.style.paddingRight)
        node.style.paddingRight = `${getPaddingRight(node) + scrollbarSize}px`
      })
    }

    // Improve Gatsby support
    // https://css-tricks.com/snippets/css/force-vertical-scrollbar/
    const parent = container.parentElement
    const scrollContainer =
      parent &&
      parent.nodeName === 'HTML' &&
      (window.getComputedStyle(parent) as any)['overflow-y'] === 'scroll'
        ? parent
        : container

    // Block the scroll even if no scrollbar is visible to account for mobile keyboard
    // screen size shrink.
    restoreStyle.push({
      value: (scrollContainer as HTMLDivElement).style.overflow,
      key: 'overflow',
      el: scrollContainer as HTMLDivElement
    })
    ;(scrollContainer as HTMLDivElement).style.overflow = 'hidden'
  }

  const restore = () => {
    if (fixedNodes) {
      ;[].forEach.call(fixedNodes, (node: HTMLDivElement, i) => {
        if (restorePaddings[i]) {
          node.style.paddingRight = restorePaddings[i]
        } else {
          node.style.removeProperty('padding-right')
        }
      })
    }

    restoreStyle.forEach(({ value, el, key }) => {
      if (value) {
        el.style.setProperty(key, value)
      } else {
        el.style.removeProperty(key)
      }
    })
  }

  return restore
}

function getHiddenSiblings(container: Element) {
  const hiddenSiblings: Element[] = []
  ;[].forEach.call(container.children, (node: Element) => {
    if (node.getAttribute && node.getAttribute('aria-hidden') === 'true') {
      hiddenSiblings.push(node)
    }
  })
  return hiddenSiblings
}

/**
 * @ignore - do not document.
 *
 * Proper state management for containers and the modals in those containers.
 * Simplified, but inspired by react-overlay's ModalManager class.
 * Used by the Modal to ensure proper styling of containers.
 */
export default class ModalManager {
  modals: ModalType[]
  containers: ModalContainer[]
  constructor() {
    this.modals = []
    this.containers = []
  }

  add(modal: ModalType, container: Element) {
    // eslint-disable-next-line no-console
    // console.log('------ modal-manager add ------')

    let modalIndex = this.modals.indexOf(modal)
    if (modalIndex !== -1) {
      return modalIndex
    }

    modalIndex = this.modals.length
    this.modals.push(modal)

    // If the modal we are adding is already in the DOM.
    if (modal.modalRef) {
      ariaHidden(modal.modalRef, false)
    }

    const hiddenSiblingNodes = getHiddenSiblings(container)
    ariaHiddenSiblings(
      container,
      modal.mountNode,
      modal.modalRef,
      hiddenSiblingNodes,
      true
    )

    const containerIndex = findIndexOf(
      this.containers,
      item => item.container === container
    )
    if (containerIndex !== -1) {
      const cur = this.containers[containerIndex]
      if (cur && cur.modals) {
        cur.modals.push(modal)
      }
      return modalIndex
    }

    this.containers.push({
      modals: [modal],
      container,
      restore: undefined,
      hiddenSiblingNodes
    })

    return modalIndex
  }

  mount(
    modal: ModalType,
    props: { disableScrollLock?: boolean; scrollBarWidth?: number }
  ) {
    // eslint-disable-next-line no-console
    // console.log('------ modal-manager mount ------')
    const containerIndex = findIndexOf(
      this.containers,
      item => !!item.modals && item.modals.indexOf(modal) !== -1
    )
    const containerInfo = this.containers[containerIndex]

    if (!containerInfo.restore) {
      containerInfo.restore = handleContainer(containerInfo, props)
    }
  }

  remove(modal: ModalType) {
    // eslint-disable-next-line no-console
    // console.log('------ modal-manager remove ------')
    const modalIndex = this.modals.indexOf(modal)

    if (modalIndex === -1) {
      return modalIndex
    }

    const containerIndex = findIndexOf(
      this.containers,
      item => !!item.modals && item.modals.indexOf(modal) !== -1
    )
    const containerInfo = this.containers[containerIndex]

    if (containerInfo && containerInfo.modals) {
      containerInfo.modals.splice(containerInfo.modals.indexOf(modal), 1)
      this.modals.splice(modalIndex, 1)

      // If that was the last modal in a container, clean up the container.
      if (containerInfo.modals.length === 0 && containerInfo.container) {
        // The modal might be closed before it had the chance to be mounted in the DOM.
        if (containerInfo.restore) {
          containerInfo.restore()
        }

        if (modal.modalRef) {
          // In case the modal wasn't in the DOM yet.
          ariaHidden(modal.modalRef, true)
        }

        ariaHiddenSiblings(
          containerInfo.container,
          modal.mountNode,
          modal.modalRef,
          containerInfo.hiddenSiblingNodes,
          false
        )
        this.containers.splice(containerIndex, 1)
      } else {
        // Otherwise make sure the next top modal is visible to a screen reader.
        const nextTop = containerInfo.modals[containerInfo.modals.length - 1]
        // as soon as a modal is adding its modalRef is undefined. it can't set
        // aria-hidden because the dom element doesn't exist either
        // when modal was unmounted before modalRef gets null
        if (nextTop.modalRef) {
          ariaHidden(nextTop.modalRef, false)
        }
      }
    }

    return modalIndex
  }

  isTopModal(modal: ModalType) {
    return (
      this.modals.length > 0 && this.modals[this.modals.length - 1] === modal
    )
  }
}
