// port from @styled-system/space v.5.1.2
import { getSpacing } from './space'
import { system } from './system-core'

const defaults = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512]
}

const config = {
  position: true,
  zIndex: {
    property: 'zIndex',
    scale: 'zIndices'
  },
  top: {
    property: 'top',
    scale: 'space',
    transform: getSpacing,
    defaultScale: defaults.space
  },
  right: {
    property: 'right',
    scale: 'space',
    transform: getSpacing,
    defaultScale: defaults.space
  },
  bottom: {
    property: 'bottom',
    scale: 'space',
    transform: getSpacing,
    defaultScale: defaults.space
  },
  left: {
    property: 'left',
    scale: 'space',
    transform: getSpacing,
    defaultScale: defaults.space
  }
}

export const position = system(config)

export default position
