/* eslint-disable @typescript-eslint/no-explicit-any */
import type { InputProps, InputThemeResolver, InputVariants } from './types'
import { getBaseReset } from '../../../styles-system/create-component'
import type { Theme, ThemeUICSSObject } from '../../../theme-provider/types'
import { THEME_UI_DEFAULT_KEY } from '../../../theme-provider/types'
import { ellipsis } from '../../../themes/const'
import { EMPTY_OBJECT } from '../../../utils/object'

export const INPUT_HOVER_PSEUDO_FIELD =
  '&:hover:not(.is-disabled, :focus-within, .is-read-only, .show-focus, .has-error)'
export const INPUT_FOCUS_PSEUDO_FIELD = '&:not(.is-read-only):focus-within'

const standardStyles: ThemeUICSSObject = {
  ...getBaseReset(), // provide reset to block style leak
  color: 'text',
  lineHeight: 'body',
  '&:not([readonly]).autocomplete-select': {
    cursor: 'pointer'
  }
}

const disabledStyles: ThemeUICSSObject = {
  '&[disabled]': {
    cursor: 'not-allowed',
    color: 'neutral.medium',
    '::selection': {
      color: 'none',
      background: 'none'
    },
    '::placeholder': {
      color: 'neutral.medium'
    }
  }
}

const focusStyles: ThemeUICSSObject = {
  zIndex: '2'
}

const placeholderStyles: ThemeUICSSObject = {
  '::placeholder': {
    color: 'neutral.dark'
  }
}

const hoverStyles: ThemeUICSSObject = {
  zIndex: '2',
  borderColor: 'black'
}

const readOnlyStyles: ThemeUICSSObject = {
  ':read-only:not(&[disabled]):not(.autocomplete-select)': {
    cursor: 'text',
    '&:focus-within': {
      boxShadow: 'none',
      border: '0'
    }
  }
}

const pointerStyles: ThemeUICSSObject = {
  '&:not(&[disabled]):not(.is-disabled)': {
    cursor: 'pointer'
  }
}

const getFocusStyles = (props: InputProps): ThemeUICSSObject => {
  const { state, disableBorderEffect } = props
  return {
    ...focusStyles,
    ...(disableBorderEffect
      ? {}
      : {
          borderColor: state ? state : 'primary',
          boxShadow: state ? state : 'primary'
        })
  }
}

const defaultInput = (
  _theme: Theme,
  props: InputProps & { __inputProps?: ThemeUICSSObject }
): ThemeUICSSObject => {
  const {
    mode,
    state,
    readOnly,
    disableBorderEffect,
    __inputProps = EMPTY_OBJECT as ThemeUICSSObject
  } = props
  const { type, ...rest } = __inputProps
  return {
    borderRadius: 'default',
    ...(disableBorderEffect
      ? {}
      : {
          border: '1px solid',
          borderColor: state ? state : 'neutral.medium'
        }),
    cursor: readOnly || mode !== 'select' ? 'text' : 'pointer',
    '&.is-disabled, &.is-read-only': {
      bg: 'neutral.light'
    },
    [INPUT_FOCUS_PSEUDO_FIELD]: getFocusStyles(props),
    '&.show-focus': getFocusStyles(props),
    [INPUT_HOVER_PSEUDO_FIELD]: {
      ...hoverStyles
    },
    [`input[type="${type}"]`]: {
      ...standardStyles,
      ...placeholderStyles,
      ...disabledStyles,
      ...readOnlyStyles,
      ...ellipsis,
      cursor: readOnly || mode !== 'select' ? 'text' : 'pointer',
      ...rest
    },
    [`input[type="${type}"].placeholder-as-value`]: {
      '::placeholder': {
        color: 'text'
      }
    },
    [`.csg-tui-${type}-input-msg`]: {
      color: state ? state : 'neutral.dark'
    }
  }
}

export default {
  [THEME_UI_DEFAULT_KEY]: defaultInput,
  'date-picker': (
    theme: Theme,
    props: InputProps & { __inputProps?: ThemeUICSSObject }
  ): ThemeUICSSObject => {
    const { __inputProps = EMPTY_OBJECT as ThemeUICSSObject } = props
    const { type, ...rest } = __inputProps

    const basePreset = (theme as any)?.forms?.input
    const baseVariant: InputThemeResolver =
      basePreset[THEME_UI_DEFAULT_KEY] ?? defaultInput
    return {
      ...baseVariant(theme, props),
      '&.is-read-only': {
        bg: 'unset'
      },
      [`input[type="${type}"]`]: {
        ...standardStyles,
        ...placeholderStyles,
        ...disabledStyles,
        ...ellipsis,
        ...pointerStyles,
        ...rest
      }
    }
  },
  'read-only': (
    theme: Theme,
    props: InputProps & { __inputProps?: ThemeUICSSObject }
  ): ThemeUICSSObject => {
    const { __inputProps = EMPTY_OBJECT as ThemeUICSSObject } = props
    const { type, ...rest } = __inputProps

    const basePreset = (theme as any)?.forms?.input
    const baseVariant: InputThemeResolver =
      basePreset[THEME_UI_DEFAULT_KEY] ?? defaultInput
    return {
      ...baseVariant(theme, props),
      [`input[type="${type}"]`]: {
        ...standardStyles,
        ...placeholderStyles,
        ...disabledStyles,
        ':read-only:not(&[disabled])': {
          cursor: 'text',
          '&:focus-within': {
            boxShadow: 'none',
            border: '0'
          }
        },
        ...ellipsis,
        ...rest
      }
    }
  }
} as InputVariants
