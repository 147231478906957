/* eslint-disable @typescript-eslint/no-explicit-any */
import { borderWidths } from './border-widths'
import { safeColor } from './utils'
import type {
  ObjectWithDefault,
  ThemeUICSSObject
} from '../../theme-provider/types'
import { THEME_UI_DEFAULT_KEY } from '../../theme-provider/types'

export type BorderThemeKey =
  | 'primary'
  | 'primaryThick'
  | 'primaryLight'
  | 'lightGrayThin'
  | 'lightGray'
  | 'lightGrayThick'
  | 'mediumGrayThin'
  | 'mediumGray'
  | 'mediumGrayThick'
  | 'neutralsGray'
  | 'secondary'
  | 'danger'
  | 'text'
  | 'textThick'
  | 'black'
  | 'blackImportant'
  | 'grayThin'
  | 'gray'
  | 'grayThick'
  | 'transparent'
  | 'transparentThick'
  | 'transparentThin'
  | 'red'
  | 'white'
  | 'alert'
  | 'neutral'
  | 'indicator-trend'

export type BorderThemeKeySupplement =
  | 'neutral.thick'
  | 'neutral.light'
  | 'neutral.lighter'
  | 'neutral.lighter.thick'
  | 'neutral.medium'
  | 'neutral.medium.thick'
  | 'neutral.dark'
  | 'neutral.dark.thick'
  | 'neutral.darker'
  | 'neutral.darker.thick'

function getBorders(
  colors: any
): Record<
  BorderThemeKey,
  ThemeUICSSObject['border'] | ObjectWithDefault<ThemeUICSSObject['border']>
> {
  return {
    primary: `${borderWidths.default} solid ${safeColor(colors.primary)}`,
    primaryThick: `${borderWidths.thick} solid ${safeColor(colors.primary)}`,
    primaryLight: `${borderWidths.default} solid ${safeColor(
      colors.primary.light
    )}`,
    lightGrayThin: `${borderWidths.thin} solid ${safeColor(
      colors.neutral.lighter
    )}`,
    lightGray: `${borderWidths.default} solid ${safeColor(
      colors.neutral.lighter
    )}`,
    lightGrayThick: `${borderWidths.thick} solid ${safeColor(
      colors.neutral.lighter
    )}`,
    neutralsGray: `${borderWidths.default} solid ${safeColor(
      colors.neutral.light
    )}`,
    mediumGrayThin: `${borderWidths.thin} solid ${safeColor(
      colors.neutral.medium
    )}`,
    mediumGray: `${borderWidths.default} solid ${safeColor(
      colors.neutral.medium
    )}`,
    mediumGrayThick: `${borderWidths.thick} solid ${safeColor(
      colors.neutral.medium
    )}`,
    secondary: {
      [THEME_UI_DEFAULT_KEY]: `${borderWidths.default} solid ${safeColor(
        colors.secondary
      )}`,
      dark: {
        [THEME_UI_DEFAULT_KEY]: `${borderWidths.default} solid ${safeColor(
          colors.secondary.dark
        )}`,
        thick: `${borderWidths.thick} solid ${safeColor(
          colors.secondary.dark
        )}`,
        thicker: `${borderWidths.thicker} solid ${safeColor(
          colors.secondary.dark
        )}`
      }
    },
    danger: `${borderWidths.default} solid ${safeColor(colors.danger)}`,
    text: `${borderWidths.default} solid ${safeColor(colors.text)}`,
    textThick: `${borderWidths.thick} solid ${safeColor(colors.text)}`,
    black: {
      [THEME_UI_DEFAULT_KEY]: `${borderWidths.default} solid ${safeColor(
        colors.black
      )}`,
      important: `${borderWidths.default} solid ${safeColor(
        colors.black
      )} !important`
    },
    blackImportant: `${borderWidths.default} solid ${safeColor(
      colors.black
    )} !important`,
    grayThin: `${borderWidths.thin} solid ${safeColor(colors.neutral.dark)}`,
    gray: `${borderWidths.default} solid ${safeColor(colors.neutral.dark)}`,
    grayThick: `${borderWidths.thick} solid ${safeColor(colors.neutral.dark)}`,
    transparent: `${borderWidths.default} solid transparent`,
    transparentThick: `${borderWidths.thick} solid transparent`,
    transparentThin: `${borderWidths.thin} solid transparent`,
    red: `${borderWidths.default} solid ${colors.red[5]}`,
    white: {
      [THEME_UI_DEFAULT_KEY]: `${borderWidths.default} solid ${safeColor(
        colors.white
      )}`,
      thick: `${borderWidths.thick} solid ${safeColor(colors.white)}`,
      thicker: `${borderWidths.thicker} solid ${safeColor(colors.white)}`
    },
    alert: {
      [THEME_UI_DEFAULT_KEY]: `${borderWidths.default} solid ${safeColor(
        colors.accent
      )}`
    },
    neutral: {
      [THEME_UI_DEFAULT_KEY]: `${borderWidths.default} solid ${safeColor(
        colors.neutral
      )}`,
      thick: `${borderWidths.thick} solid ${safeColor(colors.neutral)}`,
      light: {
        [THEME_UI_DEFAULT_KEY]: `${borderWidths.default} solid ${safeColor(
          colors.neutral.light
        )}`,
        thick: `${borderWidths.thick} solid ${safeColor(colors.neutral.light)}`,
        thicker: `${borderWidths.thicker} solid ${safeColor(
          colors.neutral.light
        )}`
      },
      lighter: {
        [THEME_UI_DEFAULT_KEY]: `${borderWidths.default} solid ${safeColor(
          colors.neutral.lighter
        )}`,
        thick: `${borderWidths.thick} solid ${safeColor(
          colors.neutral.lighter
        )}`,
        thicker: `${borderWidths.thicker} solid ${safeColor(
          colors.neutral.lighter
        )}`
      },
      medium: {
        [THEME_UI_DEFAULT_KEY]: `${borderWidths.default} solid ${safeColor(
          colors.neutral.medium
        )}`,
        thick: `${borderWidths.thick} solid ${safeColor(colors.neutral.medium)}`
      },
      dark: {
        [THEME_UI_DEFAULT_KEY]: `${borderWidths.default} solid ${safeColor(
          colors.neutral.dark
        )}`,
        thick: `${borderWidths.thick} solid ${safeColor(colors.neutral.dark)}`
      },
      darker: {
        [THEME_UI_DEFAULT_KEY]: `${borderWidths.default} solid ${safeColor(
          colors.neutral.darker
        )}`,
        thick: `${borderWidths.thick} solid ${safeColor(colors.neutral.darker)}`
      }
    },
    'indicator-trend': {
      [THEME_UI_DEFAULT_KEY]: `${borderWidths.default} solid ${safeColor(
        colors.neutral
      )}`,
      positive: `${borderWidths.default} solid ${colors.successes[0]}`,
      negative: `${borderWidths.default} solid ${colors.errors[0]}`
    }
  }
}

export default getBorders
