/* eslint-disable @typescript-eslint/no-explicit-any */

// https://github.com/ern0/kolorwheel.js 03/04/2020
// docs: http://linkbroker.hu/stuff/kolorwheel.js/
//

class KolorWheel {
  resultList: any[]
  workList: any[]
  step: number
  elm: any
  h: number
  s: number
  l: number

  constructor(color: any) {
    this.resultList = [this]
    this.workList = []
    this.elm = null
    this.h = 0
    this.s = 0
    this.l = 0
    this.step = 0

    if (typeof color == 'undefined') color = '#000000'
    if (typeof color.validateHsl == 'function') {
      this.setHsl([color.h, color.s, color.l])
    } else {
      this.setColor(color)
    } // else clone
  }

  setColor = (color: any) => {
    if (typeof color == 'undefined') return

    if (typeof color == 'object') {
      this.setHsl(color)
    } else {
      this.setHex(color)
    }
  } // setColor()

  setHsl = (hsl: number[]) => {
    this.h = hsl[0]
    this.s = hsl[1]
    this.l = hsl[2]
    this.validateHsl()

    return this
  } // setHsl()

  validateHsl = () => {
    this.h = this.h % 360
    if (this.h < 0) this.h += 360

    if (this.s < 0) this.s = 0
    if (this.s > 100) this.s = 100
    if (this.l < 0) this.l = 0
    if (this.l > 100) this.l = 100
  } // validateHsl()

  setHex = (hex: string) => {
    if (hex.substring(0, 1) == '#') hex = hex.substring(1)

    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)
    this.setRgb([r, g, b])

    return this
  } // setHex()

  setRgb = (rgb: number[]) => {
    const r = rgb[0] / 255
    const g = rgb[1] / 255
    const b = rgb[2] / 255

    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    this.h = (max + min) / 2
    this.s = this.h
    this.l = this.h

    if (max == min) {
      // achromatic

      this.h = 0
      this.s = 0
    } else {
      const d = max - min
      this.s = this.l > 0.5 ? d / (2 - max - min) : d / (max + min)

      switch (max) {
        case r:
          this.h = (g - b) / d + (g < b ? 6 : 0)
          break
        case g:
          this.h = (b - r) / d + 2
          break
        case b:
          this.h = (r - g) / d + 4
          break
      } // switch

      this.h = this.h / 6
    } // else achromatic

    this.h = 360 * this.h
    this.s = 100 * this.s
    this.l = 100 * this.l

    return this
  } // setRgb()

  hue2rgb = (p: number, q: number, t: number) => {
    if (t < 0) t += 1
    if (t > 1) t -= 1
    if (t < 1 / 6) return p + (q - p) * 6 * t
    if (t < 1 / 2) return q
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6

    return p
  } // hue2rgb()

  getRgb = () => {
    this.validateHsl()

    const h = this.h / 360
    const s = this.s / 100
    const l = this.l / 100

    let r = l
    let g = l
    let b = l

    if (s != 0) {
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s
      const p = 2 * l - q
      r = this.hue2rgb(p, q, h + 1 / 3)
      g = this.hue2rgb(p, q, h)
      b = this.hue2rgb(p, q, h - 1 / 3)
    } // if not achromatic

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
  } // getRgb()

  getHex = () => {
    const result = this.getRgb()

    let hex = this.toHexByte(result[0])
    hex += this.toHexByte(result[1])
    hex += this.toHexByte(result[2])

    return '#' + hex.toUpperCase()
  } // getHex()

  toHexByte = (number: number) => {
    let hexByte = number.toString(16)
    if (hexByte.length < 2) hexByte = '0' + hexByte

    return hexByte
  } // toHexByte()

  getHsl = () => {
    this.validateHsl()
    return [this.h, this.s, this.l]
  } // getHsl()

  multi = (fn: string, p1: any, p2: any, p3: any, p4: any, p5: any) => {
    const sourceList: any[] = [].concat(this.resultList as [])
    this.resultList = []
    for (const i in sourceList) {
      const src = sourceList[i]
      src.workList = []
      if (fn == 'rel') this.spinSingle('rel', p1, p2, p3, p4, p5)
      if (fn == 'abs') this.spinSingle('abs', p1, p2, p3, p4, p5)
      this.resultList = this.resultList.concat(src.workList)
    } // foreach sourceList

    if (this.resultList.length == 0) return this

    const lastResult = this.resultList[this.resultList.length - 1]
    this.h = lastResult.h
    this.s = lastResult.s
    this.l = lastResult.l

    return this
  } // multi()

  rel = (dh: any, ds: any, dl: any, length: any, start: any) => {
    return this.multi('rel', dh, ds, dl, length, start)
  } // rel()

  abs = (dh: any, ds: any, dl?: any, length?: any, start?: any) => {
    let isDhAColor = false
    if (typeof dh == 'object') {
      if (typeof dh.validateHsl == 'function') isDhAColor = true
    } else {
      if (('' + dh).substring(0, 1) == '#') isDhAColor = true
      if (('' + dh).length > 4) isDhAColor = true
    } // if dh is object

    if (isDhAColor) {
      const conv: any = new KolorWheel(dh)
      return this.multi('abs', conv.h, conv.s, conv.l, ds, start)
    } else {
      return this.multi('abs', dh, ds, dl, length, start)
    }
  } // abs()

  spinSingle = (
    mode: string,
    dh: string | number | any[],
    ds: string | number | any[],
    dl: string | number | any[],
    length: number,
    start: number
  ): void => {
    const unchanged = mode == 'abs' ? -1 : 0
    if (typeof dh == 'undefined') dh = unchanged
    if (typeof ds == 'undefined') ds = unchanged
    if (typeof dl == 'undefined') dl = unchanged

    if (typeof dh == 'undefined') length = 12
    let dhLength = 0
    let dsLength = 0
    let dlLength = 0
    if (typeof dh == 'object') dhLength = dh.length
    if (typeof ds == 'object') dsLength = ds.length
    if (typeof dl == 'object') dlLength = dl.length

    if (typeof length == 'undefined') {
      length = 1
      if (dhLength > length) length = dhLength
      if (dsLength > length) length = dsLength
      if (dlLength > length) length = dlLength
    }
    if (typeof start == 'undefined') start = 0

    let jquery: any = null
    if (typeof length == 'object') {
      jquery = length
      length = jquery.length
    }

    for (let step = start; step < length; step++) {
      const result: any = new KolorWheel(this)

      const progress = length == 1 ? 1 : step / (length - 1)
      let parmh: any
      let parms: any
      let parml: any

      if (dhLength > 0) {
        parmh = (dh as any)[step % dhLength]
      } else {
        parmh = (dh as any) * progress
      }

      if (dsLength > 0) {
        parms = (ds as any)[step % dsLength]
      } else {
        parms = (ds as any) * progress
      }

      if (dlLength > 0) {
        parml = (dl as any)[step % dlLength]
      } else {
        parml = (dl as any) * progress
      }

      if (mode == 'rel') {
        result.h += parmh
        result.s += parms
        result.l += parml
      } // if rel
      else {
        if (dh == unchanged) {
          result.h = this.h
        } else {
          if (dhLength == 0) {
            result.h = this.calcLinearGradientStep(
              step,
              length,
              this.h,
              dh as number
            )
          } else {
            result.h = parmh
          }
        }
        if (ds == unchanged) {
          result.s = this.s
        } else {
          if (dsLength == 0) {
            result.s = this.calcLinearGradientStep(
              step,
              length,
              this.s,
              ds as number
            )
          } else {
            result.s = parms
          }
        }
        if (dl == unchanged) {
          result.l = this.l
        } else {
          if (dlLength == 0) {
            result.l = this.calcLinearGradientStep(
              step,
              length,
              this.l,
              dl as number
            )
          } else {
            result.l = parml
          }
        }
      } // else abs

      result.step = step
      if (jquery) result.elm = jquery.eq(step)

      this.workList[step] = result
    } // for step
  } // spinSingle()

  calcLinearGradientStep = (
    step: number,
    length: number,
    base: number,
    target: number
  ) => {
    const progress = step / (length - 1)
    const result = base + (target - base) * progress

    return result
  } // calcLinearGradientStep();

  each = (fn: { call: (arg0: any, arg1: any) => void }) => {
    for (const i in this.resultList) {
      fn.call(this.resultList[i], this.resultList[i].elm)
    } // foreach result
  } // each()

  get = (n: number) => {
    if (typeof n == 'undefined') n = 0
    return this.resultList[n]
  } // get()

  isDark = () => {
    return !this.isLight()
  } // isDark()

  isLight = () => {
    const rgb = this.getRgb()
    const lum = 0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]

    return lum > 127
  } // isLight()
}

export default KolorWheel
