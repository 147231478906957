/* eslint-disable @typescript-eslint/no-explicit-any */
import { FontScales, SpaceScales } from '../scales'
import type { Size } from '../types'

const fontSize: Record<Size, FontScales> = {
  'xs-3': FontScales['lp2-8'], //   8px cosmos font-size-3x-small
  'xs-2': FontScales['lp2-7'], //  10px cosmos font-size-2x-small
  xxs: FontScales['lp2-6'], //     12px cosmos font-size-xx-small
  xs: FontScales['lp2-5'], //      14px cosmos font-x-size-small
  sm: FontScales['lp2-4'], //      16px cosmos font-size-small
  md: FontScales['lp2-3'], //      18px cosmos font-size-medium
  lg: FontScales['lp2-2'], //      20px cosmos 2.0 font-size-large
  xl: FontScales['lp2-1'], //      22px cosmos font-size-x-large
  xxl: FontScales['base'], //      24px cosmos font-size-xx-large
  'xl+2': FontScales['lp2+1'], //  26px cosmos 2.0
  'xl+3': FontScales['lp2+2'], //  28px cosmos font-size-3x-large
  'xl+4': FontScales['lp2+3'], //  30px cosmos 2.0 font-size-4x-large
  'xl+5': FontScales['lp2+4'], //  32px cosmos font-size-5x-large
  'xl+6': FontScales['lp2+8'] //   40px cosmos font-size-6x-large
}

// TO DO: Clean this up to match fontSizes
const spaceSize: Record<Exclude<Size, 'xl+5' | 'xl+6'>, SpaceScales> = {
  'xs-3': SpaceScales['lp2-7'], //    2px                          0.125rem
  'xs-2': SpaceScales['lp2-6'], //    4px   cosmos space-2x-small  0.25rem
  xxs: SpaceScales['lp2-5'], //       6px                          0.375rem
  xs: SpaceScales['lp2-4'], //        8px   cosmos space-x-small   0.5rem
  sm: SpaceScales['lp2-3'], //       10px                          0.625rem
  md: SpaceScales['lp2-2'], //       12px   cosmos space-small     0.75rem
  //'md+1': SpaceScales['lp2-1'], // 14px   cosmos 2 space-small-1 0.875rem
  lg: SpaceScales['base'], //        16px   cosmos space-medium
  xl: SpaceScales['lp8+1'], //       24px   cosmos space-large     1.5rem
  xxl: SpaceScales['lp8+2'], //      32px   cosmos space-x-large   2rem
  'xl+2': SpaceScales['lp8+3'], //   40px   cosmos space-2x-large  2.5rem
  'xl+3': SpaceScales['lp8+4'], //   48px  cosmos space-3x-large   3rem
  'xl+4': SpaceScales['lp8+5'] //    56px   cosmos space-4x-large  3.5rem
}

const spinnerSize: Record<Size, number> = {
  'xs-3': 10,
  'xs-2': 14,
  xxs: 16, // perfect fourth scale 1.333 ratio down
  xs: 19.2, // minor third scale 1.2 ratio up
  sm: 23.04,
  md: 27.648,
  lg: 33.18,
  xl: 39.81,
  xxl: 47.78,
  'xl+2': 57.33,
  'xl+3': 68.8,
  'xl+4': 82.55,
  'xl+5': 99.07,
  'xl+6': 118.9
}

const spinnerSize_rem: Record<Size, string> = {
  'xs-3': '0.625rem',
  'xs-2': '0.875rem',
  xxs: '1rem',
  xs: '1.2rem',
  sm: '1.44rem',
  md: '1.728rem',
  lg: '2.4883rem',
  xl: '2.986rem',
  xxl: '3.5832rem',
  'xl+2': '4.2999rem',
  'xl+3': '5.1598rem',
  'xl+4': '6.1918rem',
  'xl+5': '7.4301rem',
  'xl+6': '8.9161rem'
}

// liner 2 x ratio
const iconSize: Record<Exclude<Size, 'xl+5' | 'xl+6'>, number> = {
  'xs-3': 8,
  'xs-2': 10,
  xxs: 12,
  xs: 14,
  sm: 16,
  md: 18,
  lg: 20,
  xl: 22,
  xxl: 24,
  'xl+2': 26,
  'xl+3': 28,
  'xl+4': 30
}

const iconSize_rem: Record<Exclude<Size, 'xl+5' | 'xl+6'>, string> = {
  'xs-3': '0.5rem',
  'xs-2': '0.625rem',
  xxs: '0.75rem',
  xs: '0.875rem',
  sm: '1rem',
  md: '1.125rem',
  lg: '1.25rem',
  xl: '1.375rem',
  xxl: '1.5rem',
  'xl+2': '1.625rem',
  'xl+3': '1.75rem',
  'xl+4': '1.875rem'
}

const avatarSize: Record<
  Exclude<Size, 'xl+2' | 'xl+3' | 'xl+4' | 'xl+5' | 'xl+6'>,
  number
> = {
  'xs-3': 16,
  'xs-2': 18,
  xxs: 20,
  xs: 24, // minor third scale 1.200 ratio up
  sm: 28.8,
  md: 34.56,
  lg: 41.47,
  xl: 49.77,
  xxl: 59.72
}

const tabIndicator = {
  __default: '4px',
  thin: '2px',
  thick: '6px'
}

const tabIndicator_rem = {
  __default: '.25rem',
  thin: '.125rem',
  thick: '.375rem'
}

const buttonHeight = {
  __default: '32px',
  md: '32px',
  sm: '24px'
}

const buttonHeight_rem = {
  __default: '2rem',
  md: '2rem',
  sm: '1.5rem'
}

export const sizes = {
  font: fontSize,
  space: spaceSize,
  spinner: spinnerSize,
  icon: iconSize,
  avatar: avatarSize,
  tabs: {
    indicator: tabIndicator
  },
  button: {
    height: buttonHeight
  },
  form: {
    verticalSpacing: {
      __default: '44px'
    }
  }
}
export const sizes_rem = {
  font: fontSize,
  space: spaceSize,
  spinner: spinnerSize_rem,
  icon: iconSize_rem,
  avatar: avatarSize,
  tabs: {
    indicator: tabIndicator_rem
  },
  button: {
    height: buttonHeight_rem
  },
  form: {
    verticalSpacing: {
      __default: '2.75rem'
    }
  }
}

export default function (unit: 'px' | 'rem' = 'px'): any {
  return unit === 'px' ? sizes : sizes_rem
}
