import { system } from './system-core'
import elevationTransform from '../theme-provider/elevation-transform'
import lhCropTransform from '../theme-provider/lh-crop-transform'

const truncateOverflow = (maxLine: number) => `
-webkit-box;
-webkit-line-clamp: ${maxLine};
/*! autoprefixer: ignore next */
-webkit-box-orient: vertical;
overflow: hidden;
`

const stylableProps = {
  columns: true,
  spacing: true,
  transform: true,
  elevation: {
    property: 'boxShadow',
    scale: 'shadows',
    transform: elevationTransform
  },
  lhCrop: {
    property: 'paddingTop',
    scale: 'lhCrops',
    transform: lhCropTransform
  },
  maxLine: {
    property: 'display',
    scale: 'lineHeights',
    transform: (value: number): string => {
      return value ? truncateOverflow(value) : ''
    }
  }
}

export const stylable = system(stylableProps)

export default stylable
