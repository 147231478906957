/* eslint-disable @typescript-eslint/no-explicit-any */
import type { BadgeStatefulThemeProps, BadgeVariants } from './types'
import type {
  CSSObject,
  Theme,
  ThemeUICSSObject
} from '../../theme-provider/types'
import type { Transitions } from '../../themes/theme-keys/transitions'
import { transitions as defaultTransitions } from '../../themes/theme-keys/transitions'
import capitalize from '../../utils/capitalize'

const classes = {
  badge: (transitions: Transitions = defaultTransitions) =>
    ({
      transition: transitions.create('transform', {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.enteringScreen
      })
    }) as CSSObject,
  /* Styles applied to the root element if `type="dot"`. */
  dot: {
    borderRadius: '100%',
    height: '.5em',
    minWidth: '.5em',
    padding: 0
  } as CSSObject,

  anchorOriginTopRightSquare: {
    top: 0,
    right: 0,
    transform: 'scale(1) translate(50%, -50%)',
    transformOrigin: '100% 0%'
  } as CSSObject,
  /* Styles applied to the root element if `anchorOrigin={{ 'top', 'right' }} overlap="rectangular"`. */
  anchorOriginTopRightRectangular: {
    top: '0',
    right: 'auto',
    left: '55%',
    '&.invisible': {
      transform: 'scale(0) translate(50%, -50%)',
      border: 'unset'
    }
  } as CSSObject,
  /* Styles applied to the root element if `anchorOrigin={{ 'center', 'right' }} overlap="rectangular"`. */
  anchorOriginCenterRightRectangular: {
    top: 'auto',
    bottom: 'auto',
    right: 'auto',
    left: '65%',
    '&.invisible': {
      transform: 'scale(0) translate(50%, 0)',
      border: 'unset'
    }
  } as CSSObject,
  /* Styles applied to the root element if `anchorOrigin={{ 'bottom', 'right' }} overlap="rectangular"`. */
  anchorOriginBottomRightRectangular: {
    bottom: '0',
    right: 'auto',
    left: '55%',
    '&.invisible': {
      transform: 'scale(0) translate(50%, 50%)',
      border: 'unset'
    }
  } as CSSObject,
  /* Styles applied to the root element if `anchorOrigin={{ 'top', 'left' }} overlap="rectangular"`. */
  anchorOriginTopLeftRectangular: {
    top: '0',
    right: '55%',
    left: 'auto',
    '&.invisible': {
      transform: 'scale(0) translate(-50%, -50%)',
      border: 'unset'
    }
  } as CSSObject,
  /* Styles applied to the root element if `anchorOrigin={{ 'bottom', 'left' }} overlap="rectangular"`. */
  anchorOriginBottomLeftRectangular: {
    bottom: '0',
    right: '55%',
    left: 'auto',
    '&.invisible': {
      transform: 'scale(0) translate(-50%, 50%)',
      border: 'unset'
    }
  } as CSSObject,
  /* Styles applied to the root element if `anchorOrigin={{ 'top', 'right' }} overlap="circular"`. */
  anchorOriginTopRightCircular: {
    top: '14%',
    right: '14%',
    transform: 'scale(1) translate(50%, -50%)',
    transformOrigin: '100% 0%',
    '&.invisible': {
      transform: 'scale(0) translate(50%, -50%)',
      border: 'unset'
    }
  } as CSSObject,
  /* Styles applied to the root element if `anchorOrigin={{ 'bottom', 'right' }} overlap="circular"`. */
  anchorOriginBottomRightCircular: {
    bottom: '14%',
    right: '14%',
    transform: 'scale(1) translate(50%, 50%)',
    transformOrigin: '100% 100%',
    '&.invisible': {
      transform: 'scale(0) translate(50%, 50%)',
      border: 'unset'
    }
  } as CSSObject,
  /* Styles applied to the root element if `anchorOrigin={{ 'top', 'left' }} overlap="circular"`. */
  anchorOriginTopLeftCircular: {
    top: '14%',
    left: '14%',
    transform: 'scale(1) translate(-50%, -50%)',
    transformOrigin: '0% 0%',
    '&.invisible': {
      transform: 'scale(0) translate(-50%, -50%)',
      border: 'unset'
    }
  } as CSSObject,
  /* Styles applied to the root element if `anchorOrigin={{ 'bottom', 'left' }} overlap="circular"`. */
  anchorOriginBottomLeftCircular: {
    bottom: '14%',
    left: '14%',
    transform: 'scale(1) translate(-50%, 50%)',
    transformOrigin: '0% 100%',
    '&.invisible': {
      transform: 'scale(0) translate(-50%, 50%)',
      border: 'unset'
    }
  } as CSSObject,
  /* Pseudo-class to the badge `span` element if `invisible={true}`. */
  invisible: (transitions: Transitions = defaultTransitions) =>
    ({
      transition: transitions.create('transform', {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.leavingScreen
      })
    }) as CSSObject
}

export const badges: BadgeVariants = {
  default: (theme: Theme, props: BadgeStatefulThemeProps): ThemeUICSSObject => {
    const { transitions = defaultTransitions } = theme
    const { anchorOrigin, overlap = 'rectangular' } = props
    return {
      ...classes.badge(transitions as Transitions),
      '&.dot': classes.dot,
      ...(anchorOrigin
        ? (classes as any)[
            `anchorOrigin${capitalize(anchorOrigin.vertical)}${capitalize(
              anchorOrigin.horizontal
            )}${capitalize(overlap)}`
          ]
        : {}),
      ...classes.invisible(transitions as Transitions)
    }
  },
  white: (theme: Theme, props: BadgeStatefulThemeProps): ThemeUICSSObject => {
    const { transitions = defaultTransitions } = theme
    const { anchorOrigin, overlap = 'rectangular' } = props
    return {
      ...classes.badge(transitions as Transitions),
      color: 'primary',
      bg: 'white',
      '&.dot': classes.dot,
      ...(anchorOrigin
        ? (classes as any)[
            `anchorOrigin${capitalize(anchorOrigin.vertical)}${capitalize(
              anchorOrigin.horizontal
            )}${capitalize(overlap)}`
          ]
        : {}),
      ...classes.invisible(transitions as Transitions)
    }
  },
  primary: (theme: Theme, props: BadgeStatefulThemeProps): ThemeUICSSObject => {
    const { transitions = defaultTransitions } = theme
    const { anchorOrigin, overlap = 'rectangular' } = props
    return {
      ...classes.badge(transitions as Transitions),
      color: 'white',
      bg: 'primary',
      border: 'white',
      '&.dot': classes.dot,
      ...(anchorOrigin
        ? (classes as any)[
            `anchorOrigin${capitalize(anchorOrigin.vertical)}${capitalize(
              anchorOrigin.horizontal
            )}${capitalize(overlap)}`
          ]
        : {}),
      ...classes.invisible(transitions as Transitions)
    }
  },
  accent: (theme: Theme, props: BadgeStatefulThemeProps): ThemeUICSSObject => {
    const { transitions = defaultTransitions } = theme
    const { anchorOrigin, overlap = 'rectangular' } = props
    return {
      ...classes.badge(transitions as Transitions),
      color: 'white',
      bg: 'accent',
      border: 'white',
      ...classes.invisible(transitions as Transitions),
      '&.dot': classes.dot,
      ...(anchorOrigin
        ? (classes as any)[
            `anchorOrigin${capitalize(anchorOrigin.vertical)}${capitalize(
              anchorOrigin.horizontal
            )}${capitalize(overlap)}`
          ]
        : {}),
      ...classes.invisible(transitions as Transitions)
    }
  }
}

export type BadgeThemeVariants = keyof typeof badges
