/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FC } from 'react'
import React, { useState } from 'react'
import ReactDOM from 'react-dom'

import type { PortalProps } from './types'
import useForkRef from '../../../hooks/use-fork-ref'
import useIsomorphicEffect from '../../../hooks/use-isomorphic-effect'
import ThemeUIProvider from '../../../theme-provider/theme-ui-provider'
import { useSafeThemeUI } from '../../../theme-provider/use-theme-ui'
import setRef from '../../../utils/set-ref'

function getContainer(
  container?: React.ReactInstance | (() => React.ReactInstance | null) | null
) {
  container = typeof container === 'function' ? container() : container
  // #StrictMode ready
  // eslint-disable-next-line react/no-find-dom-node
  return ReactDOM.findDOMNode(container)
}

/**
 * Portals provide a first-class way to render children into a DOM node
 * that exists outside the DOM hierarchy of the parent component.
 */
const Portal: FC<PortalProps> = React.forwardRef<
  HTMLElement,
  PortalProps & { theme?: any }
>(function Portal(props, ref) {
  const { children, container, disablePortal = false, shouldHoistTheme } = props
  const theme = useSafeThemeUI(props)
  const [mountNode, setMountNode] = useState<HTMLElement | null>(null)
  const handleRef = useForkRef(
    // @ts-ignore
    React.isValidElement(children) ? children.ref : null,
    ref
  )

  useIsomorphicEffect(() => {
    if (!disablePortal) {
      setMountNode((getContainer(container) || document.body) as HTMLElement)
    }
  }, [container, disablePortal])

  useIsomorphicEffect(() => {
    if (mountNode && !disablePortal) {
      setRef(ref, mountNode)
      return () => {
        setRef(ref, null)
      }
    }

    return undefined
  }, [ref, mountNode, disablePortal])

  if (disablePortal) {
    if (React.isValidElement(children)) {
      return React.cloneElement(children, {
        ref: handleRef
      } as any)
    }
    return React.isValidElement(children) ? children : null
  }

  function wrapThemeUIProvide() {
    return shouldHoistTheme ? (
      <ThemeUIProvider theme={theme} hoistContext>
        {children}
      </ThemeUIProvider>
    ) : (
      children
    )
  }

  return mountNode
    ? ReactDOM.createPortal(wrapThemeUIProvide(), mountNode)
    : mountNode
})

// @ts-ignore
Portal.__className = '__csg-tui-portal'
// @ts-ignore
Portal.__selector = `.${Portal.__className}`

export default Portal
