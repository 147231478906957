// port from @styled-system/color v.5.1.2
import { system } from './system-core'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const config: any = {}
config.color = {
  property: 'color',
  scale: 'colors'
}
config.backgroundColor = {
  property: 'backgroundColor',
  scale: 'colors'
}
config.opacity = true

config.bg = config.backgroundColor

export const color = system(config)
export default color
