/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ThemeUICSSObject } from '../../theme-provider/types'

const mobile: ThemeUICSSObject = {
  rows: '1fr 7fr 56px 56px',
  columns: 'auto',
  area: `
"header "
"main"
"sidebar"
"footer"
`
}

const tablet: ThemeUICSSObject = {
  rows: '10% auto auto 10%',
  columns: '10% auto auto auto',
  area: `
"header header header header"
"sidebar main main main"
"sidebar main main main"
"footer footer footer footer"
`
}

const desktop: ThemeUICSSObject = {
  rows: '10% auto 10%',
  columns: '10% auto auto auto auto',
  area: `
"header header header header header"
"sidebar main main main aside"
"footer footer footer footer footer"
`
}

export const grids: Record<string, ThemeUICSSObject> = {
  sample: {
    gap: ['xxs', 'xs', 'sm', 'md', 'lg'],
    bg: 'yellow.5',
    height: '100vh',
    gridTemplateColumns: [
      mobile.columns,
      tablet.columns,
      null,
      desktop.columns
    ] as any,
    gridTemplateRows: [mobile.rows, tablet.rows, null, desktop.rows] as any,
    gridTemplateAreas: [mobile.area, tablet.area, null, desktop.area] as any
  },
  peerDetail: {
    border: '3px dotted red',
    padding: '3px',
    gap: '3px',

    gridTemplate: `
    "nav header" min-content
    "nav main" 1fr
        / min-content 1fr;
    `
  }
}

export type GridsThemeVariants = keyof typeof grids
