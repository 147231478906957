import type {
  ColorModeKey,
  Theme,
  ThemeUICSSObject
} from '../theme-provider/types'
import { getSafeShade } from '../utils/colors'
import { resolveColor } from '../utils/styled-props'

export type InputColorState =
  | ColorModeKey
  | 'neutrals.13'
  | 'neutral.light'
  | 'neutrals.3'
  | 'neutral'
  | 'white'
  | 'black'
  | 'neutral.medium'

export const resolveInputBoxShadow = (
  theme?: Theme,
  state?: InputColorState,
  inset?: boolean,
  blur = 0
): ThemeUICSSObject => {
  const color = resolveColor(theme, state)
  return color
    ? {
        boxShadow: `${inset ? 'inset ' : ''}0px 0px ${blur}px ${color}`
      }
    : {}
}

export const resolveInputOutline = (
  theme?: Theme,
  state?: InputColorState,
  inset?: boolean,
  disabled?: boolean,
  blur = 0
): ThemeUICSSObject => {
  const color = resolveColor(theme, state)
  return color
    ? {
        border: `1px solid ${getSafeShade(0.05, color)}`,
        ...(disabled ? {} : resolveInputBoxShadow(theme, state, inset, blur))
      }
    : {}
}
