/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef } from 'react'

function useTimeout(delay?: number | null) {
  const timerRef = useRef<any>()

  const executeFunction = useCallback(
    function (fn: () => void) {
      timerRef.current && clearTimeout(timerRef.current)

      timerRef.current = setTimeout(() => {
        fn()
      }, delay ?? 0)
    },
    [delay]
  )

  useEffect(() => {
    return () => {
      timerRef.current && clearTimeout(timerRef.current)
    }
  }, [])

  return executeFunction
}

export default useTimeout
