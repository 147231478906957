/* eslint-disable @typescript-eslint/no-explicit-any */

import type { CheckboxStatefulThemeProps, CheckboxVariants } from './types'
import {
  resolveInputBoxShadow,
  resolveInputOutline
} from '../../styles-system/input-element-utils'
import type { Theme, ThemeUICSSObject } from '../../theme-provider/types'
import { transitions } from '../../themes/theme-keys/transitions'
import { getSafeTint } from '../../utils/colors'
import { resolveColor } from '../../utils/styled-props'

type StyleStateProps = CheckboxStatefulThemeProps & {
  theme: Theme
}

const switchPosition = {
  top: '-0.15em',
  on: '1.15em',
  off: '-0.4em',
  neutral: '0.4em'
}

const baseSwitchIconStyle: ThemeUICSSObject = {
  content: '""',
  height: '1em',
  width: '1em',
  top: switchPosition.top,
  position: 'absolute',
  borderRadius: '100%',
  border: 'neutral.medium',
  borderImage: 'initial',
  transition: transitions.create('all', {
    duration: transitions.duration.short
  })
}

const standardHoverFocusBehaviors = ({
  theme,
  state,
  disabled
}: StyleStateProps) => ({
  focus: resolveInputOutline(
    theme,
    state ? state : 'primary',
    false,
    disabled,
    4
  ),
  hover: resolveInputOutline(
    theme,
    state ? state : 'primary',
    true,
    disabled,
    1
  )
})

const defaultBehaviors = ({
  theme,
  state,
  disabled,
  disableBg,
  disableColor
}: StyleStateProps) => ({
  ...standardHoverFocusBehaviors({
    theme,
    state,
    disabled
  }),
  disabledNotCheck: {
    ...resolveInputOutline(theme, disableBg ? disableBg : 'neutral.light')
  },
  disabledCheck: {
    color: disableColor ? disableColor : 'neutral.dark',
    ...resolveInputOutline(theme, disableBg ? disableBg : 'neutral.light'),
    backgroundColor: resolveColor(
      theme,
      disableBg ? disableBg : 'neutral.light'
    ),
    '&:hover': resolveInputOutline(
      theme,
      disableColor ? disableColor : 'neutral.light',
      true,
      disabled
    )
  }
})

export default {
  default: (
    theme: Theme,
    props: CheckboxStatefulThemeProps
  ): ThemeUICSSObject => {
    const { state, disabled, indeterminate, disableBg, disableColor } = props
    const _defaultBehaviors = defaultBehaviors({
      theme,
      state,
      disabled,
      indeterminate,
      disableBg,
      disableColor
    })
    return {
      '&:focus + div': _defaultBehaviors.focus,
      '&.is-active + div': _defaultBehaviors.focus,
      '&:hover:not([disabled]) + div': _defaultBehaviors.hover,
      '&.is-hover:not([disabled]) + div': _defaultBehaviors.hover,
      '&:checked + div': resolveInputOutline(theme, state ? state : 'primary'),
      '&:checked:focus + div': _defaultBehaviors.focus,
      '&:checked:focus:hover + div': resolveInputOutline(
        theme,
        state ? state : 'primary',
        true,
        disabled,
        1
      ),
      '&[disabled]:checked + div': _defaultBehaviors.disabledCheck,
      '&[disabled]:not([checked]) + div': _defaultBehaviors.disabledNotCheck,
      '&:disabled ~ span': {
        opacity: 0.6
      }
    }
  },
  primary: (
    theme: Theme,
    props: CheckboxStatefulThemeProps
  ): ThemeUICSSObject => {
    const {
      state,
      disabled,
      indeterminate,
      disableBg,
      disableColor,
      iconColor
    } = props
    const _defaultBehaviors = defaultBehaviors({
      theme,
      state,
      disabled,
      indeterminate,
      disableBg,
      disableColor
    })
    return {
      '& + div': {
        color: iconColor ? iconColor : 'white'
      },
      '&:indeterminate + div': {
        backgroundColor: resolveColor(theme, state ? state : 'primary')
      },
      '&:focus + div': _defaultBehaviors.focus,
      '&.is-active + div': _defaultBehaviors.focus,
      '&:hover:not([disabled]) + div:not([data-checked])': {
        border: 'black',
        boxShadow: 'black.inset'
      },
      '&:is-hover:not([disabled]) + div:not([data-checked])': {
        border: 'black',
        boxShadow: 'black.inset'
      },
      '&:checked': {
        '+ div': {
          ...resolveInputOutline(theme, state ? state : 'primary'),
          backgroundColor: resolveColor(theme, state ? state : 'primary')
        },
        ':focus + div': _defaultBehaviors.focus,
        ':hover:not([disabled]) + div': resolveInputOutline(
          theme,
          state ? state : 'primary',
          false,
          disabled,
          2
        )
      },
      '&:disabled': {
        '+ div': {
          cursor: 'not-allowed',
          ':not([data-checked])': _defaultBehaviors.disabledNotCheck
        },
        '.is-read-only + div + span': {
          cursor: 'auto'
        },
        ':not(.is-read-only) + div + span': {
          cursor: 'not-allowed'
        },
        ':not(.is-read-only) ~ span': {
          opacity: 0.6
        },
        ':checked + div': _defaultBehaviors.disabledCheck
      }
    }
  },
  switch: (
    theme: Theme,
    props: CheckboxStatefulThemeProps
  ): ThemeUICSSObject => {
    const { state, disabled, indeterminate, iconBg } = props
    const _standardHoverFocusBehaviors = standardHoverFocusBehaviors({
      theme,
      state
    })
    return {
      '& + div': {
        margin: 'unset',
        alignSelf: 'center',
        backgroundColor: resolveColor(theme, 'neutrals.17'),
        height: '.95em',
        position: 'relative',
        width: '2em',
        ...resolveInputOutline(theme, 'neutral.light'),
        borderImage: 'initial',
        borderRadius: 'pill',
        '&::before': {
          ...baseSwitchIconStyle,
          left: switchPosition.off,
          background: iconBg ? iconBg : 'white'
        }
      },
      '&:indeterminate + div': {
        backgroundColor: resolveColor(theme, state ? state : 'primary'),
        '&::before': {
          left: switchPosition.neutral
        }
      },
      '&:focus + div': _standardHoverFocusBehaviors.focus,
      '&.is-active + div': _standardHoverFocusBehaviors.focus,
      '&:hover:not([disabled]) + div': _standardHoverFocusBehaviors.hover,
      '&.is-hover:not([disabled]) + div': _standardHoverFocusBehaviors.hover,
      '&:checked:not([disabled]) + div': {
        ...resolveInputOutline(theme, state ? state : 'primary'),
        backgroundColor: resolveColor(theme, state ? state : 'primary'),
        '&::before': {
          left: indeterminate ? switchPosition.neutral : switchPosition.on,
          ...resolveInputOutline(theme, state ? state : 'primary')
        },
        '&:hover': resolveInputOutline(theme, state ? state : 'primary')
      },
      '&:checked:focus + div': _standardHoverFocusBehaviors.focus,
      '&[disabled]:not([checked]) + div': {
        ...resolveInputOutline(theme, 'neutral.light'),
        backgroundColor: resolveColor(theme, 'neutral.light'),
        '&:before': {
          background: resolveColor(theme, 'neutral.light'),
          ...resolveInputOutline(theme, 'neutral.light')
        }
      },
      '&[disabled]:checked + div': {
        '&:hover': resolveInputOutline(theme, 'neutral', true, disabled),
        '&::before': {
          left: indeterminate ? switchPosition.neutral : switchPosition.on,
          background: resolveColor(theme, 'neutral.light'),
          ...resolveInputOutline(theme, 'neutral.light')
        }
      },
      '&:disabled ~ span': {
        opacity: 0.6
      }
    }
  },
  checkIcon: (
    theme: Theme,
    props: CheckboxStatefulThemeProps
  ): ThemeUICSSObject => {
    const { state } = props
    return {
      '& + div': {
        borderRadius: '50%',
        mt: '.07em',
        color: getSafeTint(
          0.5,
          resolveColor(theme, state ? state : 'neutrals.5')
        )
      },
      '&:focus + div': {
        ...resolveInputBoxShadow(theme, state ? state : 'primary', false, 4),
        color: resolveColor(theme, state ? state : 'primary')
      },
      '&.is-active + div': {
        ...resolveInputBoxShadow(theme, state ? state : 'primary', false, 4),
        color: resolveColor(theme, state ? state : 'primary')
      },
      '&:hover:not([disabled]) + div': {
        color: resolveColor(theme, state ? state : 'primary'),
        bg: 'neutral.light'
      },
      '&.is-hover:not([disabled]) + div': {
        color: resolveColor(theme, state ? state : 'primary'),
        bg: 'neutral.light'
      },
      '&:checked:focus + div': {
        color: resolveColor(theme, state ? state : 'primary')
      },
      '&[disabled]:not([checked]) + div': {
        opacity: '0.2',
        color: 'text',
        bg: 'unset'
      },
      '&[disabled]:checked + div': {
        opacity: '0.4'
      },
      '&:checked + div': {
        color: resolveColor(theme, state ? state : 'primary')
      },
      '&:disabled:not(.is-read-only) ~ span': {
        opacity: 0.6
      }
    }
  },
  //Temp variant until checkbox are reviewed
  checkIconSecondary: (
    theme: Theme,
    props: CheckboxStatefulThemeProps
  ): ThemeUICSSObject => {
    const { state } = props
    return {
      '& + div': {
        borderRadius: '50%',
        mt: '.07em',
        color: getSafeTint(0.5, resolveColor(theme, state ? state : 'white')),
        ...resolveInputOutline(theme, 'white'),
        bg: 'secondary',
        borderWidth: '2px',
        p: '2px'
      },
      '&.is-active + div': {
        ...resolveInputBoxShadow(theme, state ? state : 'primary', false, 4),
        color: resolveColor(theme, state ? state : 'white')
      },
      '&:focus + div': {
        ...resolveInputBoxShadow(theme, state ? state : 'primary', false, 4),
        color: resolveColor(theme, state ? state : 'white')
      },
      '&:hover + div': {
        color: resolveColor(theme, state ? state : 'neutral.medium'),
        bg: 'unset'
      },
      '&.is-hover + div': {
        color: resolveColor(theme, state ? state : 'neutral.medium'),
        bg: 'unset'
      },
      '&:checked:focus + div': {
        color: resolveColor(theme, state ? state : 'white')
      },
      '&[disabled]:not([checked]) + div': {
        opacity: '0.2',
        color: 'text',
        bg: 'unset'
      },
      '&[disabled]:checked + div': {
        opacity: '0.4'
      },
      '&:checked + div': {
        color: resolveColor(theme, state ? state : 'white')
      },
      '&:disabled ~ span': {
        opacity: 0.6
      }
    }
  },
  classicSuite: (
    theme: Theme,
    props: CheckboxStatefulThemeProps
  ): ThemeUICSSObject => {
    const { state, disabled, indeterminate, disableBg, disableColor } = props
    const _defaultBehaviors = defaultBehaviors({
      theme,
      state,
      disabled,
      indeterminate,
      disableBg,
      disableColor
    })
    return {
      '+ div:after': {
        display: 'none',
        content: "' '",
        width: '0.2em',
        height: '0.5em',
        alignSelf: 'center',
        transform: 'translateY(-0.1em) rotate(45deg) skew(10deg)',
        border: 'white.thick',
        borderTop: 0,
        borderLeft: 0
      },
      '&:focus + div': { border: 'primary' },
      '&.is-active + div': { border: 'primary' },
      '&:hover + div': { border: 'primary' },
      '&.is-hover + div': { border: 'primary' },
      '&:checked + div': {
        backgroundColor: resolveColor(theme, state ? state : 'primary'),
        '&:hover': { border: 'primary' },
        ':after': {
          display: 'flex'
        }
      },
      '&:checked:focus + div': { border: 'primary' },
      '&[disabled]:checked + div': _defaultBehaviors.disabledCheck,
      '&[disabled]:not([checked]) + div': _defaultBehaviors.disabledNotCheck,
      '&:disabled ~ span': {
        opacity: 0.6
      }
    }
  }
} as CheckboxVariants
