import { useEffect, useLayoutEffect } from 'react'

/**
 * `useLayoutEffect` that fallbacks to `useEffect` on server side
 * rendering.
 */
const useIsomorphicEffect =
  typeof window === 'undefined' ? useEffect : useLayoutEffect

export default useIsomorphicEffect
