/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

import ModalManager, { ariaHidden } from './modal-manager'
import TrapFocus from './trap-focus'
import type { ModalProps, ModalType } from './types'
import useCallbackRef from '../../../hooks/use-callback-ref'
import useForkRef from '../../../hooks/use-fork-ref'
import useTimeout from '../../../hooks/use-timeout'
import { isNumeric } from '../../../utils/assertion'
import { callAll } from '../../../utils/function'
import ownerDocument from '../../../utils/owner-document'
import { BoxPrivate } from '../../_primitives/box/box'
import Backdrop from '../backdrop/backdrop'
import { useContainerContext } from '../container-context'
import Portal from '../portal/portal'

function getContainer(container: any) {
  container = typeof container === 'function' ? container() : container
  // eslint-disable-next-line react/no-find-dom-node
  return ReactDOM.findDOMNode(container) as Element
}

function getHasTransition(props: React.PropsWithChildren<any>) {
  return props.children && props.children.hasOwnProperty('props')
    ? props.children.props.hasOwnProperty('in')
    : false
}

// A modal manager used to track and manage the state of open Modals.
// Modals don't open on the server so this won't conflict with concurrent requests.
const defaultManager = new ModalManager()

const defaultZIndices = 'modal'

/**
 * Modal is a lower-level construct that is leveraged by the following components:
 *
 * - [Dialog](/api/dialog/)
 * - [Drawer](/api/drawer/)
 * - [Menu](/api/menu/)
 * - [Popover](/api/popover/)
 *
 * If you are creating a modal dialog, you probably want to use the [Dialog](/api/dialog/) component
 * rather than directly using Modal.
 *
 * This component shares many concepts with [react-overlays](https://react-bootstrap.github.io/react-overlays/#modals).
 */
const Modal: FC<ModalProps> = React.forwardRef<HTMLDivElement, ModalProps>(
  function Modal(props, ref) {
    const {
      BackdropComponent = Backdrop,
      BackdropProps,
      useModalAsBackdrop,
      children,
      closeAfterTransition = false,
      container,
      disableAutoFocus = false,
      disableBackdropClick = false,
      disableEnforceFocus = false,
      disableEscapeKeyDown = false,
      disablePortal = false,
      shouldHoistTheme = true,
      disableRestoreFocus = false,
      disableScrollLock = false,
      scrollBarWidth,
      hideBackdrop = false,
      keepMounted = false,
      zIndex: zIndexProp = defaultZIndices,
      adornerComponent,
      onBackdropClick,
      onClose,
      onEscapeKeyDown,
      open,
      hostContainerElement: _hostContainerElement,
      ...rest
    } = props
    const manager = defaultManager
    const [exited, setExited] = useState(true)
    const [mounted, setMounted] = useState(false)
    const modal = useRef<ModalType>({})
    const mountNodeRef = useRef<HTMLElement>()
    const modalRef = useRef<HTMLDivElement>(null)
    const handleRef = useForkRef(modalRef, ref)
    const hasTransition = getHasTransition(props)
    const { containerRef } = useContainerContext() ?? {}
    const hostContainerElement = _hostContainerElement ?? containerRef?.current

    const getDoc = useCallback(
      () => ownerDocument(mountNodeRef.current as Element),
      []
    )
    const getModal = () => {
      modal.current.modalRef = modalRef.current
      modal.current.mountNode = mountNodeRef.current
      return modal.current
    }

    const handleMounted = () => {
      manager.mount(getModal(), { disableScrollLock, scrollBarWidth })

      if (modalRef.current) {
        // Fix a bug on Chrome where the scroll isn't initially 0.
        modalRef.current.scrollTop = 0
      }
      setMounted(true)
    }

    const handleOpen = useCallbackRef(() => {
      const resolvedContainer = getContainer(container) || getDoc().body

      manager.add(getModal(), resolvedContainer)

      // The element was already mounted.
      if (modalRef.current) {
        handleMounted()
      }
    })

    const isTopModal = useCallback(
      () => manager.isTopModal(getModal()),
      [manager]
    )

    const handlePortalRef = useCallbackRef((node: HTMLElement) => {
      mountNodeRef.current = node

      if (!node) {
        return
      }

      if (open && isTopModal()) {
        handleMounted()
      } else {
        ariaHidden(modalRef.current as Element, true)
      }
    })

    const handleClose = useCallback(() => {
      manager.remove(getModal())
      setMounted(false)
    }, [manager])

    useEffect(() => {
      return () => {
        handleClose()
      }
    }, [handleClose])

    useEffect(() => {
      if (open) {
        handleOpen()
      } else if (!hasTransition || !closeAfterTransition) {
        handleClose()
      }
    }, [open, handleClose, hasTransition, closeAfterTransition, handleOpen])

    const delayExecution = useTimeout(5)

    useEffect(() => {
      if (mounted && disableScrollLock && open) {
        const handleResizeOrScroll = () => {
          handleClose()

          if (onClose) {
            onClose({}, 'scrollOrResize')
          }
        }
        let win: Node | Document | undefined = undefined
        // delay subscription to allow ui scroll settle
        delayExecution(() => {
          if (hostContainerElement) {
            hostContainerElement.addEventListener(
              'scroll',
              handleResizeOrScroll
            )
            win = ownerDocument(modal.current.mountNode)
            win.addEventListener('resize', handleResizeOrScroll)
          } else {
            win = ownerDocument(modal.current.mountNode).body
            win.addEventListener('resize', handleResizeOrScroll)
            win.addEventListener('scroll', handleResizeOrScroll)
          }
        })
        return () => {
          if (hostContainerElement) {
            hostContainerElement.removeEventListener(
              'scroll',
              handleResizeOrScroll
            )
            if (win) {
              win.removeEventListener('resize', handleResizeOrScroll)
            }
          }
          if (win) {
            win.removeEventListener('resize', handleResizeOrScroll)
            win.removeEventListener('scroll', handleResizeOrScroll)
          }
        }
      }
      return
    }, [
      delayExecution,
      disableScrollLock,
      handleClose,
      mounted,
      onClose,
      open,
      hostContainerElement
    ])

    const handleEnter = useCallback(() => {
      setExited(false)
    }, [])

    const handleExited = useCallback(() => {
      setExited(true)

      if (closeAfterTransition) {
        handleClose()
      }
    }, [closeAfterTransition, handleClose])

    const handleBackdropClick = useCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (event.target !== event.currentTarget) {
          return
        }

        if (onBackdropClick) {
          onBackdropClick(event)
        }

        if (!disableBackdropClick && onClose) {
          onClose(event, 'backdropClick')
        }
      },
      [disableBackdropClick, onBackdropClick, onClose]
    )

    const handleKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        // The handler doesn't take event.defaultPrevented into account:
        //
        // event.preventDefault() is meant to stop default behaviors like
        // clicking a checkbox to check it, hitting a button to submit a form,
        // and hitting left arrow to move the cursor in a text input etc.
        // Only special HTML elements have these default behaviors.
        if (event.key !== 'Escape' || !isTopModal()) {
          return
        }

        // Swallow the event, in case someone is listening for the escape key on the body.
        event.stopPropagation()

        if (onEscapeKeyDown) {
          onEscapeKeyDown(event)
        }

        if (!disableEscapeKeyDown && onClose) {
          onClose(event, 'escapeKeyDown')
        }
      },
      [disableEscapeKeyDown, isTopModal, onClose, onEscapeKeyDown]
    )

    const {
      onEnter: childrenOnEnter,
      onExited: childrenOnExited,
      tabIndex: childrenTabIndex,
      ...restChildrenProps
    } = children?.props ?? {}

    const childProps = useMemo(() => {
      return {
        tabIndex: childrenTabIndex || '-1',
        // It's a Transition like component
        ...(hasTransition
          ? {
              onEnter: callAll(handleEnter, childrenOnEnter),
              onExited: callAll(handleExited, childrenOnExited)
            }
          : {})
      }
    }, [
      childrenOnEnter,
      childrenOnExited,
      childrenTabIndex,
      handleEnter,
      handleExited,
      hasTransition
    ])

    const zIndex =
      !isNumeric(zIndexProp) &&
      zIndexProp !== defaultZIndices &&
      manager.modals.length > 1
        ? `${defaultZIndices}.${zIndexProp}`
        : zIndexProp

    const modal__css = useMemo(
      () => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        right: 0,
        bottom: 0,
        top: 0,
        left: 0,
        zIndex,
        ...(disableEnforceFocus ? { pointerEvents: 'none' } : {}),
        ...(!open && exited ? { visibility: 'hidden' } : {}),
        ...(useModalAsBackdrop
          ? {
              bg: 'opaqueBlack.3',
              overflow: 'auto',
              variant: 'scrollbar.text-area',
              ...BackdropProps
            }
          : null)
      }),
      [
        BackdropProps,
        disableEnforceFocus,
        exited,
        open,
        useModalAsBackdrop,
        zIndex
      ]
    )

    if (!keepMounted && !open && (!hasTransition || exited)) {
      return null
    }
    // eslint-disable-next-line no-console
    // console.log(`------ modal render mounted? ${mounted}, open: ${open} ------`)
    return (
      <Portal
        ref={handlePortalRef}
        container={container}
        disablePortal={disablePortal}
        shouldHoistTheme={shouldHoistTheme && !disablePortal}
      >
        {/*
          Marking an element with the role presentation indicates to assistive technology
          that this element should be ignored; it exists to support the web application and
          is not meant for humans to interact with directly.
          https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/no-static-element-interactions.md
        */}
        <BoxPrivate
          data-csg-test="Modal"
          className="csg-tui-modal"
          ref={handleRef}
          __themeKey="modals"
          onKeyDown={handleKeyDown}
          role="presentation"
          __css={modal__css}
          onClick={useModalAsBackdrop ? handleBackdropClick : undefined}
          {...rest}
        >
          {typeof adornerComponent === 'function'
            ? adornerComponent()
            : adornerComponent}
          {hideBackdrop || useModalAsBackdrop ? null : (
            <BackdropComponent
              open={open}
              onClick={handleBackdropClick}
              {...BackdropProps}
            />
          )}
          <TrapFocus
            disableEnforceFocus={disableEnforceFocus}
            disableAutoFocus={disableAutoFocus}
            disableRestoreFocus={disableRestoreFocus}
            getDoc={getDoc}
            isEnabled={isTopModal}
            open={open}
          >
            {React.cloneElement(children, {
              ...childProps,
              ...restChildrenProps
            })}
          </TrapFocus>
        </BoxPrivate>
      </Portal>
    )
  }
)

export default Modal
