import type {
  ReactSelectVariants,
  ReactSelectVariantTheme,
  SelectControlProps,
  SelectControlThemeResolver,
  SelectIndicatorProps,
  SelectMenuProps,
  SelectMenuThemeResolver,
  SelectOptionProps,
  SelectOptionThemeResolver,
  SelectSingleValueProps,
  SelectSingleValueThemeResolver
} from './types'
import { resolveInputOutline } from '../../styles-system/input-element-utils'
import type { Theme, ThemeUICSSObject } from '../../theme-provider/types'
import { zIndices } from '../../themes/const'
import { transitions } from '../../themes/theme-keys/transitions'
import { getSafeReadableColor, getSafeTint } from '../../utils/colors'
import { resolveColor } from '../../utils/styled-props'

const baseControl: SelectControlThemeResolver = (
  theme: Theme,
  props: SelectControlProps
): ThemeUICSSObject => {
  const { state, isDisabled, isFocused, menuIsOpen } = props
  return {
    bg: 'white',
    ...resolveInputOutline(theme, state ? state : 'muted', true, false, 2),
    ...(isDisabled ? { backgroundColor: 'neutral.light' } : {}),
    ...(isDisabled || isFocused || menuIsOpen
      ? {}
      : {
          '&:hover': resolveInputOutline(
            theme,
            state ? state : 'primary',
            true,
            isDisabled,
            0.75
          )
        }),
    ...(isFocused || menuIsOpen
      ? {
          ...resolveInputOutline(
            theme,
            state ? state : 'primary',
            false,
            isDisabled,
            4
          )
        }
      : {}),
    ...(menuIsOpen
      ? {
          borderTopLeftRadius: 'default',
          borderTopRightRadius: 'default',
          borderBottom: 'unset',
          '&::before': {
            position: 'absolute',
            right: '1px',
            bottom: 0,
            left: '1px',
            bg: 'white',
            content: '""',
            height: '5px',
            zIndex: 104
          },
          '&::after': {
            position: 'absolute',
            right: 0,
            bottom: 0,
            left: 0,
            bg: 'neutral',
            content: '""',
            height: '1px',
            zIndex: 104
          },
          ['& .csg-tui-select-value-container']: {
            zIndex: 105
          }
        }
      : { borderRadius: 'default' })
  }
}

const baseSingleValue: SelectSingleValueThemeResolver = (
  _t: Theme,
  props: SelectSingleValueProps
): ThemeUICSSObject => ({
  color: props.isDisabled ? 'neutral.dark' : 'text',
  emMarginX: 'xs-2',
  maxWidth: 'calc(100% - 8px)',
  overflow: 'hidden',
  position: 'absolute',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
})

const baseDropdownIndicator = (
  _t: Theme,
  props: SelectIndicatorProps
): ThemeUICSSObject => ({
  color: props.isDisabled ? 'neutral.dark' : 'primary',
  display: 'flex',
  emPadding: 'xs-2',
  transition: transitions.create(['color', 'transform'], {
    duration: transitions.duration.shorter
  }),
  transform: `${props.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)'}`,
  '&:hover': {
    opacity: 0.6
  }
})

const baseIndicator = (
  _t: Theme,
  props: SelectIndicatorProps
): ThemeUICSSObject => ({
  color: props.isDisabled ? 'neutral.dark' : 'neutral.darker',
  display: 'flex',
  emPadding: 'xs-2'
})

const baseMenu: SelectMenuThemeResolver = (
  theme: Theme,
  props: SelectMenuProps
): ThemeUICSSObject => {
  const { state, placement } = props
  return {
    variant: 'text.body',
    width: '100%',
    mt: '-1px',
    [`${placement === 'top' ? 'bottom' : 'top'}`]: '100%',
    bg: 'white',
    zIndex: 102,
    borderBottomLeftRadius: 'default',
    borderBottomRightRadius: 'default',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      pointerEvents: 'none',
      ...resolveInputOutline(theme, state ? state : 'primary', false, false, 4),
      zIndex: 99
    }
  }
}

const baseMultiValue = {
  bg: 'neutrals.20',
  borderRadius: 'pill',
  transition: transitions.create(),
  '> button': {
    transition: transitions.create('width')
  },
  '.csg-tui-icon': {
    transition: transitions.create('opacity')
  },
  '&.is-disabled': {
    bg: 'transparent',
    p: '0.25em 0',
    '> label': {
      m: 0,
      lineHeight: '1.2em'
    },
    '> button': {
      width: 0
    },
    '.csg-tui-icon': {
      opacity: 0
    }
  }
}

const baseOption: SelectOptionThemeResolver = (
  theme: Theme,
  props: SelectOptionProps
): ThemeUICSSObject => ({
  color: 'text',
  paddingX: 'sm',
  emPaddingY: 'xs-2',
  WebkitTapHighlightColor: resolveColor(theme, 'secondary'),
  ...(!props.disabled
    ? {
        ...(props.selected
          ? {
              bg: 'primary',
              color: 'white',
              '&:active': {
                textShadow: 'textWhite'
              }
            }
          : {
              '&:hover': {
                color: 'primary',
                textShadow: 'textPrimary'
              }
            })
      }
    : {
        bg: 'neutral.lighter',
        color: 'neutral',
        '&:hover': {
          cursor: 'not-allowed'
        }
      })
})

const baseSelect: ReactSelectVariantTheme = {
  selectContainer: {
    variant: 'text.body',
    lineHeight: 1.5
  },
  valueContainer: {
    emPaddingY: 'xs-2',
    emPaddingX: 'xxs'
  },
  control: baseControl,
  singleValue: baseSingleValue,
  dropdownIndicator: baseDropdownIndicator,
  clearIndicator: (
    theme: Theme,
    props: SelectIndicatorProps
  ): ThemeUICSSObject => ({
    ...baseIndicator(theme, props),
    opacity: 0.6
  }),
  loadingIndicator: {
    emPaddingY: '0.05em',
    emPaddingX: 'xxs',
    color: 'primary'
  },
  menuList: {
    variant: 'scrollbar.transition'
  },
  menu: baseMenu,
  option: baseOption,
  multiValue: baseMultiValue,
  placeholder: {
    color: 'neutral.dark'
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @deprecated react-select is deprecated. please switch to AutoComplete
 */
export default {
  select: baseSelect,
  dataDrivenSample: {
    ...baseSelect,
    selectContainer: {
      ...baseSelect.selectContainer,
      width: '300px'
    },
    singleValue: (
      theme: object,
      props: SelectSingleValueProps
    ): ThemeUICSSObject => ({
      ...baseSingleValue(theme, props),
      color: props.isDisabled ? 'neutrals.12' : props.data?.color || 'text'
    }),
    menu: (theme: Theme, props: SelectMenuProps): ThemeUICSSObject => ({
      ...baseMenu(theme, props),
      width: '400px'
    }),
    option: (theme: Theme, props: SelectOptionProps): ThemeUICSSObject => ({
      ...baseOption(theme, props),
      ...(!props.disabled
        ? {
            bg: props.selected ? props.data?.color : 'transparent',
            color: props.selected
              ? getSafeReadableColor(props.data?.color)
              : props.data?.color,
            '&:hover': {
              bg: getSafeTint(0.7, props.data?.color),
              textShadow: `0 0 1px ${props.data?.color}`
            }
          }
        : {})
    })
  },
  demographicsRadiusSelector: {
    ...baseSelect,
    selectContainer: {
      ...baseSelect.selectContainer,
      width: '300px'
    }
  },
  search: {
    ...baseSelect,
    searchIndicator: baseIndicator
  },
  modalMode: {
    ...baseSelect,
    selectContainer: {
      ...baseSelect.selectContainer,
      zIndex: ((zIndices?.modal as any)?.['__default'] ?? 0) + 50
    },
    searchIndicator: baseIndicator
  },
  lenderAdmin: {
    ...baseSelect,
    selectContainer: {
      ...baseSelect.selectContainer,
      height: '32px'
    }
  },
  ghost: {
    ...baseSelect,
    control: undefined,
    singleValue: {
      ...baseSelect.singleValue,
      position: 'inherit',
      color: 'inherit'
    }
  },
  newsFilter: {
    ...baseSelect,
    selectContainer: undefined,
    valueContainer: {
      px: 'sm',
      py: '2px',
      minHeight: '28px'
    },
    indicators: {
      minHeight: '28px'
    },
    menu: (theme: Theme, props: SelectMenuProps): ThemeUICSSObject => {
      const { state, placement } = props
      return {
        fontFamily: 'costar',
        width: '100%',
        mt: '-1px',
        [`${placement === 'top' ? 'bottom' : 'top'}`]: '100%',
        bg: 'white',
        zIndex: 102,
        borderBottomLeftRadius: 'default',
        borderBottomRightRadius: 'default',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          pointerEvents: 'none',
          ...resolveInputOutline(
            theme,
            state ? state : 'primary',
            false,
            false,
            4
          ),
          zIndex: 99
        }
      }
    },
    option: (theme: object, props: SelectOptionProps): ThemeUICSSObject => ({
      color: 'text',
      bg: 'white',
      height: '32px',
      paddingX: 'sm',
      emPaddingY: 'xs',
      WebkitTapHighlightColor: resolveColor(theme, 'secondary'),
      ...(!props.disabled
        ? {
            ...(props.selected
              ? {
                  bg: 'neutral.light',
                  color: 'text',
                  '&:active': {
                    textShadow: 'textWhite'
                  }
                }
              : {
                  '&:hover': {
                    color: 'white',
                    bg: 'primary'
                  }
                })
          }
        : {
            bg: 'neutral.lighter',
            color: 'neutral',
            '&:hover': {
              cursor: 'not-allowed'
            }
          })
    }),
    singleValue: (
      _t: Theme,
      props: SelectSingleValueProps
    ): ThemeUICSSObject => ({
      color: props.isDisabled ? 'neutral.dark' : 'text',
      maxWidth: 'calc(100% - 8px)',
      overflow: 'hidden',
      position: 'absolute',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }),
    placeholder: {
      color: 'hsl(0, 0%, 50%)'
    }
  }
} as ReactSelectVariants
