/* eslint-disable @typescript-eslint/no-explicit-any */
import { zIndices } from '../const'
import { generateFullScaleArray } from '../scales'
import {
  anchors,
  badges,
  borderWidths,
  buttonsTheme as buttons,
  cards,
  colors,
  elevations,
  fonts,
  forms,
  getBorders,
  getGradients,
  getShadows,
  grids,
  headings,
  layout,
  lists,
  radii,
  scrollbar,
  sizes,
  styles,
  tables,
  tabs,
  tags,
  text,
  tooltips,
  transitions
} from '../theme-keys'
import createBreakpoints from '../theme-keys/breakpoints'

export default {
  contextId: 'suite',
  useCustomProperties: false,
  useColorSchemeMediaQuery: false,
  useRootStyles: false, // need to be explicit other wise it result in a redundant Global style tag
  useLocalStorage: false,
  breakpoints: createBreakpoints(),
  ...fonts,
  badges,
  borders: getBorders(colors),
  borderWidths,
  anchors,
  buttons,
  forms,
  cards,
  colors,
  elevations,
  emSpace: generateFullScaleArray(1, 'em', 2, 64),
  fontSizes: generateFullScaleArray(24, 'px'), // prefer rem unit but until all application remove root font override we have to stay with px unit.
  gradients: getGradients(colors),
  grids,
  headings,
  layout,
  lists,
  radii,
  scrollbar,
  shadows: getShadows(colors),
  sizes,
  space: generateFullScaleArray(16, 'px', 2, 64), // prefer rem unit but until all application remove root font override we have to stay with px unit.
  styles,
  tables,
  tabs,
  tags,
  text,
  tooltips,
  transitions,
  zIndices
}
