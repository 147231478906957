import type { OverridableStringUnion } from '../../utils/types'
import type { Size } from '../types'

export type BreakpointSize = Exclude<
  Size,
  'xs-2' | 'xs-3' | 'xl+2' | 'xl+3' | 'xl+4' | 'xl+5' | 'xl+6'
>

export type BreakpointDefaults = Record<BreakpointSize, true>
export type BreakpointOverrides = {}

export type ThemeBreakpoint = OverridableStringUnion<
  BreakpointDefaults,
  BreakpointOverrides
>
export type BreakpointValues<T = BreakpointDefaults> = {
  [key in keyof T]: number
}

export type Breakpoints<T = BreakpointDefaults> = {
  keys: (keyof T)[]
  values: BreakpointValues<T>
  up: (key: keyof T | number) => string
  down: (key: keyof T | number) => string
  between: (start: keyof T | number, end: keyof T | number) => string
  only: (key: keyof T) => string
  width: (key: keyof T) => number
  map: () => string[]
  getValueAndUnit: (key: keyof T) => string | undefined
  scaleToSize: (
    scale: Partial<Record<keyof T, number | string>>
  ) => (width?: number) => number | string
}

// Sorted ASC by size. That's important.
// It can't be configured as it's used statically for propTypes.
export const breakpointKeys = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']

export type BreakpointsOptions<T = BreakpointDefaults> = {
  values: BreakpointValues<T>
  unit?: string
  step?: number
}

// const muiBreakpoint: BreakpointValues = {
//   xs: 0,
//   sm: 600,
//   md: 960,
//   lg: 1280,
//   xl: 1920
// }

// const defaultBreakpoint: BreakpointValues<BreakpointDefaults> = {
//   xxs: 420, // majority mobile portraits (widest portrait iPhone 8)
//   xs: 600,
//   sm: 900, // majority tablet portraits
//   md: 1024, // secondary break between portrait/landscape of high resolution tablets
//   lg: 1366, // high resolution tablets landscape
//   xl: 1440, // point of reference: MacBook Air 13 inch, Microsoft Surface Pro 3 (portrait)
//   xxl: 1700
// }

const defaultBreakpoint: BreakpointValues<BreakpointDefaults> = {
  xxs: 420, // majority mobile portraits (widest portrait iPhone 8)
  xs: 600,
  sm: 900, // majority tablet portraits
  md: 1025, // secondary break between portrait/landscape of high resolution tablets - 1 px added to prevent collision with desktop first tailwind-css used in Lender
  lg: 1281, // 1 px added to prevent collision with desktop first tailwind-css used in Lender
  xl: 1441, // point of reference: MacBook Air 13 inch, Microsoft Surface Pro 3 (portrait) - 1 px added to prevent collision with desktop first tailwind-css used in Lender
  xxl: 1601 // 1 px added to prevent collision with desktop first tailwind-css used in Lender
}

// Keep in mind that @media is inclusive by the CSS specification.
const createBreakpoints = <T = BreakpointDefaults>(
  options?: BreakpointsOptions<T>
): Breakpoints<T> => {
  const {
    // The breakpoint **start** at this value.
    // For instance with the first breakpoint xs: [xs, sm).
    values = defaultBreakpoint,
    unit = 'px',
    step = 5,
    ...other
  } = options || {}

  const keys = Object.keys(values) as (keyof T)[]
  const width = (key: keyof T) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (values as any)[key]
  }

  const up = (key: keyof T | number) => {
    const value =
      typeof key === 'string' && typeof width(key) === 'number'
        ? width(key)
        : key
    return `@media (min-width:${value}${unit})`
  }

  const down = (key: keyof T | number) => {
    const endIndex = (
      typeof key === 'string' ? keys.indexOf(key) + 1 : key
    ) as number
    const upperBound =
      endIndex === 0
        ? width(keys[0])
        : typeof key === 'string' && endIndex < keys.length
          ? width(keys[endIndex])
          : endIndex

    if (typeof key === 'string' && endIndex > keys.length - 1) {
      // down from the biggest breakpoint applies to all sizes
      return up(0)
    }

    const value = (
      typeof upperBound === 'number' && endIndex > -1 ? upperBound : key
    ) as number
    const delta = step / 100
    return `@media (max-width:${value - delta}${unit})`
  }

  const between = (start: keyof T | number, end: keyof T | number) => {
    const endIndex = (
      typeof end === 'string' ? keys.indexOf(end) : end
    ) as number

    if (endIndex === keys.length - 1) {
      return up(start)
    }
    const delta = step / 100
    return (
      `@media (min-width:${
        typeof start === 'string' && typeof width(start) === 'number'
          ? width(start)
          : start
      }${unit}) and ` +
      `(max-width:${
        (endIndex !== -1 && typeof width(keys[endIndex + 1]) === 'number'
          ? width(keys[endIndex + 1])
          : end) - delta
      }${unit})`
    )
  }

  const only = (key: keyof T) => {
    return between(key, key)
  }

  const map = () => {
    return keys.map(i => up(i as keyof T))
  }

  const getValueAndUnit = (key: keyof T) => {
    return key && width(key) ? `${width(key)}${unit}` : undefined
  }

  const scaleToSize =
    (scale: Partial<Record<keyof T, number | string>>) =>
    (_width?: number): number | string => {
      const scaleKeys = Object.keys(scale) as (keyof T)[]
      if (scaleKeys.length === 0) {
        throw new Error('invalid scale')
      }
      if (_width === undefined || _width === null) {
        return scale[scaleKeys[Math.floor(scaleKeys.length / 2)]] as
          | string
          | number
      }
      let breakpoint: keyof T = scaleKeys[0]
      keys.some(i => {
        if (scaleKeys.includes(i)) {
          breakpoint = i
        }
        return width(i) >= _width
      })
      return scale[breakpoint] as string | number
    }

  return {
    keys,
    // @ts-ignore
    values,
    up,
    down,
    between,
    only,
    width,
    map,
    getValueAndUnit,
    scaleToSize,
    ...other
  }
}

export const defaultBreakpoints = createBreakpoints()

export default createBreakpoints
