/* eslint-disable @typescript-eslint/no-explicit-any */

import type { RadioStatefulThemeProps, RadioVariants } from './types'
import { resolveInputOutline } from '../../styles-system/input-element-utils'
import type {
  ColorStateKey,
  Theme,
  ThemeUICSSObject
} from '../../theme-provider/types'
import { transitions } from '../../themes/theme-keys/transitions'
import scaleToPx from '../../utils/scale-to-px'
import { resolveColor } from '../../utils/styled-props'

const hoverStyles = (
  theme: Theme,
  showHoverShadow?: boolean,
  state?: ColorStateKey,
  disabled?: boolean
) => ({
  ':not([disabled]) + span': {
    border: 'black',
    boxShadow: 'black.inset',
    ...(showHoverShadow
      ? {
          '::before': {
            opacity: 0.5
          }
        }
      : {})
  },
  ':checked + span': resolveInputOutline(
    theme,
    state ?? 'primary',
    true,
    disabled,
    1
  )
})

export default {
  default: (theme: Theme, props: RadioStatefulThemeProps): ThemeUICSSObject => {
    const {
      state,
      disabled,
      disableBg,
      disableColor,
      showHoverShadow: showHoverShadowProps,
      hoverShadowColor,
      radioBorder,
      radioInnerSize,
      radioInnerColor
    } = props
    const showHoverShadow = showHoverShadowProps || !!hoverShadowColor
    return {
      '& + span': {
        width: '1em',
        height: '1em',
        position: 'relative',
        transition: 'box-shadow 250ms ease 0s',
        ...(radioBorder
          ? { border: radioBorder }
          : resolveInputOutline(theme, state ?? 'neutrals.13')),
        borderRadius: '100%',
        ...(showHoverShadow
          ? {
              '::before': {
                content: '""',
                height: '2em',
                width: '2em',
                position: 'absolute',
                bg: hoverShadowColor ?? 'neutrals.18',
                borderRadius: '100%',
                opacity: 0,
                transition: transitions.create('opacity', {
                  duration: transitions.duration.short
                })
              }
            }
          : {}),
        '::after': {
          content: '""',
          height: scaleToPx(radioInnerSize ?? '0.6em'),
          width: scaleToPx(radioInnerSize ?? '0.6em'),
          position: 'absolute',
          bg: disabled ? 'neutrals.17' : radioInnerColor ?? 'primary',
          borderRadius: '100%',
          transform: 'scale(0)',
          transition: transitions.create('transform', {
            duration: transitions.duration.short
          })
        }
      },
      '&:checked + span': {
        ...(radioBorder
          ? { border: radioBorder }
          : resolveInputOutline(theme, state ?? 'primary')),

        '::after': {
          transform: 'scale(1)'
        }
      },
      '&:hover': hoverStyles(theme, showHoverShadow, state, disabled),
      '&.is-hover': hoverStyles(theme, showHoverShadow, state, disabled),
      '&.is-active + span': resolveInputOutline(
        theme,
        state ?? 'primary',
        false,
        disabled,
        4
      ),
      '&:focus + span': resolveInputOutline(
        theme,
        state ?? 'primary',
        false,
        disabled,
        4
      ),
      '&:disabled': {
        '+ span': {
          cursor: 'not-allowed'
        },
        '.is-read-only + span + span': {
          cursor: 'auto'
        },
        ':not(.is-read-only) + span + span': {
          cursor: 'not-allowed'
        },
        ':not(.is-read-only) ~ span': {
          opacity: 0.6
        },
        ':checked + span': {
          color: disableColor ?? 'neutral.dark',
          ...resolveInputOutline(theme, disableBg ?? 'neutral.light'),
          backgroundColor: resolveColor(theme, disableBg ?? 'neutral.light'),
          '::after': {
            bg: 'neutral'
          }
        },
        ':not([checked]) + span': resolveInputOutline(
          theme,
          disableBg ?? 'neutral.light'
        )
      }
    }
  }
} as RadioVariants
