/* eslint-disable @typescript-eslint/no-explicit-any */
import { Global, jsx } from '@emotion/react'
import packageInfo from '@emotion/react/package.json'
import { __ThemeUIInternalBaseThemeProvider } from '@theme-ui/core'
import type { FC } from 'react'
import React, { useMemo } from 'react'

import ColorModeProvider from './color-mode-provider'
import css from './css'
import CSSReset from './css-reset'
import { merge } from './merge-theme'
import type { Theme } from './types'
import useThemeUI from './use-theme-ui'
import { BoxPrivate } from '../components/_primitives/box/box'
import type { BoxProps } from '../components/_primitives/box/types'
import CostarSuiteTheme from '../themes/presets/costar-suite-theme'
import { EMPTY_OBJECT, isEmptyObject, isNotEmptyObject } from '../utils'
import { isBrowser } from '../utils/dom'
import { getIdGenerator, IdProvider } from '../utils/unique-id'

const __EMOTION_VERSION__ = packageInfo.version

const cacheThemes: Record<string, { theme: Theme }> = {}

function resolveTheme(outer: { theme: Theme }, theme: Theme) {
  const baseUnit = theme.baseUnit || outer.theme.baseUnit
  const direction = theme.direction || outer.theme.direction
  const cacheKey = theme.contextPath
    ? `${theme.contextPath}.${baseUnit}.${direction}`
    : undefined
  let retVal = cacheKey ? cacheThemes[cacheKey] : undefined
  if (!retVal) {
    retVal = merge.all({}, outer, { theme })
    if (cacheKey) {
      cacheThemes[cacheKey] = retVal
    }
  }
  return retVal
}
type ThemeProviderProps = {
  theme: Theme | ((outerTheme: Theme) => Theme)
  children?: React.ReactNode
}

function ThemeProvider({ theme, children }: ThemeProviderProps) {
  const outer = useThemeUI()

  if (process.env.NODE_ENV !== 'production') {
    if (outer.__EMOTION_VERSION__ !== __EMOTION_VERSION__) {
      // eslint-disable-next-line no-console
      console.warn(
        'Multiple versions of Emotion detected,',
        'and theming might not work as expected.',
        'Please ensure there is only one copy of @emotion/react installed in your application.'
      )
    }
  }

  const context =
    typeof theme === 'function' && outer.theme
      ? { ...outer, theme: theme(outer.theme) }
      : //@ts-ignore
        resolveTheme(outer, theme)

  // @ts-ignore
  return jsx(__ThemeUIInternalBaseThemeProvider, { context }, children)
}

const RootStyles = (): JSX.Element =>
  jsx(Global, {
    styles: emotionTheme => {
      const theme = emotionTheme as Theme
      const { useRootStyles } = theme.config || theme

      if (useRootStyles === false || (theme.styles && !theme.styles.root)) {
        return null
      }
      const boxSizing = theme.useBorderBox === false ? undefined : 'border-box'

      return css({
        '*': {
          boxSizing
        },
        html: {
          variant: 'styles.root'
        },
        body: {
          margin: 0
        }
      })(theme)
    }
  })

export type ThemeUIProviderProps = {
  /**
   * Theme specification JSON
   */
  theme: Theme
  /**
   * enable when style.root is necessary, common use if portal popup
   */
  hoistContext?: boolean
  /**
   * set global style reset, this will override any other reset for the entire sites
   */
  resetCSS?: string | boolean
  /**
   * disable ssr content will only be render on the client
   * @default false
   */
  disableSSR?: boolean
  /**
   *  When enable it will create div wrapper for a specific context
   */
  useDivRootContext?: boolean
  /**
   *  use <RootStyles> i.e. globle root style
   */
  includeGlobalRootStyle?: boolean
  /**
   *  provide any styling necessary when a context div wrapper is necessary
   */
  divRootProps?: BoxProps
  /**
   * enable color mode using `ColorModeProvider`
   */
  colorModeEnable?: boolean
  /**
   * enable id provider, for generating unique id for the component that can be use for keys and/or automated test ids
   */
  idProviderEnable?: boolean
}

const ThemeUIProvider: FC<
  React.PropsWithChildren<ThemeUIProviderProps>
> = props => {
  const {
    theme = CostarSuiteTheme as unknown as Theme,
    hoistContext,
    resetCSS,
    disableSSR = false,
    children,
    useDivRootContext: _useDivRootContext,
    includeGlobalRootStyle = false,
    divRootProps = EMPTY_OBJECT as BoxProps,
    colorModeEnable,
    idProviderEnable
  } = props
  const { theme: outerTheme = {} as Theme } = useThemeUI()

  const { sx, ...restDivRootProps } = divRootProps
  const useDivRootContext = _useDivRootContext || !isEmptyObject(divRootProps)

  const __css = useMemo(
    () => ({
      ...sx,
      ...(theme?.direction === 'rtl' ? { direction: 'rtl' } : {})
    }),
    [theme?.direction, sx]
  )

  // context has already been establish no need to initialized another context,
  // however if hoisting is require initialize the root style with a div wrapper.
  // first scenario handles the base theme context, second scenario handles existing alternate context
  if (
    isNotEmptyObject(theme) &&
    theme.contextId &&
    (outerTheme.contextId === theme.contextId ||
      (outerTheme.contextPath &&
        outerTheme.contextPath.includes(theme.contextId)))
  ) {
    return hoistContext ? (
      <BoxPrivate
        className={`csg-tui-context-root-${theme.contextPath?.replaceAll(
          '.',
          '-'
        )}`}
        variant="styles.root"
        __css={__css}
        {...restDivRootProps}
      >
        {children}
      </BoxPrivate>
    ) : (
      <>{children}</>
    )
  }

  if (isNotEmptyObject(theme)) {
    if (!theme.contextId) {
      theme.contextId = getIdGenerator('theme')()
    }
    theme.contextPath = outerTheme.contextPath
      ? `${outerTheme.contextPath}.${theme.contextId}`
      : theme.contextId
    theme.outerTheme = outerTheme
  }

  function wrapIdProvider(children: React.ReactNode) {
    return idProviderEnable ? (
      <IdProvider>{children}</IdProvider>
    ) : (
      <>{children}</>
    )
  }

  function wrapColorModeProvider(children: React.ReactNode) {
    return colorModeEnable ? (
      <ColorModeProvider>{children}</ColorModeProvider>
    ) : (
      <>{children}</>
    )
  }

  function wrapDivRootContext(children: React.ReactNode) {
    return useDivRootContext ? (
      <BoxPrivate
        className={`csg-tui-context-root-${theme.contextPath?.replaceAll(
          '.',
          '-'
        )}`}
        variant="styles.root"
        __css={__css}
        {...restDivRootProps}
      >
        {children}
      </BoxPrivate>
    ) : (
      <>{children}</>
    )
  }

  return isEmptyObject(theme) ? (
    <>{children}</>
  ) : disableSSR && !isBrowser ? null : (
    <ThemeProvider theme={theme}>
      {resetCSS === true ? CSSReset() : resetCSS && CSSReset(resetCSS)}
      {includeGlobalRootStyle ? <RootStyles /> : null}
      {wrapDivRootContext(wrapColorModeProvider(wrapIdProvider(children)))}
    </ThemeProvider>
  )
}

export default ThemeUIProvider
