/* eslint-disable @typescript-eslint/no-explicit-any */
import { THEME_UI_DEFAULT_KEY } from './types'

const toVarName = (key: string) =>
  `--csg-tui-${key.replace(`-${THEME_UI_DEFAULT_KEY}`, '')}`
const toVarValue = (key: string) => `var(${toVarName(key)})`

const join = (...args: (string | undefined)[]) => args.filter(Boolean).join('-')

const numberScales = {
  fontWeights: true,
  lineHeights: true
}
const reservedKeys = {
  useCustomProperties: true,
  initialColorModeName: true,
  printColorModeName: true,
  initialColorMode: true,
  useLocalStorage: true
}

const toPixel = (key: string, value: string | number) => {
  if (typeof value !== 'number') return value
  if (numberScales[key as keyof typeof numberScales]) return value
  return value + 'px'
}

// convert theme values to custom properties
export const toCustomProperties = (
  obj: Record<string, any> | undefined,
  parent?: string,
  themeKey?: string
): Record<string, any> => {
  const next: Record<string, any> = Array.isArray(obj) ? [] : {}

  for (const key in obj) {
    const value = obj[key]
    const name = join(parent, key)
    if (value && typeof value === 'object') {
      next[key] = toCustomProperties(value, name, key)
      continue
    }
    if (reservedKeys[key as keyof typeof reservedKeys]) {
      next[key] = value
      continue
    }
    const _val = toPixel(themeKey || key, value) // save this from original code might come in handy later
    next[key] = toVarValue(name)
  }

  return next
}

export const objectToVars = (
  parent: string,
  obj: Record<string, any>
): Record<string, object> => {
  let vars: Record<string, object> = {}
  for (const key in obj) {
    if (key === 'modes') continue
    const name = join(parent, key)
    const value = obj[key]
    if (value && typeof value === 'object') {
      vars = {
        ...vars,
        ...objectToVars(name, value)
      }
    } else {
      vars[toVarName(name)] = value
    }
  }
  return vars
}
