/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef } from 'react'

import useIsomorphicEffect from './use-isomorphic-effect'

/**
 * https://github.com/facebook/react/issues/14099#issuecomment-440013892
 *
 * React hook to persist any value between renders,
 * but keeps it up-to-date if it changes.
 *
 * @param value the value or function to persist
 */
export default function useCallbackRef<T extends (...args: any[]) => any>(
  fn: T | undefined
): T {
  const ref = useRef(fn)

  useIsomorphicEffect(() => {
    ref.current = fn
  }, [fn])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(((...args) => ref.current?.(...args)) as T, [])
}
