// port from @styled-system/background v.5.1.2
import { system } from './system-core'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const config: any = {}

config.background = true
config.backgroundImage = true
config.backgroundSize = true
config.backgroundPosition = true
config.backgroundRepeat = true

config.bgImage = config.backgroundImage
config.bgSize = config.backgroundSize
config.bgPosition = config.backgroundPosition
config.bgRepeat = config.backgroundRepeat

export const background = system(config)

export default background
