import isColor from 'is-color'

import { memoizedGet as get } from './object'
import { resolveSize } from './size-utils'
import { useSafeThemeUI } from '../theme-provider/use-theme-ui'

/* eslint-disable @typescript-eslint/no-explicit-any */
export type PropsFn = (props: { [key: string]: any }) => any

export function bool(
  cssProp: string,
  validComponentProps: string[],
  lastOneWins?: boolean
) {
  return function (props: object): {
    [x: string]: string
  } {
    const keys = Object.keys(props)
      .filter(k => (props as any)[k] && validComponentProps.indexOf(k) >= 0)
      .map(k => k.replace(/([A-Z])/g, '-$1').toLowerCase())
    if (keys.length) {
      return {
        [`${cssProp}`]: lastOneWins ? keys[keys.length - 1] : keys.join(' ')
      }
    }
    return {}
  }
}

export const resolveSpaceProps =
  (cssProp?: string, isNegativeSpace?: boolean, defaultSpace = 0) =>
  (props: any) => {
    const theme = useSafeThemeUI(props)
    const space = get(theme, 'space')
    const spaceSize = get(theme, 'sizes.space')
    const value = cssProp ? props[cssProp] : undefined
    const applyNegativeSpace = (v: string | number | (string | number)[]) =>
      isNegativeSpace ? `-${v}` : v
    return !space
      ? defaultSpace
      : Array.isArray(value)
        ? value.map(i =>
            applyNegativeSpace(
              space[resolveSize(i, spaceSize) as string | number]
            )
          )
        : applyNegativeSpace(
            space[
              value != null
                ? (resolveSize(value, spaceSize) as string | number)
                : defaultSpace
            ]
          )
  }

export const resolveSpacing =
  (props: any, key: string, isNegativeSpace?: boolean) => (theme: any) => {
    const _props: any = { theme }
    _props[key] = props[key]
    return resolveSpaceProps(key, isNegativeSpace)(_props)
  }

export const resolveColor = (theme?: any, color?: string) => {
  const _color = color
    ? isColor(color)
      ? color
      : get(theme, `colors.${color}`, color)
    : get(theme, 'colors.border')
  return typeof _color === 'string' && _color.startsWith('var')
    ? _color.slice(_color.indexOf('#')).replace(')', '')
    : _color
}
