import type { FC } from 'react'
import React, { useMemo } from 'react'

import type { BoxProps } from './types'
import { createUseStyledComponent } from '../../../hooks'
import type { PrivateThemeUiProps } from '../../../styles-system/create-component'
import { positions } from '../../../utils/props-utils'

const useStyledComponent = createUseStyledComponent(['div', 'span'], 'csg-box')

export const BoxPrivate = React.forwardRef<
  HTMLElement,
  BoxProps & PrivateThemeUiProps
>(function BoxPrivate(props, ref) {
  const { as: component = 'div', inline, __css, ...rest } = props
  const Component = useStyledComponent(component)

  const baseStyles = useMemo(
    () => ({
      ...(inline ? { display: 'inline-block' } : {}),
      ...positions(props),
      ...__css
    }),
    [__css, inline, props]
  )

  return <Component ref={ref} {...rest} __css={baseStyles} />
})

const Box: FC<BoxProps> = React.forwardRef<HTMLDivElement, BoxProps>(
  function Box(props, ref) {
    return <BoxPrivate ref={ref} {...props} />
  }
)

export default Box
