import type { ButtonProps, ButtonThemeResolver, ButtonVariants } from './types'
import type { Theme, ThemeUICSSObject } from '../../theme-provider/types'
import { THEME_UI_DEFAULT_KEY } from '../../theme-provider/types'
import { borderToShadows } from '../../themes/theme-keys/shadows'
import type { Transitions } from '../../themes/theme-keys/transitions'
import { transitions } from '../../themes/theme-keys/transitions'
import { EMPTY_OBJECT } from '../../utils/object'

export const standardButtonDisabled: ThemeUICSSObject = {
  pointerEvents: 'unset',
  outline: 'unset',
  boxShadow: 'unset',
  textShadow: 'unset',
  cursor: 'not-allowed',
  opacity: '0.4',
  filter: 'grayscale(100%)'
}

export const standardButtonHover: ThemeUICSSObject = {
  opacity: '0.6'
}

function standardActive(borderColor?: string): ThemeUICSSObject {
  return {
    boxShadow: 'unset',
    opacity: '1',
    ...(borderColor
      ? {
          border: borderColor
        }
      : {})
  }
}

function standardFocus(borderColor?: string): ThemeUICSSObject {
  return {
    zIndex: 2,
    ...(borderColor
      ? {
          border: borderColor,
          boxShadow: borderToShadows(borderColor)
        }
      : {})
  }
}

const standardStatic: ThemeUICSSObject = {
  cursor: 'default'
}

const standardLoading: ThemeUICSSObject = {
  '& #svg_spinner': {
    color: 'primary'
  }
}

const defaultControlStyles = {
  color: 'textSecondary',
  paddingY: 'md',
  paddingX: 'md',
  bg: 'neutral.light',
  borderTop: 'neutral.medium',
  borderRadius: undefined,

  '&:last-of-type:not(:focus)': {
    borderBottom: 'neutral.medium'
  }
}

const disabledControlStyles = {
  '&[disabled]': {
    opacity: '1',
    background: 'rgba(245, 245, 245, 0.4)',
    outline: 'unset',
    boxShadow: 'unset',
    textShadow: 'unset',
    cursor: 'not-allowed',
    filter: 'grayscale(100%)',

    '.csg-tui-icon': {
      opacity: '0.4'
    }
  }
}
const standardBehavior = (
  isStatic?: boolean,
  isLoading?: boolean,
  borderColor?: string
): ThemeUICSSObject =>
  !isStatic && !isLoading
    ? {
        cursor: 'pointer',
        '&:hover:not(:disabled):not(.is-toggled):not(.disable-hover)':
          standardButtonHover,
        '&:active:not(:disabled)': standardActive(borderColor),
        '&[disabled]': standardButtonDisabled,
        '&:focus:not(.disable-focus)': standardFocus(borderColor),
        '&.is-toggled': standardFocus(borderColor),
        '&.is-disabled': standardButtonDisabled,
        '&.is-hidden': { display: 'none' }
      }
    : {
        ...standardStatic,
        ...(isLoading ? standardLoading : {})
      }

const baseButtonStyle: ThemeUICSSObject = {
  borderRadius: 'default'
}

const getTransition = (
  _t: Transitions = transitions,
  props: string | string[] = ['all']
): ThemeUICSSObject => ({
  '&:not([type="checkbox"]):not([type="radio"])': {
    transition: _t.create(props)
  }
})

const greyHover = (_t: Theme, props: ButtonProps): ThemeUICSSObject => ({
  color: 'textSecondary',
  ...getTransition(_t.transitions, ['background', 'transform']),
  ...(!props.isStatic && !props.isLoading
    ? {
        '&:hover:not(:disabled):not(.disable-hover)': {
          bg: 'neutral.lighter',
          color: 'text'
        }
      }
    : {})
})

const baseHover = (_t: Theme, props: ButtonProps): ThemeUICSSObject => ({
  ...getTransition(_t.transitions, ['background', 'transform']),
  ...(!props.isStatic && !props.isLoading
    ? {
        '&:hover:not(:disabled):not(.is-toggled):not(.disable-hover)': {
          borderColor: 'black',
          opacity: '1' //overwriting - temp
        }
      }
    : {})
})

const primaryHover = (_t: Theme, props: ButtonProps): ThemeUICSSObject => ({
  color: 'text',
  ...getTransition(_t.transitions, 'background'),
  ...(!props.isStatic && !props.isLoading
    ? {
        '&:hover:not(:disabled):not(.disable-hover)': {
          bg: 'primary',
          color: 'white'
        }
      }
    : {})
})

const ghostBase = (_t: Theme, props: ButtonProps): ThemeUICSSObject =>
  ({
    ...baseButtonStyle,
    border: 'transparent',
    color: 'primary',
    bg: 'transparent',
    ...getTransition(_t.transitions, 'box-shadows'),
    ...(!props.isStatic && !props.isLoading
      ? {
          cursor: 'pointer',
          '&:hover:not(:disabled):not(.disable-hover)': {
            ...(props.hoverColor
              ? { color: props.hoverColor }
              : standardButtonHover)
          },
          '&:active:not(:disabled)': {
            textShadow: 'unset',
            opacity: '1'
          },
          '&:focus:not(.disable-focus)': {
            ...standardFocus(),
            textShadow: 'textPrimary'
          },
          '&[disabled]': standardButtonDisabled,
          '&.is-disabled': standardButtonDisabled
        }
      : {
          ...standardStatic,
          ...(props.isLoading ? standardLoading : {})
        }),
    '&.is-selected': {
      bg: 'neutral.lighter'
    }
  }) as unknown as ThemeUICSSObject

const defaultBase: ButtonThemeResolver = (
  _t: Theme,
  props: ButtonProps = EMPTY_OBJECT
): ThemeUICSSObject => ({
  ...baseButtonStyle,
  border: 'neutral.medium',
  color: 'text',
  bg: 'white-bg',
  ...getTransition(_t.transitions, 'box-shadows'),
  ...standardBehavior(props.isStatic, props.isLoading, 'primary')
})

const carouselButton =
  (isLeft: boolean) =>
  (_t: Theme, props: ButtonProps): ThemeUICSSObject => ({
    ...ghostBase(_t, props),
    color: 'white',
    ...getTransition(_t.transitions, ['background', 'transform']),
    '&.is-hidden': {
      transform: `translateX(${isLeft ? '-5.0' : '5.0'}rem)`
    },
    '&:hover': {
      bg: 'opaqueBlack.4',
      borderRadius: 'pill'
    }
  })

const iconButton = (_t: Theme): ThemeUICSSObject => ({
  '&:hover:not(:disabled):not(.disable-hover)': {
    ...standardButtonHover,
    cursor: 'pointer'
  },
  '&:focus:not(.disable-focus)': {
    zIndex: 2,
    color: 'primary'
  },
  '&[disabled]': {
    ...standardButtonDisabled
  }
})

export default {
  [THEME_UI_DEFAULT_KEY]: defaultBase,
  primary: (_t: Theme, props: ButtonProps): ThemeUICSSObject => ({
    ...baseButtonStyle,
    border: props.isLoading ? 'secondary' : 'primary',
    color: 'white',
    bg: props.isLoading ? 'secondary' : 'primary',
    ...getTransition(_t.transitions, 'box-shadows'),
    ...(!props.isStatic && !props.isLoading
      ? {
          cursor: 'pointer',
          '&:hover:not(:disabled):not(.disable-hover)': standardButtonHover,
          '&:active:not(:disabled)': standardActive('primary'),
          '&[disabled]': {
            ...standardButtonDisabled,
            bg: 'neutrals.15',
            border: 'lightGray'
          },
          '&:focus:not(.disable-focus)': standardFocus('primary'),
          '&.is-toggled': standardFocus('primary')
        }
      : {
          ...standardStatic,
          ...(props.isLoading
            ? {
                '& #svg_spinner': {
                  color: 'white'
                }
              }
            : {})
        }),
    '& .csg-tui-progress-spinner': {
      color: 'white'
    }
  }),
  secondary: (_t: Theme, props: ButtonProps): ThemeUICSSObject => ({
    ...baseButtonStyle,
    border: 'primary',
    color: 'primary',
    bg: 'white-bg',
    ...getTransition(_t.transitions, 'box-shadows'),
    ...standardBehavior(props.isStatic, props.isLoading, 'primary')
  }),
  tertiary: (_t: Theme, props: ButtonProps): ThemeUICSSObject =>
    ({
      ...baseButtonStyle,
      border: 'neutral.light',
      color: 'text',
      bg: 'white-bg',
      ...getTransition(_t.transitions, 'box-shadows'),
      ...(!props.isStatic && !props.isLoading
        ? {
            cursor: 'pointer',
            '&:hover:not(:disabled):not(.disable-hover)': {
              ...(props.hoverColor ? { color: props.hoverColor } : {}),
              border: props.disabled ? 'neutral.light' : 'blackImportant'
            },
            '&:active:not(:disabled)': {
              bg: 'neutral.light',
              textShadow: 'unset'
            },
            '&:focus:not(.disable-focus)': {
              ...standardFocus(),
              textShadow: 'textShadow'
            },
            '&[disabled]': standardButtonDisabled,
            '&.is-toggled': standardFocus('primary')
          }
        : {
            ...standardStatic,
            ...(props.isLoading ? standardLoading : {})
          })
    }) as unknown as ThemeUICSSObject,
  ghost: {
    [THEME_UI_DEFAULT_KEY]: ghostBase,
    basic: (_t: Theme, props: ButtonProps): ThemeUICSSObject => ({
      ...ghostBase(_t, props),
      color: 'text',
      ...(!props.isStatic && !props.isLoading
        ? {
            '&:focus:not(.disable-focus)': {
              ...standardFocus()
            }
          }
        : {
            ...standardStatic,
            ...(props.isLoading ? standardLoading : {})
          })
    })
  },
  ghostMod: (_t: Theme, props: ButtonProps): ThemeUICSSObject => ({
    ...ghostBase(_t, props),
    ...greyHover(_t, props),
    ...(!props.isStatic && !props.isLoading
      ? {
          '&:focus:not(.disable-focus)': {
            ...standardFocus(),
            textShadow: 'textShadow'
          }
        }
      : {
          ...standardStatic,
          ...(props.isLoading ? standardLoading : {})
        })
  }),
  ghostPrimary: (_t: Theme, props: ButtonProps): ThemeUICSSObject => ({
    ...ghostBase(_t, props),
    ...primaryHover(_t, props),
    ...(!props.isStatic && !props.isLoading
      ? {
          '&:active:not(:disabled)': {
            color: 'primaries.medium',
            textShadow: '0 0 3px #fff',
            opacity: '1'
          },
          '&:focus:not(.disable-focus)': {
            ...standardFocus(),
            textShadow: 'textShadow'
          },
          '&.is-toggled': {
            bg: 'primary',
            color: 'white',
            ...standardFocus('primary')
          }
        }
      : {
          ...standardStatic,
          ...(props.isLoading ? standardLoading : {})
        })
  }),
  ghostDanger: (_t: Theme, props: ButtonProps): ThemeUICSSObject => ({
    ...ghostBase(_t, props),
    color: 'danger',
    ...(!props.isStatic && !props.isLoading
      ? {
          '&:active:not(:disabled)': {
            color: 'danger',
            textShadow: '0 0 3px #fff',
            opacity: '1'
          },
          '&:focus:not(.disable-focus)': {
            ...standardFocus(),
            textShadow: 'textDanger'
          }
        }
      : {
          ...standardStatic,
          ...(props.isLoading ? standardLoading : {})
        })
  }),
  ghostWhite: (_t: Theme, props: ButtonProps): ThemeUICSSObject => ({
    ...ghostBase(_t, props),
    color: 'white',
    ...(!props.isStatic && !props.isLoading
      ? {
          '&:active:not(:disabled)': {
            color: 'white',
            textShadow: '0 0 3px #fff',
            opacity: '1'
          },
          '&:focus:not(.disable-focus)': {
            ...standardFocus(),
            textShadow: 'unset'
          }
        }
      : {
          ...standardStatic,
          ...(props.isLoading ? standardLoading : {})
        })
  }),
  listButton: (_t: Theme, props: ButtonProps): ThemeUICSSObject => ({
    ...defaultBase(_t, props),
    ...greyHover(_t, props)
  }),
  'carousel-left': carouselButton(true),
  'carousel-right': carouselButton(false),
  icon: {
    [THEME_UI_DEFAULT_KEY]: iconButton,
    clear: (_t: Theme): ThemeUICSSObject => {
      return {
        color: 'neutral.dark',
        ...iconButton(_t)
      }
    },
    'no-borders': (_t: Theme): ThemeUICSSObject => {
      return {
        ...iconButton(_t),
        border: 'none',
        '&:active:not(:disabled)': {
          border: 'none'
        }
      }
    }
  },
  transferList: (_t: Theme, props: ButtonProps): ThemeUICSSObject => ({
    ...ghostBase(_t, props),
    ...defaultControlStyles,
    ...disabledControlStyles,
    '&:active:not(:disabled)': {
      border: 'neutral.medium'
    },
    '&:focus:not(.disable-focus)': {
      boxShadow: '-0.1px 0.1px 4px #0559b3',
      borderColor: 'primary',
      zIndex: 2
    }
  }),
  'theme-ui-select': (_t: Theme, props: ButtonProps): ThemeUICSSObject => ({
    ...defaultBase(_t, props),
    ...baseHover(_t, props)
  }),
  'button-link': {
    color: 'primary',
    fontWeight: 'body',
    height: 'auto',
    '&:hover:not(.is-disabled):not(.is-toggled):not(.disable-hover), &:active:not(.is-disabled):not(.is-toggled):not(.disable-hover)':
      {
        textDecoration: 'underline',
        opacity: 1,
        cursor: 'pointer'
      },
    '&:visited': {
      color: 'primary'
    },
    '&:focus-visible': {
      outline: 'primary',
      outlineOffset: '0',
      borderRadius: 'default'
    },
    '&:active': {
      outline: 'none'
    },
    '&.is-disabled': {
      color: 'neutral.medium',
      cursor: 'default'
    }
  }
} as ButtonVariants
