import fastMemoize from 'fast-memoize'
import type { CSSProperties } from 'react'

import { FontScales } from './scales'
import type { Size } from './types'
import type { TooltipProps } from '../components/tooltip/types'
import type {
  ObjectWithDefault,
  Theme,
  ThemeUICSSObject
} from '../theme-provider/types'
import { THEME_UI_DEFAULT_KEY } from '../theme-provider/types'
import { memoizedGet as get } from '../utils/object'
import { isSizeIndex, resolveSize } from '../utils/size-utils'

// use for fall back object and facilitate typing
export const EMPTY_THEME = {} as Theme

// recommended approach to hide input but allow it to be read by screen reader.
// reference https://www.sarasoueidan.com/blog/inclusively-hiding-and-styling-checkboxes-and-radio-buttons/
export const visuallyHiddenStyleForText: React.CSSProperties = {
  clip: 'rect(0px, 0px, 0px, 0px)',
  clipPath: 'inset(100%)',
  border: '0px',
  height: '1px',
  width: '1px',
  margin: '-1px',
  padding: '0px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  /* remove element from flow */
  position: 'absolute'
}

// recommended approach to hide input but allow it to be read by screen reader.
// reference https://www.sarasoueidan.com/blog/inclusively-hiding-and-styling-checkboxes-and-radio-buttons/
export const visuallyHiddenStyleForCheckboxOrRadio: React.CSSProperties = {
  border: '0px',
  height: '1px',
  width: '1px',
  margin: '-1px',
  padding: '0px',
  /* remove element from flow */
  position: 'absolute',
  /* hide it visually */
  opacity: 0.00001
}

export const boldTextFixLength: ThemeUICSSObject = {
  '& span::before': {
    width: 'unset'
  },
  '& span::after': {
    display: 'block',
    width: 'unset',
    height: '0px',
    content: 'attr(content)',
    fontWeight: 'bold',
    color: 'transparent',
    visibility: 'hidden'
  }
}

export const standardDisabled: ThemeUICSSObject = {
  pointerEvents: 'unset',
  cursor: 'not-allowed',
  outline: 'unset',
  boxShadow: 'unset !important',
  textShadow: 'unset !important',
  opacity: 'unset'
}

export const ellipsis: ThemeUICSSObject = {
  display: 'block',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
}

export const unsetLineHeight = {
  '&.unset-line-height': {
    lineHeight: 'unset'
  }
}

export const rotateSvgCenterPoint = fastMemoize((degValue: number) => ({
  transformBox: 'fill-box',
  transformOrigin: 'center',
  transform: `rotate(${degValue}deg)`
}))

export const centerPosition: Pick<
  CSSProperties,
  'position' | 'top' | 'left' | 'transform'
> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
}

export const fillContainer: Pick<
  CSSProperties,
  'position' | 'top' | 'left' | 'bottom' | 'right'
> = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
}

export const animatedRotation = (
  initialOrientation = 0,
  rotate = 180,
  enableTransition?: boolean
): Pick<CSSProperties, 'transition' | 'transform'> & {
  '&.is-toggle': Pick<CSSProperties, 'transform'>
} => ({
  ...(enableTransition ? { transition: 'transform .2s ease-in-out .05s' } : {}),
  transform: `rotate(${initialOrientation}deg)`,
  '&.is-toggle': {
    transform: `rotate(${initialOrientation + rotate}deg)`
  }
})

export const smallResponsiveValueSizes: number[] = [8, 9, 10, 11, 12]
export const standardResponsiveValueSizes: number[] = [10, 11, 12, 13, 14]
export const mediumResponsiveValueSizes: number[] = [12, 13, 14, 15, 16]
export const largeResponsiveValueSizes: number[] = [14, 15, 16, 17, 18]
export const xLargeResponsiveValueSizes: number[] = [16, 17, 18, 19, 20]
export const xxLargeResponsiveValueSizes: number[] = [18, 19, 21, 20, 22]

export const xSmallResponsiveSizes: (FontScales | Size | null)[] = [
  'xs-3',
  null,
  'xs-2',
  null,
  'xxs'
]

export const smallResponsiveSizes: (FontScales | Size | null)[] = [
  'xs-2',
  null,
  'xxs',
  null,
  'xs'
]

export const standardResponsiveSizes: (FontScales | Size | null)[] = [
  'xxs',
  null,
  'xs',
  null,
  'sm'
]

export const mediumResponsiveSizes: (FontScales | Size | null)[] = [
  'xs',
  null,
  'sm',
  null,
  'md'
]

export const largeResponsiveSizes: (FontScales | Size | null)[] = [
  'sm',
  null,
  'md',
  null,
  'lg'
]

export const xLargeResponsiveSizes: (FontScales | Size | null)[] = [
  'md',
  null,
  'lg',
  null,
  'xl'
]

export const xxLargeResponsiveSizes: (FontScales | Size | null)[] = [
  'lg',
  null,
  'xl',
  null,
  'xxl'
]

export const xSmallResponsiveSpace: (FontScales | Size | null)[] = [
  'xs-3',
  'xs-2',
  null,
  'xxs'
]

export const smallResponsiveSpace: (FontScales | Size | null)[] = [
  'xs-2',
  'xxs',
  null,
  'xs'
]

export const standardResponsiveSpace: (FontScales | Size | null)[] = [
  'xxs',
  null,
  'xs',
  'sm'
]

export const mediumResponsiveSpace: (FontScales | Size | null)[] = [
  'xs',
  'sm',
  null,
  'md'
]

export const largeResponsiveSpace: (FontScales | Size | null)[] = [
  'sm',
  'md',
  null,
  'lg'
]

export const xLargeResponsiveSpace: (FontScales | Size | null)[] = [
  'md',
  'lg',
  null,
  'xl'
]

export const ellipsisExpandResponsiveFontSizes: FontScales[] = [
  FontScales['lp1-15'],
  FontScales['lp1-14'],
  FontScales['lp1-13'],
  FontScales['lp1-12'],
  FontScales['lp1-11'],
  FontScales['lp1-10']
]

export const h1ResponsiveFontSizes: FontScales[] = [
  FontScales['lp2+2'], //  28px 1.75rem
  FontScales['lp2+3'], // 30px 1.875rem
  FontScales['lp2+4'], // 32px 2rem
  FontScales['lp2+5'], // 34px 2.125rem
  FontScales['lp2+6'], // 36px 2.25rem
  FontScales['lp2+7'] //  38px 2.375rem
]
export const h2ResponsiveFontSizes: FontScales[] = [
  FontScales['lp2+1'], // 26px 1.625rem
  FontScales['lp2+2'], // 28px 1.75rem
  FontScales['lp2+3'], // 30px 1.875rem
  FontScales['lp2+4'], // 32px 2rem
  FontScales['lp2+5'], // 34px 2.125rem
  FontScales['lp2+6'] //  36px 2.25rem
]
export const h3ResponsiveFontSizes: FontScales[] = [
  FontScales['base'], //  24px 1.5rem
  FontScales['lp2+1'], // 26px 1.625rem
  FontScales['lp2+2'], // 28px 1.75rem
  FontScales['lp2+3'], // 30px 1.875rem
  FontScales['lp2+4'], // 32px 2rem
  FontScales['lp2+5'] //  34px 2.125rem
]
export const h4ResponsiveFontSizes: FontScales[] = [
  FontScales['lp2-1'], // 22px 1.375rem
  FontScales['base'], //  24px 1.5rem
  FontScales['lp2+1'], // 26px 1.625rem
  FontScales['lp2+2'], // 28px 1.75rem
  FontScales['lp2+3'], // 30px 1.875rem
  FontScales['lp2+4'] //  32px 2rem
]

export const h5ResponsiveFontSizes: FontScales[] = [
  FontScales['lp2-2'], // 20px 1.25rem
  FontScales['lp2-1'], // 22px 1.375rem
  FontScales['base'], //  24px 1.5rem
  FontScales['lp2+1'], // 26px 1.625rem
  FontScales['lp2+2'], // 28px 1.75rem
  FontScales['lp2+3'] //  30px 1.875rem
]

export const h6ResponsiveFontSizes: FontScales[] = [
  FontScales['lp2-3'], // 18px 1.125rem
  FontScales['lp2-2'], // 20px 1.25rem
  FontScales['lp2-1'], // 22px 1.375rem
  FontScales['base'], //  24px 1.5rem
  FontScales['lp2+1'], // 26px 1.625rem
  FontScales['lp2+2'] //  28px 1.75rem
]

export const zIndices: Record<
  string,
  ThemeUICSSObject['zIndex'] | ObjectWithDefault<ThemeUICSSObject['zIndex']>
> = {
  mobileStepper: 1000,
  speedDial: 1050,
  appBar: 1100,
  drawer: 1200,
  toolbar: 1201,
  selectPopover: 1202,
  selectButton: 1203,
  selectOverlapPopover: 1204,
  checkbox: {
    [THEME_UI_DEFAULT_KEY]: 1205,
    plus_one: 1206
  },
  sticky: 1299,
  snackbar: 1400,
  tooltip: 10004, //1500
  masthead: 4999, // from costar suite
  // To work in conjunction with modal manager we want establish a new base zIndex for each
  // modal being create. TBD it may be necessary to make this more dynamic.
  // see src\components\_utils\modal\modal.tsx line # 213
  modal: {
    [THEME_UI_DEFAULT_KEY]: 10000, //5000 // force modal above masthead
    selectPopover: 10003, //5003,
    selectOverlapPopover: 10005, //5005,
    checkbox: {
      [THEME_UI_DEFAULT_KEY]: 10020,
      plus_one: 10021
    },
    sticky: 10099,
    mobileStepper: 11000
  }
}

export type ZIndexThemeKeys = keyof typeof zIndices

export const responsiveImageHeight = [100, 110, 120, 130, 140]
export const responsiveImageWidth = responsiveImageHeight.map(i => i * 1.4375)
export const calculatedOffsets = (
  offsets: (number | Size) | (number | Size)[],
  theme: Theme = {},
  percentValue = 100
): string | string[] => {
  const { space = [] } = theme
  if (theme) {
    if (Array.isArray(offsets)) {
      if (offsets.some(i => isSizeIndex(i))) {
        const spaceSizes = resolveSize(
          offsets,
          get(theme, 'sizes.space')
        ) as number[]
        return spaceSizes.map(i => `calc(${percentValue}% - ${get(space, i)})`)
      }
    } else {
      if (isSizeIndex(offsets)) {
        const spaceSizes = resolveSize(
          offsets,
          get(theme, 'sizes.space')
        ) as number
        return `calc(${percentValue}% - ${get(space, spaceSizes)})`
      }
    }
  }
  return Array.isArray(offsets)
    ? offsets.map(i => `calc(${percentValue}% - ${i}px)`)
    : `calc(${percentValue}% - ${offsets}px)`
}

export const titleTooltipProps: Omit<
  TooltipProps,
  'children' | 'open' | 'title'
> = {
  variant: 'title',
  interactive: true,
  disableFocusListener: true,
  placement: 'bottom-start',
  PopperProps: {
    modifiers: {
      offset: {
        enabled: true,
        offset: '50,-10'
      }
    }
  }
}
