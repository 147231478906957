import { isNumeric } from './assertion'
import toPx from './to-px'

const scaleToPx = (v: string | number): string => {
  if (isNumeric(v) && parseFloat(`${v}`) < 1) {
    const retVal = Math.ceil(toPx(v))
    return `${retVal % 2 === 0 ? retVal : retVal + 1}px`
  }
  return `${v}`
}

export default scaleToPx
