/* eslint-disable @typescript-eslint/no-explicit-any */

import type { Breakpoints } from '../themes/theme-keys/breakpoints'
import { defaultBreakpoints } from '../themes/theme-keys/breakpoints'
import type { LhCrop } from '../themes/theme-keys/fonts'
import { isNotEmptyObject, isNumeric } from '../utils/assertion'
import { toNumber } from '../utils/number'
import { memoizedGet as get } from '../utils/object'

const getLhCrop = (value: LhCrop): any => ({
  '&:before': {
    content: '""',
    display: 'block',
    height: 0,
    width: 0,
    marginTop: `-${Array.isArray(value) ? value[0] : value}em`
  },
  '&:after': {
    content: '""',
    display: 'block',
    height: 0,
    width: 0,
    marginTop: `-${Array.isArray(value) ? value[1] : value}em`
  }
})

function parseNumericArray(value: string): LhCrop | undefined {
  if (typeof value === 'string' && value.includes(',')) {
    const values = value.split(',')
    return values.length === 2 && isNumeric(values[0]) && isNumeric(values[1])
      ? [toNumber(values[0]), toNumber(values[1])]
      : undefined
  }
  return isNumeric(value) ? toNumber(value) : undefined
}

export default function (
  value: string,
  scale: any,
  _props: any
): string | object {
  const lhCorp = parseNumericArray(value) || get(scale, value)
  if (Array.isArray(lhCorp) && Array.isArray(lhCorp[0])) {
    const breakpoints: Breakpoints = get(
      _props,
      'theme.breakpoints',
      defaultBreakpoints
    )

    const mediaQueries = ['', ...breakpoints.map()]
    const test = mediaQueries.reduce((acc, cur, idx) => {
      return {
        ...acc,
        ...(idx === 0
          ? {
              ...(isNotEmptyObject(lhCorp[idx]) ? getLhCrop(lhCorp[idx]) : {})
            }
          : isNotEmptyObject(lhCorp[idx])
            ? {
                [cur]: getLhCrop(lhCorp[idx])
              }
            : {})
      }
    }, {})
    return test
  }
  return lhCorp ? getLhCrop(lhCorp) : ''
}
