import {
  adjustHue,
  readableColor,
  setHue,
  shade,
  tint,
  transparentize
} from 'polished'

import { memoizedGet } from './object'
import type { Theme } from '../theme-provider/types'

export function getSafeShade(
  percentage: string | number,
  color: string
): string {
  try {
    return shade(percentage, color)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(`----- unable to resolve shade of color ${color} ----`)
    return color
  }
}

export function getSafeTint(
  percentage: string | number,
  color: string
): string {
  try {
    return tint(percentage, color)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(`----- unable to resolve tint of color ${color} ----`)
    return color
  }
}

export function getSafeTransparentize(
  amount: string | number,
  color: string
): string {
  try {
    return transparentize(amount, color)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(`----- unable to resolve transparentize of color ${color} ----`)
    return color
  }
}

// get readable color based on the background color
export function getSafeReadableColor(
  color: string,
  returnIfLightColor?: string,
  returnIfDarkColor?: string,
  strict?: boolean
): string {
  try {
    return readableColor(color, returnIfLightColor, returnIfDarkColor, strict)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(`----- unable to resolve readable color of color ${color} ----`)
    return color
  }
}

export function getSafeColor(
  color: string,
  theme?: Theme,
  fallBackColor = '#fff'
): string {
  if (theme && 'colors' in theme) {
    return memoizedGet(theme, `colors.${color}`, fallBackColor)
  }
  return fallBackColor
}

export function setSafeAdjustHue(hue: number | string, color: string): string {
  try {
    return adjustHue(hue, color)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(`----- unable set hue color ${color} ----`)
    return color
  }
}

export function setSafeHue(hue: number | string, color: string): string {
  try {
    return setHue(hue, color)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(`----- unable set hue color ${color} ----`)
    return color
  }
}
