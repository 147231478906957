import { standardButtonDisabled } from '../../components/button/variants'
import type { ThemeUICSSObject } from '../../theme-provider/types'
import { boldTextFixLength } from '../const'

const baseTab: ThemeUICSSObject = {
  cursor: 'pointer',
  color: 'white',
  emPaddingY: 'xs-3',
  ...boldTextFixLength,
  '&:hover:not(:disabled):not(.is-selected)': {
    opacity: 0.4
  },
  '&[disabled]': standardButtonDisabled,
  '&.is-selected': {
    fontWeight: 'bold',
    cursor: 'default'
  }
}

export const tabs: ThemeUICSSObject = {
  tabs: {
    bg: 'secondary',
    px: ['xxs', 'xs', 'sm', 'md', 'lg'],
    lineHeight: 2
  },
  tab: {
    ...baseTab
  },
  indicator: {
    bg: 'accent',
    height: 'tabs.indicator'
  },
  scrollButton: {
    color: 'white',
    width: '2.5em'
  },
  panel: {
    px: ['xxs', 'xs', 'sm', 'md', 'lg'],
    emPaddingY: 'md'
  },
  popover: {
    variant: 'scrollbar.transition',
    boxShadow: 'primary.rise',
    elevation: 400,
    fontSize: ['xxs', 'xs', 'sm', 'md']
  },
  moreTab: {
    cursor: 'pointer',
    ...boldTextFixLength,
    color: 'primary',
    bg: 'white',
    textAlign: 'left',
    emPadding: 'md',
    width: '100%',
    '&:hover:not(:disabled):not(.is-selected)': {
      color: 'white',
      bg: 'primary'
    },
    '&.is-selected': {
      color: 'white',
      bg: 'primary',
      fontWeight: 'bold',
      cursor: 'default'
    }
  },
  vertical: {
    tabs: {
      bg: 'secondary',
      emPaddingY: 'md'
    },
    tab: baseTab,
    indicator: {
      bg: 'accent',
      width: 'tabs.indicator'
    },
    scrollButton: {
      color: 'white',
      opacity: 0.4,
      bg: 'neutral'
    }
  },
  suiteDetail: {
    tabs: {
      borderBottom: 'lightGrayThin',
      px: 0,
      lineHeight: 1.6
    },
    tab: {
      cursor: 'pointer',
      color: 'text',
      emPaddingY: 'xs-2',
      ...boldTextFixLength,
      '&:hover:not(:disabled):not(.is-selected)': {
        color: 'primary'
      },
      '&[disabled]': standardButtonDisabled,
      '&.is-selected': {
        color: 'primary',
        fontWeight: 'bold',
        cursor: 'default'
      }
    },
    indicator: {
      bg: 'primary',
      height: 'tabs.indicator'
    },
    scrollButton: {
      variant: 'tabs.scrollButton',
      color: 'primary'
    },
    popover: {
      variant: 'scrollbar.transition',
      boxShadow: 'primary.rise',
      elevation: 400,
      fontSize: ['xxs', 'xs', 'sm', 'md']
    },
    moreTab: {
      cursor: 'pointer',
      color: 'text',
      width: '100%',
      textAlign: 'left',
      ...boldTextFixLength,
      emPaddingY: 'xxs',
      emPaddingX: 'sm',
      '&:hover:not(:disabled):not(.is-selected)': {
        color: 'primary'
      },
      '&.is-selected': {
        color: 'primary',
        fontWeight: 'bold',
        cursor: 'default'
      }
    }
  },
  suiteDetailSubTabs: {
    tabs: {
      borderBottom: 'lightGrayThin',
      lineHeight: 1.6,
      width: 'min-content'
    },
    tab: {
      variant: 'tabs.suiteDetail.tab',
      px: 0
    },
    indicator: {
      variant: 'tabs.suiteDetail.indicator'
    },
    panel: {
      emPaddingY: 'md',
      bg: 'white'
    }
  },
  suiteSearchAlley: {
    tabs: {
      px: 0,
      lineHeight: 1.6
    },
    tab: {
      cursor: 'pointer',
      color: 'text',
      emPaddingY: 'xs-2',
      ...boldTextFixLength,
      '&:hover:not(:disabled):not(.is-selected)': {
        color: 'primary'
      },
      '&[disabled]': standardButtonDisabled,
      '&.is-selected': {
        color: 'primary',
        fontWeight: 'bold',
        cursor: 'default'
      }
    },
    indicator: {
      border: 'neutral',
      borderWidth: '0 1px 1px 0'
    },
    scrollButton: {
      variant: 'tabs.scrollButton',
      color: 'primary'
    },
    popover: {
      variant: 'scrollbar.transition',
      boxShadow: 'primary.rise',
      elevation: 400,
      fontSize: ['xxs', 'xs', 'sm', 'md']
    },
    moreTab: {
      cursor: 'pointer',
      color: 'text',
      width: '100%',
      textAlign: 'left',
      ...boldTextFixLength,
      emPaddingY: 'xxs',
      emPaddingX: 'sm',
      '&:hover:not(:disabled):not(.is-selected)': {
        color: 'primary'
      },
      '&.is-selected': {
        color: 'primary',
        fontWeight: 'bold',
        cursor: 'default'
      }
    }
  },
  layersAlley: {
    tabs: {
      borderBottom: 'lightGrayThin',
      bg: 'neutral.lighter',
      lineHeight: 1.6,
      width: 'min-content'
    },
    tab: {
      variant: 'tabs.suiteDetail.tab',
      px: 0
    },
    indicator: {
      variant: 'tabs.suiteDetail.indicator'
    },
    panel: {
      emPaddingY: 'md'
    },
    popover: {
      variant: 'scrollbar.transition',
      boxShadow: 'primary.rise',
      elevation: 400
    },
    moreTab: {
      cursor: 'pointer',
      bg: 'neutral.lighter',
      color: 'text',
      width: '100%',
      textAlign: 'left',
      ...boldTextFixLength,
      emPaddingY: 'xxs',
      emPaddingX: 'sm',
      '&:hover:not(:disabled):not(.is-selected)': {
        color: 'primary',
        bg: 'neutral.light'
      },
      '&.is-selected': {
        color: 'primary',
        fontWeight: 'bold',
        cursor: 'default'
      }
    }
  },
  'select-tab': {
    tabs: {
      bg: 'neutral.lighter',
      lineHeight: 2,
      '& .csg-tui-tab-list': {
        justifyContent: 'space-around',
        button: {
          justifyContent: 'center',
          flexGrow: 1
        }
      }
    },
    tab: {
      ...baseTab,
      color: 'text'
    },
    indicator: {
      bg: 'primary',
      height: 'tabs.indicator'
    }
  },
  'users-tab': {
    tabs: {
      lineHeight: '48px'
    },
    tab: {
      ...baseTab,
      bg: '#EAEAEA',
      color: 'text',
      textAlign: 'center',
      justifyContent: 'center',
      '&.is-selected': {
        fontWeight: 'bold',
        cursor: 'default',
        bg: 'white'
      },
      border: '1px solid #EAEAEA',
      borderBottom: '0',
      width: '8em'
    },
    indicator: {
      bg: '#EC4A08',
      height: 'tabs.indicator',
      width: '8em'
    }
  },
  moreButtonList: {
    tabs: {
      px: 0,
      lineHeight: 1.6,
      gap: 'xs'
    },
    popover: {
      elevation: 'none',
      bg: 'white',
      borderRadius: 'none',
      border: 'primary',
      boxShadow: 'primary',
      variant: 'scrollbar.transition'
    }
  }
}

export type TabsThemeVariants = keyof typeof tabs
