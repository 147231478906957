import { useMemo } from 'react'

import setRef from '../utils/set-ref'

export default function useForkRef<T>(
  refA: React.Ref<T>,
  refB?: React.Ref<T>
): (value: T | null) => void {
  /**
   * This will create a new function if the ref props change and are defined.
   * This means react will call the old forkRef with `null` and the new forkRef
   * with the ref. Cleanup naturally emerges from this behavior
   */
  return useMemo(() => {
    return refValue => {
      refA && setRef(refA, refValue)
      refB && setRef(refB, refValue)
    }
  }, [refA, refB])
}
