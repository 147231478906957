import type { CardVariants } from '../../components/card/types'
import { THEME_UI_DEFAULT_KEY } from '../../theme-provider/types'
import type { ThemeUICSSObject } from '../../theme-provider/types'
import {
  h6ResponsiveFontSizes,
  largeResponsiveSizes,
  largeResponsiveSpace,
  mediumResponsiveSizes,
  mediumResponsiveSpace,
  smallResponsiveSizes,
  smallResponsiveSpace,
  standardResponsiveSizes,
  xSmallResponsiveSizes
} from '../const'

const baseChartGridHeader: ThemeUICSSObject = {
  variant: 'cards.header',
  p: ['sm', 'md', 'lg'],
  '& .csg-tui-icon': {
    cursor: 'pointer'
  }
}

export const cosmosBaseCard: {
  card: ThemeUICSSObject
  header: ThemeUICSSObject
  title: ThemeUICSSObject
  footer: ThemeUICSSObject
} = {
  card: {
    width: '100%'
  },
  header: {
    position: undefined,
    flexDirection: undefined,
    flexWrap: undefined,
    justifyContent: undefined,
    width: undefined,
    emPaddingBottom: undefined,
    display: 'grid',
    alignItems: 'center',
    gap: 'xs',
    gridTemplateColumns: 'minmax(20px, auto) 1fr',
    borderBottom: 'neutral.light'
  },
  title: {
    variant: undefined,
    fontSize: 'lg',
    fontWeight: 'light',
    lhCrop: '0.2, 0.2',
    color: 'black'
  },
  footer: {
    position: undefined,
    flexDirection: undefined,
    emPaddingTop: undefined,
    width: undefined,
    borderTop: 'neutral.light'
  }
}

export const cards: CardVariants = {
  title: {
    variant: 'headings.h3',
    lhCrop: 'heading.h3',
    fontWeight: 'bold'
  },
  content: {
    variant: 'text.body',
    fontSize: standardResponsiveSizes
  },
  footer: {
    position: 'relative',
    flexDirection: 'row',
    emPaddingTop: 'xs',
    width: '100%'
  },
  header: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
    emPaddingBottom: 'xs'
  },
  [THEME_UI_DEFAULT_KEY]: {
    card: {
      variant: 'cards.card'
    },
    title: {
      variant: 'cards.title'
    },
    content: {
      variant: 'cards.content'
    },
    footer: {
      variant: 'cards.footer'
    },
    header: {
      variant: 'cards.header'
    }
  },
  colorPallets: {
    card: {
      padding: 'xs',
      bg: 'white-bg'
    },
    header: {
      variant: 'cards.header'
    },
    title: {
      fontSize: 'md',
      fontWeight: 'bold',
      lhCrop: 'body'
    }
  },
  borderLess: {
    card: { border: 'transparent', padding: 'xs' },
    header: {
      variant: 'cards.header',
      fontSize: largeResponsiveSizes,
      '& .csg-tui-card-header-action-container': {
        borderBottom: 'primary'
      }
    },
    title: { fontWeight: 'bold', lhCrop: 'body', color: 'primary' },
    content: { variant: 'cards.content' },
    footer: { pt: 'xxs' }
  },
  suiteDetailFodal: {
    card: {
      bg: 'white-bg',
      borderRadius: 0,
      boxShadow: 'rgb(0 0 0 / 50%) 0px 2px 8px 0px',
      border: `1px solid rgb(217, 217, 217)`,
      '& > .csg-tui-card-content': {
        flexGrow: 1,
        overflow: 'hidden'
      }
    },
    header: {
      py: 0,
      height: '40px',
      px: '16px',
      bg: 'rgb(246, 246, 246)',
      color: '#515151',
      fontSize: '18px',
      fontWeight: 'bold',
      borderBottom: `1px solid rgb(217, 217, 217)`,
      justifyContent: 'center',
      alignItems: 'center',
      '& .csg-tui-card-header-action-container': {
        alignSelf: 'center'
      }
    },
    title: { lhCrop: 'body' }
  },
  peerModifyCompsModal: {
    card: {
      bg: 'white-bg',
      borderRadius: 'lg',
      position: 'absolute',
      top: '50%',
      width: '33vw',
      minWidth: '400px',
      maxWidth: '550px',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      boxShadow: 'secondary.rise',
      elevation: 200
    },
    content: {
      p: smallResponsiveSpace,
      display: 'flex',
      flexDirection: 'column'
    },
    header: {
      borderTopLeftRadius: 'lg',
      borderTopRightRadius: 'lg',
      color: 'primary',
      bg: 'neutrals.20',
      fontWeight: 'bold',
      px: smallResponsiveSpace,
      emPaddingY: 'xs',
      justifyContent: 'center'
    },
    footer: {
      justifyContent: 'flex-end',
      emPaddingY: 'sm',
      px: smallResponsiveSpace
    }
  },
  peerPropertyDetail: {
    card: {
      bg: 'white-bg',
      border: 'transparent',
      padding: 'xxs'
    },
    title: {
      fontSize: smallResponsiveSizes,
      fontWeight: 'bold',
      lhCrop: 'body'
    },
    content: {
      variant: 'text.body',
      fontSize: xSmallResponsiveSizes
    }
  },
  infoCard: {
    card: { emMarginTop: 'xxs', bg: 'white-bg' },
    header: {
      color: 'primary',
      fontSize: xSmallResponsiveSizes,
      '& .csg-tui-card-header-action-container': {
        borderBottom: 'primaryLight'
      }
    },
    title: { lhCrop: 'body', fontWeight: 'body' },
    content: {
      variant: 'text.body',
      fontSize: xSmallResponsiveSizes
    },
    footer: {
      pt: 0,
      fontSize: xSmallResponsiveSizes,
      justifyContent: 'flex-end'
    }
  },
  propertyInfoTooltip: {
    card: { bg: 'white-bg', border: 'transparent', borderRadius: 0 },
    content: {
      variant: 'text.body'
    }
  },
  demographicsTable: {
    card: {
      bg: 'white-bg',
      border: '1px solid #d1d1d1'
    },
    header: {
      fontWeight: 'light',
      fontSize: largeResponsiveSizes,
      borderBottom: '1px solid #d1d1d1',
      alignItems: 'center',
      p: mediumResponsiveSpace
    },
    title: { lhCrop: 'body' },
    content: {
      p: mediumResponsiveSpace
    }
  },
  demographicsTableNoPadding: {
    card: {
      variant: 'cards.demographicsTable.card'
    },
    header: {
      variant: 'cards.demographicsTable.header'
    },
    title: { variant: 'cards.demographicsTable.title' },
    content: {
      p: 0,
      '& .csg-rc-table-scroll-horizontal table': {
        tableLayout: 'auto'
      }
    }
  },
  demographicsChart: {
    card: {
      variant: 'cards.demographicsTable.card'
    },
    header: {
      variant: 'cards.demographicsTable.header'
    },
    title: { variant: 'cards.demographicsTable.title' },
    content: {
      '&.apply-scroll-bar-style': {
        variant: 'scrollbar.transition',
        overflowY: 'auto'
      },
      p: largeResponsiveSpace,
      '& .cstp-legend-group': {
        transform: 'translateY(10px)'
      }
    }
  },
  employeeTable: {
    card: {
      bg: 'white-bg',
      border: '1px solid #d1d1d1'
    },
    header: {
      fontWeight: 'light',
      fontSize: largeResponsiveSizes,
      borderBottom: '1px solid #d1d1d1',
      p: mediumResponsiveSpace,
      alignItems: 'center',
      '& .csg-tui-card-header-action-container': {
        p: 'md',
        alignSelf: 'center'
      }
    },
    title: { lhCrop: 'body' },
    content: {
      p: mediumResponsiveSpace
    }
  },
  lenderChart: {
    card: {
      variant: 'cards.demographicsChart.card'
    },
    header: {
      variant: 'cards.demographicsChart.header'
    },
    title: { variant: 'cards.demographicsChart.title' },
    content: { variant: 'cards.demographicsChart.content' }
  },
  chartGrid: {
    card: { bg: 'white-bg', border: 'transparent', p: 0 },
    header: {
      ...baseChartGridHeader,
      borderBottom: 'lightGrayThin'
    },
    title: {
      variant: 'text.body',
      lhCrop: 'body',
      fontSize: h6ResponsiveFontSizes.slice(0, 3)
    },
    content: { variant: 'cards.content', p: mediumResponsiveSpace }
  },
  chartGridPlaceHolder: {
    card: { variant: 'cards.chartGrid.card' },
    header: {
      ...baseChartGridHeader,
      '& .show-border': {
        borderBottom: 'lightGrayThin'
      }
    },
    title: {
      variant: 'cards.chartGrid.title'
    },
    content: { variant: 'cards.chartGrid.content' }
  },
  cpdFactSheet: {
    card: {
      variant: 'cards.demographicsTable.card'
    },
    header: {
      variant: 'cards.demographicsTable.header',
      fontSize: mediumResponsiveSizes
    },
    title: { variant: 'cards.demographicsTable.title' },
    content: {
      variant: 'cards.demographicsTable.content',
      fontSize: standardResponsiveSizes
    }
  },
  transferList: {
    card: {
      bg: 'white-bg',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      height: '100%'
    },
    header: {
      fontWeight: 'light',
      fontSize: largeResponsiveSizes,
      p: mediumResponsiveSpace,
      alignItems: 'center'
    },
    title: { lhCrop: 'body' },
    content: {
      position: 'relative',
      flex: '1 1 auto'
    },
    footer: {
      justifyContent: 'flex-end',
      emPaddingY: 'sm',
      px: smallResponsiveSpace
    }
  },
  transferListResetDialog: {
    card: {
      variant: 'cards.transferList.card'
    },
    footer: {
      variant: 'cards.transferList.footer'
    }
  },
  layersAlley: {
    card: {
      bg: 'white-bg',
      border: '1px solid #d1d1d1',
      margin: '12px 21px',
      '&.is-active': {
        color: 'white',
        bg: 'secondary'
      }
    },
    header: {
      fontWeight: 'heading',
      alignItems: 'center',
      boxShadow: '0 1px 1px 0 rgb(0 0 0 / 15%)',
      p: 'xxs',
      '& .csg-tui-icon': {
        width: '22px'
      },
      '&.is-active': {
        color: 'white',
        bg: 'secondary',
        '.csg-tui-icon': {
          color: 'white',
          bg: 'secondary'
        }
      }
    },
    title: {
      '&.is-active': {
        color: 'white',
        bg: 'secondary'
      }
    }
  },
  layersAlleyContainer: {
    card: {
      bg: 'neutral.lighter'
    },
    header: {
      borderBottom: 'neutral.medium',
      p: 'sm'
    },
    title: {
      fontSize: 'md',
      fontWeight: 'heading'
    },
    footer: {
      borderTop: 'neutral.medium'
    }
  },
  'confirmation-dialogue': {
    card: {
      bg: 'white-bg',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%'
    },
    header: {
      fontSize: largeResponsiveSizes,
      alignItems: 'center',
      p: largeResponsiveSpace,
      borderBottom: 'neutral.light'
    },
    title: { lhCrop: 'body' },
    content: {
      justifyContent: 'center',
      p: largeResponsiveSpace,
      position: 'relative',
      flex: '1 1 auto'
    },
    footer: {
      borderTop: 'neutral.light',
      justifyContent: 'flex-end',
      p: largeResponsiveSpace
    }
  },
  'geo-filter-confirmation': {
    card: {
      variant: 'cards.confirmation-dialogue.card'
    },
    header: {
      variant: 'cards.confirmation-dialogue.header',
      borderBottom: 'unset'
    },
    title: {
      variant: 'cards.confirmation-dialogue.title',
      fontSize: 'xl',
      color: 'primary'
    },
    content: {
      variant: 'cards.confirmation-dialogue.content',
      textAlign: 'unset'
    },
    footer: {
      variant: 'cards.confirmation-dialogue.footer',
      borderTop: 'unset'
    }
  },
  'card-modal': {
    card: {
      ...cosmosBaseCard.card
    },
    header: {
      ...cosmosBaseCard.header,
      p: 'xl',
      border: '0'
    },
    title: {
      ...cosmosBaseCard.title
    },
    content: {
      px: 'xl'
    },
    footer: {
      ...cosmosBaseCard.footer,
      borderTop: '0',
      p: 'xl'
    }
  },
  'card-modal-custom': {
    card: {
      ...cosmosBaseCard.card
    },
    header: {
      ...cosmosBaseCard.header,
      p: 'xl'
    },
    title: {
      ...cosmosBaseCard.title
    },
    content: {
      px: 'xl'
    },
    footer: {
      ...cosmosBaseCard.footer,
      borderTop: '0',
      p: 'xl'
    }
  },
  'card-tooltip': {
    card: {
      ...cosmosBaseCard.card
    },
    header: {
      ...cosmosBaseCard.header,
      mx: 'lg',
      my: '0',
      pb: 'xs'
    },
    title: {
      ...cosmosBaseCard.title,
      fontWeight: 'bold',
      fontSize: 'lg'
    },
    content: {
      pl: 'lg',
      overflowY: 'scroll',
      variant: 'scrollbar.transition',
      maxHeight: '335px'
    }
  }
}

export type CardsThemeVariants = keyof typeof cards
