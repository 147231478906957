import type { ListVariants } from '../../components/list/types'
import type { ThemeUICSSObject } from '../../theme-provider/types'
import { boldTextFixLength } from '../const'

const selectedStyle: ThemeUICSSObject = {
  bg: 'primary',
  color: 'white'
}

const hoverStyle: ThemeUICSSObject = {
  bg: 'primary.light',
  color: 'black'
}

export const lists: ListVariants = {
  list: {
    variant: 'scrollbar.transition',
    wordBreak: 'break-all'
  },
  listItem: {
    cursor: 'pointer',
    color: 'text',
    wordBreak: 'normal',
    gap: 'xs',
    '&.is-selected': {
      ...selectedStyle
    },
    '&.is-disabled': {
      cursor: 'not-allowed',
      opacity: 0.4
    },
    '&.is-active, &:hover:not(.is-selected, .is-disabled)': {
      ...hoverStyle
    }
  },
  contactList: {
    list: { variant: 'lists.list' },
    listItem: {
      variant: 'lists.listItem',
      ...boldTextFixLength,
      '&:hover:not(.is-disabled) .csg-tui-list-item-text-primary': {
        bg: 'neutral.light',
        color: 'primary',
        cursor: 'pointer'
      },
      '& .csg-tui-list-item-text-primary': {
        color: 'text'
      }
    }
  },
  buttonDropdownList: {
    list: { variant: 'lists.list' },
    listItem: {
      ...boldTextFixLength,
      '.csg-tui-button, .csg-tui-button:active, .csg-tui-button:disabled': {
        border: 'unset'
      },
      '&:hover:not(.is-selected) > .csg-tui-button:disabled, .csg-tui-button:disabled':
        {
          bg: 'unset'
        },
      '.csg-tui-button:disabled': {
        color: 'neutral.medium'
      },
      '&:hover:not(.is-selected, .is-disabled) .csg-tui-button': {
        color: 'text',
        cursor: 'pointer',
        bg: 'primary.light'
      },
      '&:hover:not(.is-selected) > .csg-tui-button:disabled': {
        color: 'neutral.medium',
        cursor: 'not-allowed'
      },
      '.csg-tui-button, .csg-tui-button .csg-tui-text ': {
        flex: '1'
      },
      '.csg-tui-button': {
        textAlign: 'left',
        justifyContent: 'left',
        color: 'text',
        border: 'unset'
      },
      '&:hover:not(.is-selected) > .csg-tui-button:disabled, .csg-tui-button:hover:not(:disabled):not(.is-toggled):not(.disable-hover)':
        {
          opacity: 1
        },
      '.csg-tui-button:focus:not([type="checkbox"]):not([type="radio"])': {
        boxShadow: 'unset'
      }
    }
  },
  transferList: {
    list: {
      variant: 'scrollbar.text-area',
      fontSize: 'sm',
      wordBreak: 'break-all'
    },
    listItem: {
      cursor: 'pointer',
      color: 'text',
      '&.is-selected': {
        ...selectedStyle
      },
      '&.is-disabled': {
        cursor: 'default',
        opacity: 0.4
      },
      '&.is-active, &:hover:not(.is-selected, .is-disabled)': {
        ...hoverStyle
      }
    },
    listSubheader: {
      textTransform: 'uppercase',
      bg: 'neutral.light'
    }
  },
  'select-list': {
    list: {
      variant: 'lists.list'
    },
    listItem: {
      variant: 'lists.listItem'
    }
  },
  'select-list-show-checkbox': {
    list: {
      variant: 'lists.list'
    },
    listItem: {
      variant: 'lists.listItem',
      my: 'xs',
      '&.is-selected': {
        // clear hover and active, let check box be the indicator
      },
      '&.is-active, &:hover:not(.is-selected, .is-disabled)': {
        // clear hover and active, let check box be the indicator
      }
    }
  },
  listImageCaption: {
    list: {
      variant: 'lists.list'
    },
    listItem: {
      color: 'text',
      wordBreak: 'normal',
      gap: 'xs',
      '&:not(:last-child)': {
        borderBottom: 'mediumGray'
      },
      '&.is-disabled': {
        cursor: 'not-allowed',
        opacity: 0.4
      }
    }
  },
  collapsiblePanel: {
    list: {
      display: 'flex',
      flexDirection: 'column',
      gap: 'xs',
      p: '0'
    },
    listItem: {
      gap: 'xs',
      p: '0',
      height: '2rem',
      borderBottom: 'neutral.medium',
      '&:hover': {
        cursor: 'pointer'
      },
      '> .csg-tui-list-item-text': {
        color: 'black',
        fontWeight: 'bold'
      },
      '.csg-tui-icon-svg': {
        color: 'neutral.dark'
      }
    }
  },
  'tooltip-list': {
    list: {
      py: '0'
    },
    listItem: {
      py: 'xs',
      px: '0',
      '&:not(:last-of-type)': {
        borderBottom: 'neutral.light'
      },
      '> .csg-tui-list-item-text': {
        my: '0',
        '.csg-tui-text': {
          whiteSpace: 'normal'
        }
      },
      '.csg-tui-icon-svg': {
        color: 'neutral.dark'
      }
    }
  },
  kpi: {
    // TO DO: Review if needed
    list: {
      display: 'flex'
    },
    listItem: {
      flexDirection: 'column',
      borderRight: 'neutralsGray',
      justifyContent: 'flex-start',
      // '&:not(:last-of-type)': {
      // },
      '&.is-selected': {
        // clear hover and active, let check box be the indicator
      },
      '&.is-active, &:hover:not(.is-selected, .is-disabled)': {
        // clear hover and active, let check box be the indicator
      }
    }
  },
  bullet: {
    list: {
      display: 'list-item',
      flexDirection: 'column',
      pt: '0',
      pl: 'lg'
    },
    listItem: {
      display: 'list-item',
      listStyle: 'disc outside',
      userSelect: 'auto',
      p: '0',
      '&.is-selected': {
        // clear hover and active, let check box be the indicator
      },
      '&.is-active, &:hover:not(.is-selected, .is-disabled)': {
        // clear hover and active, let check box be the indicator'
      }
    }
  }
}

export type ListsThemeVariants = keyof typeof lists
