import type { TextAreaProps, TextAreaVariants } from './types'
import { resolveInputOutline } from '../../../styles-system/input-element-utils'
import type { Theme, ThemeUICSSObject } from '../../../theme-provider/types'

export default {
  default: (theme: Theme, props: TextAreaProps): ThemeUICSSObject => {
    const { state, disabled, resize, height, disableBorderEffect } = props
    return {
      color: 'text',
      resize: resize,
      whiteSpace: 'normal',
      height: height,
      variant: 'scrollbar.text-area',
      ...(disableBorderEffect
        ? {}
        : resolveInputOutline(theme, state ? state : 'muted', true, false, 2)),
      '&:focus:not(:read-only)': {
        zIndex: '2',
        ...(disableBorderEffect
          ? {}
          : resolveInputOutline(
              theme,
              state ? state : 'primary',
              false,
              disabled,
              4
            ))
      },
      '&:hover:not([disabled]):not(:focus):not(:read-only)': {
        zIndex: '2',
        ...(disableBorderEffect
          ? {}
          : resolveInputOutline(
              theme,
              state ? state : 'muted',
              true,
              disabled,
              0.75
            ))
      },
      '&[disabled]': {
        cursor: 'not-allowed',
        backgroundColor: 'neutrals.20',
        color: 'neutrals.13'
      },
      '&::placeholder': {
        opacity: '0.6'
      },
      '&:read-only': {
        cursor: 'text',
        backgroundColor: 'neutrals.20'
      }
    }
  }
} as TextAreaVariants
