/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import useForkRef from '../../../hooks/use-fork-ref'
import ownerDocument from '../../../utils/owner-document'
import Box from '../../_primitives/box/box'

type TrapFocusProps = {
  /**
   * A single child content element.
   */
  children: React.ReactElement
  /**
   * If `true`, the modal will not automatically shift focus to itself when it opens, and
   * replace it to the last focused element when it closes.
   * This also works correctly with any modal children that have the `disableAutoFocus` prop.
   *
   * Generally this should never be set to `true` as it makes the modal less
   * accessible to assistive technologies, like screen readers.
   */
  disableAutoFocus?: boolean
  /**
   * If `true`, the modal will not prevent focus from leaving the modal while open.
   *
   * Generally this should never be set to `true` as it makes the modal less
   * accessible to assistive technologies, like screen readers.
   */
  disableEnforceFocus?: boolean
  /**
   * If `true`, the modal will not restore focus to previously focused element once
   * modal is hidden.
   */
  disableRestoreFocus?: boolean
  /**
   * Return the document to consider.
   * We use it to implement the restore focus between different browser documents.
   */
  getDoc: () => Document
  /**
   * Do we still want to enforce the focus?
   * This prop helps nesting TrapFocus elements.
   */
  isEnabled: () => boolean
  /**
   * If `true`, the modal is open.
   */
  open?: boolean
}

const TrapFocus: FC<TrapFocusProps> = props => {
  const {
    children,
    disableAutoFocus = false,
    disableEnforceFocus = false,
    disableRestoreFocus = false,
    getDoc,
    isEnabled,
    open
  } = props
  const ignoreNextEnforceFocus = useRef<boolean>()
  const sentinelStart = useRef<HTMLDivElement>(null)
  const sentinelEnd = useRef<HTMLDivElement>(null)
  const nodeToRestore = useRef<HTMLElement | null>()

  const rootRef = useRef<HTMLElement>()
  const handleOwnRef = useCallback((instance?: HTMLElement) => {
    rootRef.current = instance
  }, [])
  // @ts-ignore
  const handleRef = useForkRef(children.ref, handleOwnRef)

  // ⚠️ You may rely on useMemo as a performance optimization, not as a semantic guarantee.
  // https://reactjs.org/docs/../../hooks-reference.html#usememo
  useMemo(() => {
    if (!open || typeof window === 'undefined') {
      return
    }

    nodeToRestore.current = getDoc().activeElement as HTMLElement
  }, [getDoc, open])

  useEffect(() => {
    if (!open) {
      return
    }

    const doc = ownerDocument(rootRef.current)

    // We might render an empty child.
    if (
      !disableAutoFocus &&
      rootRef.current &&
      !rootRef.current.contains(doc.activeElement)
    ) {
      if (!rootRef.current.hasAttribute('tabIndex')) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            [
              'Tui: the modal content node does not accept focus.',
              'For the benefit of assistive technologies, ' +
                'the tabIndex of the node is being set to "-1".'
            ].join('\n')
          )
        }
        rootRef.current.setAttribute('tabIndex', '-1')
      }

      rootRef.current.focus()
    }

    const contain = () => {
      if (
        !doc.hasFocus() ||
        disableEnforceFocus ||
        !isEnabled() ||
        ignoreNextEnforceFocus.current
      ) {
        ignoreNextEnforceFocus.current = false
        return
      }

      if (rootRef.current && !rootRef.current.contains(doc.activeElement)) {
        rootRef.current.focus()
      }
    }

    const loopFocus = (event: KeyboardEvent) => {
      // 9 = Tab
      if (disableEnforceFocus || !isEnabled() || event.keyCode !== 9) {
        return
      }

      // Make sure the next tab starts from the right place.
      if (doc.activeElement === rootRef.current) {
        // We need to ignore the next contain as
        // it will try to move the focus back to the rootRef element.
        ignoreNextEnforceFocus.current = true
        if (event.shiftKey) {
          sentinelEnd.current && sentinelEnd.current.focus()
        } else {
          sentinelStart.current && sentinelStart.current.focus()
        }
      }
    }

    doc.addEventListener('focus', contain, true)
    doc.addEventListener('keydown', loopFocus, true)

    // With Edge, Safari and Firefox, no focus related events are fired when the focused area stops being a focused area
    // e.g. https://bugzilla.mozilla.org/show_bug.cgi?id=559561.
    //
    // The whatwg spec defines how the browser should behave but does not explicitly mention any events:
    // https://html.spec.whatwg.org/multipage/interaction.html#focus-fixup-rule.
    const interval = setInterval(() => {
      contain()
    }, 50)

    return () => {
      clearInterval(interval)

      doc.removeEventListener('focus', contain, true)
      doc.removeEventListener('keydown', loopFocus, true)

      // restoreLastFocus()
      if (!disableRestoreFocus) {
        // In IE 11 it is possible for document.activeElement to be null resulting
        // in nodeToRestore.current being null.
        // Not all elements in IE 11 have a focus method.
        // Once IE 11 support is dropped the focus() call can be unconditional.
        if (nodeToRestore.current && nodeToRestore.current.focus) {
          nodeToRestore.current.focus()
        }

        nodeToRestore.current = null
      }
    }
  }, [
    disableAutoFocus,
    disableEnforceFocus,
    disableRestoreFocus,
    isEnabled,
    open
  ])

  return (
    <React.Fragment>
      <Box tabIndex={0} ref={sentinelStart} data-test="sentinelStart" />
      {React.cloneElement(children, { ref: handleRef })}
      <Box tabIndex={0} ref={sentinelEnd} data-test="sentinelEnd" />
    </React.Fragment>
  )
}

export default TrapFocus
