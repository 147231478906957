import { jsx, merge, ThemeProvider, useThemeUI } from '@theme-ui/core'
import type { ThemeUIContextValue as BaseThemeUIContextValue } from '@theme-ui/core'
import type { Theme as BaseTheme } from '@theme-ui/css'
import type { Dispatch, SetStateAction } from 'react'
import React, { useEffect, useState } from 'react'

import { toCustomProperties } from './custom-properties'
import type { Theme } from './types'
// eslint-disable-next-line import/order
import {
  getBorders as getBordersTheme,
  getGradients as getGradientsTheme,
  getShadows as getShadowsTheme
} from '../themes/theme-keys'

// import { Global } from '@emotion/react'
import { memoizedGet as get } from '../utils/object'

const STORAGE_KEY = 'csg-tui-color-mode'

export type ThemeUIContextValue = BaseThemeUIContextValue & {
  colorMode?: string
  setColorMode?: (colorMode: SetStateAction<string | undefined>) => void
}

const storage = {
  get: () => {
    try {
      return window.localStorage.getItem(STORAGE_KEY)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(
        'localStorage is disabled and color mode might not work as expected.',
        'Please check your Site Settings.',
        e
      )
    }
    return
  },
  set: (value: string) => {
    try {
      window.localStorage.setItem(STORAGE_KEY, value)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(
        'localStorage is disabled and color mode might not work as expected.',
        'Please check your Site Settings.',
        e
      )
    }
  }
}

const getPreferredColorScheme = (): 'dark' | 'light' | null => {
  if (typeof window !== 'undefined' && window.matchMedia) {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return 'dark'
    }
    if (window.matchMedia('(prefers-color-scheme: light)').matches) {
      return 'light'
    }
  }
  return null
}

const getModeFromClass = (): string | undefined => {
  let mode: string | undefined
  if (typeof document !== 'undefined') {
    document.documentElement.classList.forEach(className => {
      if (className.startsWith('csg-tui-')) {
        mode = className.replace('csg-tui-', '')
      }
    })
  }
  return mode
}

const useColorModeState = (theme: Theme = {}) => {
  // eslint-disable-next-line prefer-const
  const [mode, setMode] = useState(() => {
    const modeFromClass = getModeFromClass()
    if (modeFromClass) {
      return modeFromClass
    }

    const preferredMode =
      theme.useColorSchemeMediaQuery !== false && getPreferredColorScheme()

    return preferredMode || theme.initialColorModeName
  })

  // on first render, we read the color mode from localStorage and
  // clear the class on document element body
  useEffect(() => {
    const stored = theme.useLocalStorage !== false && storage.get()

    if (typeof document !== 'undefined') {
      document.documentElement.classList.remove('cgs-tui-' + stored)
      document.body.classList.remove('cgs-tui-' + stored)
    }

    if (stored && stored !== mode) {
      setMode(stored)
    }
  }, [mode, theme.useLocalStorage])

  // when mode changes, we save it to localStorage
  useEffect(() => {
    if (mode && theme.useLocalStorage !== false) {
      storage.set(mode)
    }
  }, [mode, theme.useLocalStorage])

  if (process.env.NODE_ENV !== 'production') {
    if (
      theme.colors &&
      theme.colors.modes &&
      theme.initialColorModeName &&
      Object.keys(theme.colors.modes).indexOf(theme.initialColorModeName) > -1
    ) {
      // eslint-disable-next-line no-console
      console.warn(
        'The `initialColorModeName` value should be a unique name' +
          ' and cannot reference a key in `theme.colors.modes`.'
      )
    }
  }

  return [mode, setMode] as const
}

export function useColorMode<T extends string = string>(): [
  T,
  Dispatch<SetStateAction<T>>
] {
  const { colorMode, setColorMode } = useThemeUI() as ThemeUIContextValue

  if (typeof setColorMode !== 'function') {
    throw new Error(`[useColorMode] requires the ColorModeProvider component`)
  }

  // We're allowing the user to specify a narrower type for its color mode name.
  return [colorMode, setColorMode] as unknown as [
    T,
    Dispatch<SetStateAction<T>>
  ]
}

const applyColorMode = (theme: Theme, mode: string | undefined): Theme => {
  if (!mode) return { ...theme }
  const modes = get(theme, 'colors.modes', {})
  const colorSchema = get(modes, mode, {})
  return merge.all({}, theme, {
    colors: colorSchema,
    borders: getBordersTheme(colorSchema),
    gradients: getGradientsTheme(colorSchema),
    shadows: getShadowsTheme(colorSchema)
  })
}

const ColorModeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const outer = useThemeUI()
  const [colorMode, setColorMode] = useColorModeState(outer.theme as Theme)

  const theme = applyColorMode((outer.theme || {}) as Theme, colorMode)
  if (theme.useCustomProperties !== false) {
    // TODO: This mutation is less than ideal
    // We could save custom properties to `theme.colorVars`,
    // But it's infeasible to do this because of how the packages are split.
    theme.rawColors = theme.colors
    theme.colors = toCustomProperties(theme.colors, 'colors')
  }

  const context = {
    ...outer,
    theme,
    colorMode,
    setColorMode
  } as ThemeUIContextValue

  // const isTopLevelColorModeProvider = outer.setColorMode === undefined

  return <ThemeProvider theme={context as BaseTheme}>{children}</ThemeProvider>
}

const noFlash = `(function() { try {
  var mode = localStorage.getItem('csg-tui-color-mode');
  if (!mode) return
  document.documentElement.classList.add('csg-tui-' + mode);
  document.body.classList.add('csg-tui-' + mode);
} catch (e) {} })();`

export const InitializeColorMode = (): JSX.Element =>
  jsx('script', {
    key: 'csg-tui-no-flash',
    dangerouslySetInnerHTML: {
      __html: noFlash
    }
  })

export default ColorModeProvider
