/* eslint-disable @typescript-eslint/no-explicit-any */
import chroma from 'chroma-js'

import type { BorderWidthThemeKeys } from './border-widths'
import { borderWidths } from './border-widths'
import type {
  ObjectWithDefault,
  ThemeUICSSObject
} from '../../theme-provider/types'
import { THEME_UI_DEFAULT_KEY } from '../../theme-provider/types'

function safeColor(color: string | ObjectWithDefault<string>): string {
  if (typeof color === 'string') return color
  if (typeof color === 'object' && THEME_UI_DEFAULT_KEY in color)
    return color[THEME_UI_DEFAULT_KEY] as string
  return '#fff'
}

const shadowSettings = {
  qtrBlur: '0 0 4px',
  ethBlur: '0 0 2px',
  halfBlur: '0 0 8px',
  qtrBlurRise: '0 2px 4px',
  ethBlurRise: '0 2px 6px',
  halfBlurRise: '0 2px 8px',
  bottomOnly: '0 3px 4px -2px'
}

export type ShadowSettingsThemeKeys = keyof typeof shadowSettings
export function isShadowKey(
  subject: string
): subject is ShadowSettingsThemeKeys {
  return Object.keys(shadowSettings).includes(subject)
}

export type ShadowsThemeKey =
  | 'default'
  | 'defaultRise'
  | 'defaultRaiseTop'
  | 'black'
  | 'text'
  | 'textRise'
  | 'textThick'
  | 'textNormal'
  | 'textShadow'
  | 'textPrimary'
  | 'textDanger'
  | 'textWhite'
  | 'textBottomOnly'
  | 'primary'
  | 'primaryRise'
  | 'primaryInset'
  | 'primaryThin'
  | 'primaryThick'
  | 'secondary'
  | 'secondaryRise'
  | 'whiteRise'
  | 'danger'
  | 'neutral'
  | 'cosmos'
  | 'dropShadow'

const ShadowsThemeKeys: ShadowsThemeKey[] = [
  'default',
  'defaultRise',
  'defaultRaiseTop',
  'black',
  'text',
  'textRise',
  'textThick',
  'textNormal',
  'textShadow',
  'textPrimary',
  'textDanger',
  'textWhite',
  'textBottomOnly',
  'primary',
  'primaryRise',
  'primaryInset',
  'primaryThin',
  'primaryThick',
  'secondary',
  'secondaryRise',
  'whiteRise',
  'danger',
  'cosmos',
  'dropShadow'
]

type ColorOffset = {
  alpha: number
  offset: ShadowSettingsThemeKeys | BorderWidthThemeKeys
}

export function generateBoxShadow(
  color: string | ObjectWithDefault<string>,
  offset: ColorOffset[] = [
    { alpha: 0.4, offset: 'qtrBlurRise' },
    { alpha: 0.2, offset: 'default' }
  ]
) {
  function getSection(section: ColorOffset) {
    const { offset, alpha } = section
    return isShadowKey(offset)
      ? `${shadowSettings[offset]} ${chroma(safeColor(color))
          .alpha(alpha)
          .css()}`
      : `0 0 0 ${borderWidths[offset]} ${chroma(safeColor(color))
          .alpha(alpha)
          .css()}`
  }
  return offset.reduce(
    (acc, cur) =>
      `${acc}${acc.length > 0 ? ' ,' + getSection(cur) : getSection(cur)}`,
    ''
  )
}

function getShadows(
  colors: any
): Record<
  ShadowsThemeKey,
  | ThemeUICSSObject['boxShadow']
  | ObjectWithDefault<ThemeUICSSObject['boxShadow']>
> {
  return {
    default: generateBoxShadow(colors.black, [
      { offset: 'qtrBlur', alpha: 0.1 }
    ]),
    defaultRise: generateBoxShadow(colors.black),
    defaultRaiseTop: 'rgb(64 64 64) 0px -4px 12px -8px',
    text: generateBoxShadow(colors.text, [{ offset: 'qtrBlur', alpha: 0.2 }]),
    textBottomOnly: `${shadowSettings.bottomOnly} ${chroma(
      safeColor(colors.text)
    )
      .alpha(0.2)
      .css()}`,
    textRise: `${shadowSettings.qtrBlurRise} ${chroma(safeColor(colors.text))
      .alpha(0.2)
      .css()}, 0 0 0 ${borderWidths.default} ${chroma(safeColor(colors.text))
      .alpha(0.2)
      .css()}`,
    textThick: `${shadowSettings.halfBlur} ${chroma(safeColor(colors.text))
      .alpha(0.3)
      .css()}`,
    textNormal: `0 0 ${safeColor(colors.text)}`,
    textShadow: `0 0 1px ${safeColor(colors.text)}`,
    textPrimary: `0 0 1px ${safeColor(colors.primary)}`,
    textWhite: `0 0 1px ${safeColor(colors.white)}`,
    textDanger: `0 0 1px ${safeColor(colors.danger)}`,
    black: {
      [THEME_UI_DEFAULT_KEY]: generateBoxShadow(colors.black),
      inset: `inset 0px 0px 1px ${colors.black}`
    },
    primary: {
      [THEME_UI_DEFAULT_KEY]: `${shadowSettings.qtrBlur} ${safeColor(
        colors.primary
      )}`,
      rise: generateBoxShadow(colors.primary),
      inset: `inset ${shadowSettings.ethBlur} ${chroma(
        safeColor(colors.primary)
      )
        .alpha(0.2)
        .css()}`,
      thin: `${shadowSettings.ethBlur} ${chroma(safeColor(colors.primary))
        .alpha(0.1)
        .css()}`,
      thick: `${shadowSettings.halfBlur} ${chroma(safeColor(colors.primary))
        .alpha(0.3)
        .css()}`,
      shadow: `0 0 1px ${safeColor(colors.primary)}`,
      focus: `0 0 4px ${safeColor(colors.primary)}`
    },
    primaryRise: {
      variant: 'shadows.primary.rise'
    },
    primaryInset: {
      variant: 'shadows.primary.inset'
    },
    primaryThin: {
      variant: 'shadows.primary.thin'
    },
    primaryThick: {
      variant: 'shadows.primary.thick'
    },
    secondary: {
      [THEME_UI_DEFAULT_KEY]: `${shadowSettings.qtrBlur} ${chroma(
        safeColor(colors.secondary)
      )
        .alpha(0.2)
        .css()}`,
      rise: `${shadowSettings.qtrBlurRise} ${chroma(safeColor(colors.secondary))
        .alpha(0.4)
        .css()}, 0 0 0 ${borderWidths.default} ${chroma(
        safeColor(colors.secondary)
      )
        .alpha(0.2)
        .css()}`
    },
    secondaryRise: {
      variant: 'shadows.secondary.rise'
    },
    whiteRise: `${shadowSettings.qtrBlurRise} ${chroma(colors.primaries[6])
      .alpha(0.4)
      .css()}, 0 0 0 ${borderWidths.default} ${chroma(colors.neutrals[17])
      .alpha(0.2)
      .css()}`,
    danger: `${shadowSettings.qtrBlur} ${safeColor(colors.danger)}`,
    neutral: {
      [THEME_UI_DEFAULT_KEY]: generateBoxShadow(colors.neutral.darker)
    },
    cosmos: {
      [THEME_UI_DEFAULT_KEY]: '2px 5px 15px 0px rgba(0,0,0,0.35)'
    },
    dropShadow: {
      [THEME_UI_DEFAULT_KEY]: '2px 4px 6px 0 rgba(0,0,0,0.20)'
    }
  }
}

export function isValidShadowsToken(key: string): boolean {
  const retVal = ShadowsThemeKeys.includes(key as any)
  if (process.env.NODE_ENV !== 'production' && !retVal) {
    // eslint-disable-next-line no-console
    console.warn(` invalid shadows Token ${key} `)
  }
  return retVal
}

export function borderToShadows(key: string) {
  switch (key) {
    case 'neutral.medium':
      return 'defaultRise'
    default:
      return key
  }
}

export default getShadows
