/* eslint-disable @typescript-eslint/no-explicit-any */
import elevationTransform from './elevation-transform'
import hasDefault from './has-default'
import lhCropTransform from './lh-crop-transform'
import type {
  CSSObject,
  CssPropsArgument,
  Theme,
  ThemeDerivedStyles,
  ThemeUICSSObject,
  ThemeUIStyleObject
} from './types'
import { THEME_UI_DEFAULT_KEY } from './types'
import {
  emMarginTransform,
  emPaddingTransform
} from '../styles-system/em-space'
import { getSpacing, resolveMargin } from '../styles-system/space'
import { getFontSize } from '../styles-system/typography'
import { defaultBreakpoints } from '../themes/theme-keys/breakpoints'
import type { Breakpoints } from '../themes/theme-keys/breakpoints'
import { isNotEmptyObject } from '../utils/assertion'
import { EMPTY_OBJECT, memoizedGet as get } from '../utils/object'

const defaultTheme = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72]
}

const aliases = {
  bg: 'backgroundColor',
  m: 'margin',
  mt: 'marginTop',
  mr: 'marginRight',
  mb: 'marginBottom',
  ml: 'marginLeft',
  mx: 'marginX',
  my: 'marginY',
  p: 'padding',
  pt: 'paddingTop',
  pr: 'paddingRight',
  pb: 'paddingBottom',
  pl: 'paddingLeft',
  px: 'paddingX',
  py: 'paddingY'
} as const
type Aliases = typeof aliases

const postAliases = {
  emPadding: 'padding',
  emPaddingTop: 'paddingTop',
  emPaddingRight: 'paddingRight',
  emPaddingBottom: 'paddingBottom',
  emPaddingLeft: 'paddingLeft',
  emPaddingX: 'paddingX',
  emPaddingY: 'paddingY',
  emMargin: 'margin',
  emMarginTop: 'marginTop',
  emMarginRight: 'marginRight',
  emMarginBottom: 'marginBottom',
  emMarginLeft: 'marginLeft',
  emMarginX: 'marginX',
  emMarginY: 'marginY',
  lhCrop: '', // empty string will apply the generated css object to the parent css object (fall back handling by emotion)
  elevation: 'boxShadow'
} as const
type PostAliases = typeof postAliases

function resolveDefaultVariant(
  scaleKey: string,
  props: any
): string | undefined {
  return scaleKey === 'elevation' && 'boxShadow' in props
    ? props['boxShadow']
    : undefined
}

const multiples = {
  marginX: ['marginLeft', 'marginRight'],
  marginY: ['marginTop', 'marginBottom'],
  paddingX: ['paddingLeft', 'paddingRight'],
  paddingY: ['paddingTop', 'paddingBottom'],
  size: ['width', 'height']
}

const scales = {
  color: 'colors',
  backgroundColor: 'colors',
  borderColor: 'colors',
  caretColor: 'colors',
  columnRuleColor: 'colors',
  opacity: 'opacities',
  transition: 'transitions',
  margin: 'space',
  marginTop: 'space',
  marginRight: 'space',
  marginBottom: 'space',
  marginLeft: 'space',
  marginX: 'space',
  marginY: 'space',
  marginBlock: 'space',
  marginBlockEnd: 'space',
  marginBlockStart: 'space',
  marginInline: 'space',
  marginInlineEnd: 'space',
  marginInlineStart: 'space',
  padding: 'space',
  paddingTop: 'space',
  paddingRight: 'space',
  paddingBottom: 'space',
  paddingLeft: 'space',
  paddingX: 'space',
  paddingY: 'space',
  emMargin: 'emSpace',
  emMarginTop: 'emSpace',
  emMarginRight: 'emSpace',
  emMarginBottom: 'emSpace',
  emMarginLeft: 'emSpace',
  emMarginX: 'emSpace',
  emMarginY: 'emSpace',
  emPadding: 'emSpace',
  emPaddingTop: 'emSpace',
  emPaddingRight: 'emSpace',
  emPaddingBottom: 'emSpace',
  emPaddingLeft: 'emSpace',
  emPaddingX: 'emSpace',
  emPaddingY: 'emSpace',
  paddingBlock: 'space',
  paddingBlockEnd: 'space',
  paddingBlockStart: 'space',
  paddingInline: 'space',
  paddingInlineEnd: 'space',
  paddingInlineStart: 'space',
  scrollPadding: 'space',
  scrollPaddingTop: 'space',
  scrollPaddingRight: 'space',
  scrollPaddingBottom: 'space',
  scrollPaddingLeft: 'space',
  scrollPaddingX: 'space',
  scrollPaddingY: 'space',
  inset: 'space',
  insetBlock: 'space',
  insetBlockEnd: 'space',
  insetBlockStart: 'space',
  insetInline: 'space',
  insetInlineEnd: 'space',
  insetInlineStart: 'space',
  top: 'space',
  right: 'space',
  bottom: 'space',
  left: 'space',
  gridGap: 'space',
  gridColumnGap: 'space',
  gridRowGap: 'space',
  gap: 'space',
  columnGap: 'space',
  rowGap: 'space',
  fontFamily: 'fonts',
  fontSize: 'fontSizes',
  fontWeight: 'fontWeights',
  lineHeight: 'lineHeights',
  letterSpacing: 'letterSpacings',
  outline: 'borders',
  border: 'borders',
  borderTop: 'borders',
  borderRight: 'borders',
  borderBottom: 'borders',
  borderLeft: 'borders',
  borderWidth: 'borderWidths',
  borderStyle: 'borderStyles',
  borderRadius: 'radii',
  borderTopRightRadius: 'radii',
  borderTopLeftRadius: 'radii',
  borderBottomRightRadius: 'radii',
  borderBottomLeftRadius: 'radii',
  borderTopWidth: 'borderWidths',
  borderTopColor: 'colors',
  borderTopStyle: 'borderStyles',
  borderBottomWidth: 'borderWidths',
  borderBottomColor: 'colors',
  borderBottomStyle: 'borderStyles',
  borderLeftWidth: 'borderWidths',
  borderLeftColor: 'colors',
  borderLeftStyle: 'borderStyles',
  borderRightWidth: 'borderWidths',
  borderRightColor: 'colors',
  borderRightStyle: 'borderStyles',
  borderBlock: 'borders',
  borderBlockEnd: 'borders',
  borderBlockEndStyle: 'borderStyles',
  borderBlockEndWidth: 'borderWidths',
  borderBlockStart: 'borders',
  borderBlockStartStyle: 'borderStyles',
  borderBlockStartWidth: 'borderWidths',
  borderBlockStyle: 'borderStyles',
  borderBlockWidth: 'borderWidths',
  borderEndEndRadius: 'radii',
  borderEndStartRadius: 'radii',
  borderInline: 'borders',
  borderInlineEnd: 'borders',
  borderInlineEndStyle: 'borderStyles',
  borderInlineEndWidth: 'borderWidths',
  borderInlineStart: 'borders',
  borderInlineStartStyle: 'borderStyles',
  borderInlineStartWidth: 'borderWidths',
  borderInlineStyle: 'borderStyles',
  borderInlineWidth: 'borderWidths',
  borderStartEndRadius: 'radii',
  borderStartStartRadius: 'radii',
  outlineColor: 'colors',
  elevation: 'elevations',
  boxShadow: 'shadows',
  textShadow: 'shadows',
  zIndex: 'zIndices',
  width: 'sizes',
  minWidth: 'sizes',
  maxWidth: 'sizes',
  height: 'sizes',
  minHeight: 'sizes',
  maxHeight: 'sizes',
  flexBasis: 'sizes',
  size: 'sizes',
  blockSize: 'sizes',
  inlineSize: 'sizes',
  maxBlockSize: 'sizes',
  maxInlineSize: 'sizes',
  minBlockSize: 'sizes',
  minInlineSize: 'sizes',
  // svg
  fill: 'colors',
  stroke: 'colors',
  lhCrop: 'lhCrops'
} as const
type Scales = typeof scales

const positiveOrNegative = (value: string | number, scale: object) => {
  if (typeof value !== 'number' || value >= 0) {
    if (typeof value === 'string' && value.startsWith('-')) {
      const valueWithoutMinus = value.substring(1)
      const n = get(scale, valueWithoutMinus, valueWithoutMinus)
      return `-${n}`
    }
    return get(scale, value, value)
  }
  const absolute = Math.abs(value)
  const n = get(scale, absolute, absolute)
  if (typeof n === 'string') return '-' + n
  return Number(n) * -1
}

const marginTransforms = [
  'margin',
  'marginTop',
  'marginRight',
  'marginBottom',
  'marginLeft',
  'marginX',
  'marginY',
  'marginBlock',
  'marginBlockEnd',
  'marginBlockStart',
  'marginInline',
  'marginInlineEnd',
  'marginInlineStart'
].reduce(
  // (acc, curr) => ({
  //   ...acc,
  //   [curr]: resolveMargin
  // }),
  (acc, curr) => {
    acc[curr] = resolveMargin
    return acc
  },
  {} as Record<string, typeof resolveMargin>
)

const emMarginTransforms = [
  'emMargin',
  'emMarginTop',
  'emMarginRight',
  'emMarginBottom',
  'emMarginLeft',
  'emMarginX',
  'emMarginY'
].reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: emMarginTransform
  }),
  {}
)

const tShirtSizeSpacingTransforms = [
  'padding',
  'paddingTop',
  'paddingRight',
  'paddingBottom',
  'paddingLeft',
  'paddingX',
  'paddingY',
  'gap',
  'gridGap',
  'gridColumnGap',
  'gridRowGap',
  'columnGap',
  'rowGap'
].reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: getSpacing
  }),
  {}
)

const emPaddingTransforms = [
  'emPadding',
  'emPaddingTop',
  'emPaddingRight',
  'emPaddingBottom',
  'emPaddingLeft',
  'emPaddingX',
  'emPaddingY'
].reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: emPaddingTransform
  }),
  {}
)

const fontSizeTransforms = ['fontSize'].reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: getFontSize
  }),
  {}
)

const positionTransforms = ['top', 'bottom', 'left', 'right'].reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: positiveOrNegative
  }),
  {}
)

const lhCropTransforms = ['lhCrop'].reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: lhCropTransform
  }),
  {}
)

const elevationTransforms = ['elevation'].reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: elevationTransform
  }),
  {}
)

const customTransforms = {
  ...marginTransforms,
  ...emMarginTransforms,
  ...tShirtSizeSpacingTransforms,
  ...emPaddingTransforms,
  ...fontSizeTransforms,
  ...positionTransforms,
  ...lhCropTransforms,
  ...elevationTransforms
}

const customTransformKeys = Object.keys(customTransforms)

export const responsive =
  (styles: Exclude<ThemeUIStyleObject, ThemeDerivedStyles>) =>
  (theme?: Theme, props: CssPropsArgument = {}) => {
    const next: Exclude<ThemeUIStyleObject, ThemeDerivedStyles> = {}
    const breakpoints: Breakpoints = theme
      ? get(theme, 'breakpoints', defaultBreakpoints)
      : defaultBreakpoints
    const mediaQueries = [null, ...breakpoints.map()]

    for (const k in styles) {
      const key = k as keyof typeof styles
      let value = styles[key]
      if (typeof value === 'function') {
        value = value(
          (theme || {}) as Omit<Theme, 'breakpoints' | 'transitions'>,
          props
        )
      }

      if (value == null) continue
      if (!Array.isArray(value)) {
        next[key] = value
        continue
      }
      for (let i = 0; i < value.slice(0, mediaQueries.length).length; i++) {
        const media = mediaQueries[i]
        if (!media) {
          next[key] = value[i]
          continue
        }
        next[media] = next[media] || {}
        if (value[i] == null) continue
        ;(next[media] as Record<string, any>)[key] = value[i]
      }
    }

    return next
  }

function getObjectWithVariants(
  obj: any,
  theme: Theme,
  props: CssPropsArgument = {}
): CSSObject {
  if (obj && obj['variant']) {
    let result: CSSObject = {}
    for (const key in obj) {
      const x = obj[key]
      if (key === 'variant') {
        const val = typeof x === 'function' ? x(theme, props) : x
        const variant = getObjectWithVariants(get(theme, val as string), theme)
        result = { ...result, ...variant }
      } else {
        result[key] = x as CSSObject
      }
    }
    return result
  }
  return obj as CSSObject
}

export function css(args: ThemeUIStyleObject = {}) {
  return function (props: CssPropsArgument = {}): CSSObject {
    const theme = {
      ...defaultTheme,
      ...('theme' in props ? props.theme : props)
    }

    // insert variant props before responsive styles, so they can be merged
    // we need to maintain order of the style props, so if a variant is place in the middle
    // of other props, it will extends its props at that same location order.

    const obj: CSSObject = getObjectWithVariants(
      typeof args === 'function' ? args(theme, props) : args,
      theme,
      props
    )

    const styles = responsive(obj as ThemeUICSSObject)(theme, props)

    const result: CSSObject = {}

    for (const key in styles) {
      const x = styles[key as keyof typeof styles]
      const val = typeof x === 'function' ? x(theme, props) : x

      if (val === undefined || val === null) {
        continue
      }

      if (val && typeof val === 'object') {
        if (hasDefault(val)) {
          result[key] = (val as any)[THEME_UI_DEFAULT_KEY]
          continue
        }

        // On type level, val can also be an array here,
        // but we transform all arrays in `responsive` function.
        result[key] = css(val as ThemeUICSSObject)(theme)
        continue
      }

      let scaleKey = key in aliases ? aliases[key as keyof Aliases] : key
      const scaleName =
        scaleKey in scales ? scales[scaleKey as keyof Scales] : undefined
      const scale = get(
        theme,
        scaleName as string,
        get(theme, scaleKey, EMPTY_OBJECT)
      )
      const transform = get(customTransforms, scaleKey, get)
      const value = customTransformKeys.includes(scaleKey)
        ? transform(
            val,
            scale,
            theme,
            '__themeKey' in props && 'variant' in props
              ? `${props.__themeKey}.${props.variant}`
              : resolveDefaultVariant(scaleKey, obj)
          )
        : transform(scale, val, val)
      scaleKey =
        scaleKey in postAliases
          ? postAliases[scaleKey as keyof PostAliases]
          : scaleKey
      if ((multiples as any)[scaleKey]) {
        const dirs = (multiples as any)[scaleKey]

        for (let i = 0; i < dirs.length; i++) {
          result[dirs[i]] = value
        }
      } else {
        result[scaleKey] = value
      }
    }

    if (isNotEmptyObject(result)) {
      // eslint-disable-next-line no-console
      // console.log(`prop:
      // __themeKey: ${(props as any).__themeKey}
      // variant: ${(props as any).variant}
      // result:
      // ${JSON.stringify(result, undefined, 2)}`)
    }

    return result
  }
}

export default css
