/* eslint-disable @typescript-eslint/no-explicit-any */
// port from @styled-system/space v.5.1.2
import { compose, system } from './system-core'
import { isNumber } from '../utils/assertion'
import { memoizedGet as get } from '../utils/object'
import { resolveSizeValue } from '../utils/size-utils'

export const defaults = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512]
}

export const resolveMargin = (n: any, scale: any, props: any) => {
  const v = resolveSizeValue(props.theme || props)(n, 'sizes.space')
  if (!isNumber(v)) {
    return get(scale, v, v)
  }

  const isNegative = Number(v) < 0
  const absolute = Math.abs(v)
  const value = get(scale, absolute, absolute)
  if (!isNumber(value)) {
    return isNegative ? '-' + value : value
  }
  return Number(value) * (isNegative ? -1 : 1)
}

export const getSpacing = (n: any, scale: any, props: any) => {
  const v = resolveSizeValue(props.theme || props)(n, 'sizes.space')

  return get(scale, v, v)
}

const configs: any = {}
configs.margin = {
  margin: {
    property: 'margin',
    scale: 'space',
    transform: resolveMargin,
    defaultScale: defaults.space
  },
  marginTop: {
    property: 'marginTop',
    scale: 'space',
    transform: resolveMargin,
    defaultScale: defaults.space
  },
  marginRight: {
    property: 'marginRight',
    scale: 'space',
    transform: resolveMargin,
    defaultScale: defaults.space
  },
  marginBottom: {
    property: 'marginBottom',
    scale: 'space',
    transform: resolveMargin,
    defaultScale: defaults.space
  },
  marginLeft: {
    property: 'marginLeft',
    scale: 'space',
    transform: resolveMargin,
    defaultScale: defaults.space
  },
  marginX: {
    properties: ['marginLeft', 'marginRight'],
    scale: 'space',
    transform: resolveMargin,
    defaultScale: defaults.space
  },
  marginY: {
    properties: ['marginTop', 'marginBottom'],
    scale: 'space',
    transform: resolveMargin,
    defaultScale: defaults.space
  }
}
configs.margin.m = configs.margin.margin
configs.margin.mt = configs.margin.marginTop
configs.margin.mr = configs.margin.marginRight
configs.margin.mb = configs.margin.marginBottom
configs.margin.ml = configs.margin.marginLeft
configs.margin.mx = configs.margin.marginX
configs.margin.my = configs.margin.marginY

configs.padding = {
  padding: {
    property: 'padding',
    scale: 'space',
    transform: getSpacing,
    defaultScale: defaults.space
  },
  paddingTop: {
    property: 'paddingTop',
    scale: 'space',
    transform: getSpacing,
    defaultScale: defaults.space
  },
  paddingRight: {
    property: 'paddingRight',
    scale: 'space',
    transform: getSpacing,
    defaultScale: defaults.space
  },
  paddingBottom: {
    property: 'paddingBottom',
    scale: 'space',
    transform: getSpacing,
    defaultScale: defaults.space
  },
  paddingLeft: {
    property: 'paddingLeft',
    scale: 'space',
    transform: getSpacing,
    defaultScale: defaults.space
  },
  paddingX: {
    properties: ['paddingLeft', 'paddingRight'],
    scale: 'space',
    transform: getSpacing,
    defaultScale: defaults.space
  },
  paddingY: {
    properties: ['paddingTop', 'paddingBottom'],
    scale: 'space',
    transform: getSpacing,
    defaultScale: defaults.space
  }
}
configs.padding.p = configs.padding.padding
configs.padding.pt = configs.padding.paddingTop
configs.padding.pr = configs.padding.paddingRight
configs.padding.pb = configs.padding.paddingBottom
configs.padding.pl = configs.padding.paddingLeft
configs.padding.px = configs.padding.paddingX
configs.padding.py = configs.padding.paddingY

export const margin = system(configs.margin)
export const padding = system(configs.padding)
export const space = compose(margin, padding)

export default space
