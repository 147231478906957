// port from @styled-system/shadow v.5.1.2
import { system } from './system-core'

export const shadow = system({
  boxShadow: {
    property: 'boxShadow',
    scale: 'shadows'
  },
  textShadow: {
    property: 'textShadow',
    scale: 'shadows'
  }
})

export default shadow
