import type { FC } from 'react'
import React, { useMemo } from 'react'

import type { BackdropProps } from './types'
import type { Theme } from '../../../theme-provider/types'
import { FlexPrivate } from '../../_primitives/flex/flex'
import Fade from '../fade'
import Portal from '../portal/portal'

const Backdrop: FC<BackdropProps> = React.forwardRef<
  HTMLDivElement,
  BackdropProps & { theme?: Theme }
>(function Backdrop(props, ref) {
  const {
    children,
    invisible = false,
    open,
    usePortal = false,
    transitionDuration = 500,
    TransitionComponent = Fade,
    bg,
    backgroundColor,
    zIndex,
    shouldHoistTheme = true,
    ...rest
  } = props
  const _bg = bg || backgroundColor

  const styles = useMemo(
    () => ({
      zIndex: zIndex ? zIndex : -1,
      position: 'fixed',
      alignItems: 'center',
      justifyContent: 'center',
      right: 0,
      bottom: 0,
      top: 0,
      left: 0,
      bg: _bg ? _bg : 'opaqueBlack.3',
      WebkitTapHighlightColor: 'transparent',
      ...(invisible ? { bg: 'transparent' } : {})
    }),
    [_bg, invisible, zIndex]
  )

  const content = (
    <TransitionComponent in={open} timeout={transitionDuration}>
      <FlexPrivate
        __themeKey="backdrops"
        data-csg-test="Backdrop"
        __css={styles}
        aria-hidden
        ref={ref}
        {...rest}
      >
        {children}
      </FlexPrivate>
    </TransitionComponent>
  )

  return usePortal ? (
    <Portal shouldHoistTheme={shouldHoistTheme}>{content}</Portal>
  ) : (
    content
  )
})

export default Backdrop
