/* eslint-disable @typescript-eslint/no-explicit-any */
// ported from @styled-system/variant v5.1.5

import css from './css'
import { createParser } from '../styles-system/system-core'
import { EMPTY_OBJECT, memoizedGet as get } from '../utils/object'

export default function ({
  scale,
  prop = 'variant',
  // enables new api
  variants = EMPTY_OBJECT,
  // shim for v4 API
  key
}: any) {
  let sx: any
  if (Object.keys(variants).length) {
    sx = function (value: any, scale: any, props: any) {
      return css(get(scale, value, null))(props)
    }
  } else {
    sx = function (value: any, scale: any) {
      return get(scale, value, null)
    }
  }
  sx.scale = scale || key
  sx.defaults = variants
  const config = {
    [prop]: sx
  }
  const parser = createParser(config)
  return parser
}
