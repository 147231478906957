/* eslint-disable @typescript-eslint/no-explicit-any */
import { isNumber, isNumeric } from './assertion'
import { toNumber } from './number'
import { memoizedGet as get } from './object'
import type { Empty } from './types'
import type { SizeProps } from '../components/_primitives/types'
import type { Theme } from '../theme-provider/types'
import { SizeEnum } from '../themes/size-enum'
import type { Size } from '../themes/types'

const SizeIndex = Object.keys(SizeEnum) as Size[]

export const isSizeIndex = (v: any): v is Size =>
  SizeIndex.includes(`${v}` as Size)

export function pxToRem(value: string | number, basePx = 16): string {
  return typeof value === 'number'
    ? `${value / basePx}rem`
    : value.includes('px')
      ? `${Number(value.replace('px', '')) / basePx}rem`
      : value
}

export function remToPx(value: string | number, basePx = 16): string {
  return typeof value === 'number'
    ? `${value}px`
    : value.includes('rem')
      ? `${Number(value.replace('rem', '')) * basePx}px`
      : value
}

export const resolveSizeValue =
  (theme: Theme) =>
  (n: Size | number, sizesKey: string): number | string => {
    const isNegative =
      (typeof n === 'string' && n.startsWith('-')) ||
      (isNumber(n) && Number(n) < 0)
    const t = isNegative ? (isNumber(n) ? Math.abs(n) : `${n}`.slice(1)) : n
    const v =
      typeof t === 'string' && t.startsWith('sizes')
        ? get(theme, t, t)
        : get(get(theme, sizesKey), t, t)
    if (!isNumber(v)) {
      return isNegative ? '-' + v : v
    }
    return Number(v) * (isNegative ? -1 : 1)
  }

export const resolveSize = (
  size?: SizeProps,
  sizes?: Record<Size, number>
): string | number | (string | number)[] => {
  const getSize = (v: string | number | Empty) =>
    v && isSizeIndex(v) && sizes ? (sizes as any)[v] : v
  return Array.isArray(size) ? size.map(getSize) : getSize(size)
}

export const themeFontIndexToFontSize = (
  theme: Theme,
  indices: string | number | (string | number)[]
): string | string[] => {
  return Array.isArray(indices)
    ? (indices.map(i =>
        resolveSizeValue(theme)(i as Size | number, 'fontSizes')
      ) as string[])
    : (resolveSizeValue(theme)(indices as Size | number, 'fontSizes') as string)
}

export const getNextSize = (
  fromSize: string | number | Empty,
  step = 0
): string | number | null | undefined => {
  if (fromSize === null) return null
  if (fromSize && !isSizeIndex(fromSize)) {
    if (typeof fromSize === 'number') {
      const retVal = fromSize + step * 1.5
      return retVal < 6 ? fromSize : retVal
    }
    return fromSize
  }
  const currentIndex = fromSize ? SizeIndex.indexOf(fromSize as Size) : -1
  const nextIndex = currentIndex + step
  if (nextIndex >= 0 && nextIndex <= SizeIndex.length - 1) {
    return SizeIndex[nextIndex]
  } else if (nextIndex < 0) {
    return SizeIndex[0]
  } else if (nextIndex > SizeIndex.length - 1) {
    return SizeIndex[SizeIndex.length - 1]
  }
  return SizeIndex[currentIndex]
}

export const getStepSize = (size: SizeProps, step = -1): SizeProps =>
  Array.isArray(size)
    ? size.map(i => getNextSize(i as Size, step))
    : getNextSize(size as Size, step)

export const resolveIconFromFontSize = (
  size: SizeProps,
  step = -1,
  theme: Theme = {}
): SizeProps =>
  resolveSize(
    Array.isArray(size)
      ? size.map(i => getNextSize(i as Size, step))
      : getNextSize(size as Size, step),
    get(theme, 'sizes.icon')
  )

export function toBaseUnit(
  subject: Record<string, string | string[]>,
  targetUnit: 'px' | 'rem' = 'px'
): Record<string, string | string[]> {
  const sourceUnit = targetUnit === 'px' ? 'rem' : 'px'
  function convert(value?: string | null) {
    if (typeof value === 'string' && value.includes(sourceUnit)) {
      const numberValue = toNumber(value)
      return isNumber(numberValue)
        ? `${
            targetUnit === 'rem' ? numberValue / 16 : numberValue * 16
          }${targetUnit}`
        : value
    }
    return value
  }
  return Object.keys(subject).reduce((acc, cur) => {
    const curField = subject[cur]
    return {
      ...acc,
      [cur]: Array.isArray(curField)
        ? curField.map(v => convert(v))
        : convert(curField)
    }
  }, {})
}

export function isValidUnit(value: unknown): boolean {
  return (
    isNumeric(value as never) &&
    typeof value === 'string' &&
    (value.endsWith('rem') || value.endsWith('em') || value.endsWith('px'))
  )
}
