import { isNumeric } from './assertion'

const toPx = (v: string | number): number => {
  const value = typeof v === 'string' && isNumeric(v) ? parseFloat(`${v}`) : +v
  const unit =
    typeof v === 'string' ? v.replace(/^([0-9]+\.?[0-9]*|\.[0-9]+)/g, '') : 'px'
  return unit === 'px'
    ? value
    : unit === 'rem' || unit === 'em'
      ? value * 16
      : value
}

export default toPx
