/* eslint-disable @typescript-eslint/no-explicit-any */
import { safeColor } from './utils'
import type { ThemeUICSSObject } from '../../theme-provider/types'

export type GradientsThemeKey = 'basic' | 'primary'

function getGradients(
  colors: any
): Record<
  GradientsThemeKey,
  Partial<Pick<ThemeUICSSObject, 'background' | 'bg'>>
> {
  return {
    basic: {
      background: `linear-gradient(90deg, transparent, ${colors.neutrals[17]}, transparent)`
    },
    primary: {
      background: `linear-gradient(90deg, transparent, ${safeColor(
        colors.primary
      )}, transparent)`
    }
  }
}

export default getGradients
