import { THEME_UI_DEFAULT_KEY } from '../../theme-provider/types'

const common = {
  borderRadius: 'pill',
  fontSize: 'xs',
  '> .csg-tui-text': {
    emPaddingX: 'xs'
  },
  '.csg-tui-button': {
    width: 'initial',
    margin: 'initial',
    pr: 'xs',
    '.csg-tui-icon': {
      display: 'initial'
    }
  },
  '.csg-tui-tag': {
    gap: 'unset',
    px: 'unset',
    alignItems: 'baseline'
  }
}

const tagCommon = {
  py: 'xs-3',
  textTransform: 'uppercase',
  color: 'white',
  fontWeight: 'bold',
  fontSize: 'xs-3',
  height: '2em'
}

export default {
  [THEME_UI_DEFAULT_KEY]: {
    border: 'neutral.medium',
    bg: 'neutral.light',
    ...common
  },
  primary: {
    [THEME_UI_DEFAULT_KEY]: {
      border: 'primary',
      bg: 'primary',
      color: 'white',
      ...common
    },
    outline: {
      variant: `tags.${THEME_UI_DEFAULT_KEY}`,
      border: 'primary',
      bg: 'primary.light',
      color: 'primary',
      ...common
    }
  },
  neutral: {
    //Cosmos V1
    [THEME_UI_DEFAULT_KEY]: {
      border: 'neutral',
      bg: 'neutral',
      ...common
    },
    outline: {
      border: 'neutral',
      bg: 'neutral.lighter',
      ...common
    },
    lighter: {
      border: 'neutral.lighter',
      bg: 'neutral.lighter',
      ...common,
      '.csg-tui-icon': {
        color: 'primary'
      }
    }
  },
  basic: {
    //Cosmos V2,
    [THEME_UI_DEFAULT_KEY]: {
      bg: 'primary.medium',
      ...tagCommon
    },
    highlight: {
      bg: 'highlight18',
      ...tagCommon
    }
  }
}
