/* eslint-disable @typescript-eslint/no-explicit-any */
import type { styleFn } from 'styled-system'

import { defaults, resolveMargin } from './space'
import { compose, system } from './system-core'
import { memoizedGet } from '../utils/object'
import { resolveSizeValue } from '../utils/size-utils'

export const emMarginTransform = (n: number, scale: any, props: any): any => {
  const v = resolveSizeValue(props.theme || props)(n, 'sizes.space')
  return defaults.space !== scale
    ? resolveMargin(v, scale, props)
    : props.theme && Array.isArray(props.theme.space)
      ? resolveMargin(v, props.theme.space, props)
      : resolveMargin(v, defaults.space, props)
}

export const emPaddingTransform = (n: number, scale: any, props: any): any => {
  const v = resolveSizeValue(props.theme || props)(n, 'sizes.space')
  return defaults.space !== scale
    ? memoizedGet(scale, v, v)
    : props.theme && Array.isArray(props.theme.space)
      ? memoizedGet(props.theme.space, v, v)
      : memoizedGet(defaults.space, v, v)
}

const configs: any = {}
configs.emMargin = {
  emMargin: {
    property: 'margin',
    scale: 'emSpace',
    transform: emMarginTransform,
    defaultScale: defaults.space
  },
  emMarginTop: {
    property: 'marginTop',
    scale: 'emSpace',
    transform: emMarginTransform,
    defaultScale: defaults.space
  },
  emMarginRight: {
    property: 'marginRight',
    scale: 'emSpace',
    transform: emMarginTransform,
    defaultScale: defaults.space
  },
  emMarginBottom: {
    property: 'marginBottom',
    scale: 'emSpace',
    transform: emMarginTransform,
    defaultScale: defaults.space
  },
  emMarginLeft: {
    property: 'marginLeft',
    scale: 'emSpace',
    transform: emMarginTransform,
    defaultScale: defaults.space
  },
  emMarginX: {
    properties: ['marginLeft', 'marginRight'],
    scale: 'emSpace',
    transform: emMarginTransform,
    defaultScale: defaults.space
  },
  emMarginY: {
    properties: ['marginTop', 'marginBottom'],
    scale: 'emSpace',
    transform: emMarginTransform,
    defaultScale: defaults.space
  }
}

configs.emPadding = {
  emPadding: {
    property: 'padding',
    scale: 'emSpace',
    transform: emPaddingTransform,
    defaultScale: defaults.space
  },
  emPaddingTop: {
    property: 'paddingTop',
    scale: 'emSpace',
    transform: emPaddingTransform,
    defaultScale: defaults.space
  },
  emPaddingRight: {
    property: 'paddingRight',
    scale: 'emSpace',
    transform: emPaddingTransform,
    defaultScale: defaults.space
  },
  emPaddingBottom: {
    property: 'paddingBottom',
    scale: 'emSpace',
    transform: emPaddingTransform,
    defaultScale: defaults.space
  },
  emPaddingLeft: {
    property: 'paddingLeft',
    scale: 'emSpace',
    transform: emPaddingTransform,
    defaultScale: defaults.space
  },
  emPaddingX: {
    properties: ['paddingLeft', 'paddingRight'],
    scale: 'emSpace',
    transform: emPaddingTransform,
    defaultScale: defaults.space
  },
  emPaddingY: {
    properties: ['paddingTop', 'paddingBottom'],
    scale: 'emSpace',
    transform: emPaddingTransform,
    defaultScale: defaults.space
  }
}

export const emMargin: styleFn = system(configs.emMargin)
export const emPadding: styleFn = system(configs.emPadding)
export const emSpace = compose(emMargin, emPadding)

export default emSpace
