import { THEME_UI_DEFAULT_KEY } from '../../theme-provider/types'

export const scrollbarWidth = 12

const containerScrollbar = {
  scrollbar: {
    '-webkit-appearance': 'none',
    backgroundClip: 'content-box',
    width: '16px',
    height: '16px'
  },
  track: {
    bg: 'transparent'
  },
  thumb: {
    borderRadius: 'pill'
  }
}

export const scrollbar = {
  basic: {
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      backgroundClip: 'content-box',
      border: '1px solid transparent',
      borderRadius: `${scrollbarWidth / 2}px`,
      width: `${scrollbarWidth}px`,
      height: `${scrollbarWidth}px`
    },
    '&::-webkit-scrollbar-track': {
      bg: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: `${scrollbarWidth / 2}px`,
      boxShadow: `inset 0 0 0 ${scrollbarWidth / 2}px #bfbfbf`,
      border: '2px solid transparent'
    }
  },
  transition: {
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      backgroundClip: 'content-box',
      border: '1px solid transparent',
      borderRadius: `${scrollbarWidth / 2}px`,
      width: `${scrollbarWidth}px`,
      height: `${scrollbarWidth}px`
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      boxShadow: `inset 0 0 0 ${scrollbarWidth / 2}px transparent`,
      borderRadius: `${scrollbarWidth / 2}px`,
      border: '2px solid transparent'
    },
    '&:hover::-webkit-scrollbar-thumb': {
      boxShadow: `inset 0 0 0 ${scrollbarWidth / 2}px #bfbfbf`
    }
  },
  container: {
    [THEME_UI_DEFAULT_KEY]: {
      '&::-webkit-scrollbar': {
        ...containerScrollbar.scrollbar,
        bg: 'white'
      },
      '&::-webkit-scrollbar-track': containerScrollbar.track,
      '&::-webkit-scrollbar-thumb': {
        ...containerScrollbar.thumb,
        bg: 'neutral',
        border: 'white.thicker'
      }
    },
    'lighter-bg': {
      '&::-webkit-scrollbar': {
        ...containerScrollbar.scrollbar,
        bg: 'neutral.lighter'
      },
      '&::-webkit-scrollbar-track': containerScrollbar.track,
      '&::-webkit-scrollbar-thumb': {
        ...containerScrollbar.thumb,
        bg: 'neutral',
        border: 'neutral.lighter.thicker'
      }
    },
    'light-bg': {
      '&::-webkit-scrollbar': {
        ...containerScrollbar.scrollbar,
        bg: 'neutral.light'
      },
      '&::-webkit-scrollbar-track': containerScrollbar.track,
      '&::-webkit-scrollbar-thumb': {
        ...containerScrollbar.thumb,
        bg: 'neutral.medium',
        border: 'neutral.light.thicker'
      }
    }
  },
  'text-area': {
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      backgroundClip: 'content-box',
      width: '12px',
      bg: 'white'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      bg: 'neutral.medium',
      borderRadius: '6px',
      border: '3px solid white'
    }
  }
}

export type ScrollbarThemeKeys = keyof typeof scrollbar
