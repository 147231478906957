export const radii_px = {
  none: 0,
  default: '2px',
  sm: '4px',
  md: '6px',
  lg: '8px',
  pill: '50rem'
}

export const radii = radii_px

export const radii_rem = {
  none: 0,
  default: '0.125rem',
  sm: '0.25rem',
  md: '0.375rem',
  lg: '0.5rem',
  pill: '50rem'
}

export type RadiiThemeKeys = keyof typeof radii

export default function (
  unit: 'px' | 'rem' = 'px'
): Record<RadiiThemeKeys, string | number> {
  return unit === 'px' ? radii_px : radii_rem
}
