import { useMemo } from 'react'
import type { ComponentType } from 'react'

import createComponent, {
  createComponents
} from '../styles-system/create-component'

/**
 * Creates a hook that can be used to create styled components.
 *
 * Provide this factory with a list of default tags to create on app startup
 * time. Then, when the hook is called with one of these tags, it will return a pre-created
 * styled component instead of creating a new one on the fly. Be sparing with
 * how many tags you provide, as each will occupy space in memory for the
 * entirety of the app's lifecycle.
 *
 * @example
 * ```
 * import { createUseStyledComponent } from '../../hooks'
 * // make sure you create the hook _outside_ of the component
 * const useStyledComponent = createUseStyledComponent(['div', 'span'], 'MyStyledComponent')
 *
 * export default function MyComponent({
 *   as = 'div',
 *  ...props
 * }) {
 *     // When 'as' is one of the default components, this hook will use an already-created
 *     // styled component. Otherwise, it will create a new one and memoize it.
 *     const StyledComponent = useStyledComponent(as)
 *
 *     return <StyledComponent {...props} />
 * }
 * ```
 *
 * @param tags The list of default tags to create styled components for
 * @param displayName An optional display name to apply to the returned component
 * @param defaultVariants Variants to apply when creating the component. If the
 * hook is called with a different variant object, including `undefined` when
 * this is provided, the hook will create a new component with the new variants.
 * @returns A hook for creating styled components
 *
 * @internal
 */
export function createUseStyledComponent(
  tags: ReadonlyArray<keyof JSX.IntrinsicElements>,
  displayName?: string,
  defaultVariants?: Record<string, unknown>
) {
  const MemoComponents = createComponents(tags, displayName, defaultVariants)

  return function useStyledComponent(
    as: keyof JSX.IntrinsicElements | ComponentType<unknown>,
    variants?: object
  ): ReturnType<typeof createComponent> {
    return useMemo(
      () =>
        (Object.is(defaultVariants, variants) &&
          typeof as === 'string' &&
          MemoComponents[as]) ||
        createComponent(as, displayName),
      [as, variants]
    )
  }
}
