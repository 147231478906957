import type { ThemeUICSSObject } from '../../theme-provider/types'
import {
  ellipsisExpandResponsiveFontSizes,
  standardResponsiveSizes
} from '../const'

export const text: Record<string, ThemeUICSSObject> = {
  caps: {
    textTransform: 'uppercase',
    letterSpacing: 'caps'
  },
  heading: {
    fontFamily: 'heading',
    fontWeight: 'heading',
    lineHeight: 'heading'
  },
  body: {
    fontFamily: 'body',
    fontWeight: 'body',
    lineHeight: 'body'
  },
  display: {
    // extends the text.body styles
    variant: 'text.body',
    fontSize: standardResponsiveSizes.slice(0, 3),
    fontWeight: 'body'
  },
  ellipsisExpand: {
    variant: 'styles.a',
    fontSize: ellipsisExpandResponsiveFontSizes
  },
  news: {
    fontFamily: 'news',
    lineHeight: 1.25
  }
}

export type TextThemeVariants = keyof typeof text
