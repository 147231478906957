import { defaults, getSpacing } from './space'
import { system } from './system-core'

const config = {
  gap: {
    property: 'gap',
    scale: 'space',
    transform: getSpacing,
    defaultScale: defaults.space
  },
  columnGap: {
    property: 'columnGap',
    scale: 'space',
    transform: getSpacing,
    defaultScale: defaults.space
  },
  rowGap: {
    property: 'rowGap',
    scale: 'space',
    transform: getSpacing,
    defaultScale: defaults.space
  },
  gridColumn: true,
  gridRow: true,
  gridAutoFlow: true,
  gridAutoColumns: true,
  gridAutoRows: true,
  gridTemplateColumns: true,
  gridTemplateRows: true,
  gridTemplateAreas: true,
  gridArea: true
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any

config.gridGap = config.gap
config.gridColumnGap = config.columnGap
config.gridRowGap = config.rowGap

export const grid = system(config)
export default grid
