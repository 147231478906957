export const elevations = {
  100: 4,
  200: 8,
  300: 12,
  400: 24,
  500: 36
}

export type ElevationThemeKeys = keyof typeof elevations

export function isElevationThemeKeys(
  key?: number | string
): key is ElevationThemeKeys {
  return Object.keys(elevations).includes(`${key}`)
}

export function assertIsElevationThemeKeys(
  key: number | string,
  shouldThrow = false
): asserts key is ElevationThemeKeys {
  if (!isElevationThemeKeys(key) && shouldThrow) {
    throw new Error('Not a DemographicDataVariableKey!')
  }
  return
}
