/* eslint-disable @typescript-eslint/no-explicit-any */
// port from @styled-system/typography v.5.1.2
import { system } from './system-core'
import { memoizedGet as get } from '../utils/object'
import { resolveSizeValue } from '../utils/size-utils'

const defaults = {
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72]
}

export const getFontSize = (n: any, scale: any, props: any): any => {
  const v = resolveSizeValue(props.theme || props)(n, 'sizes.font')

  return get(scale, v, v)
}

const config = {
  fontFamily: {
    property: 'fontFamily',
    scale: 'fonts'
  },
  fontSize: {
    property: 'fontSize',
    scale: 'fontSizes',
    transform: getFontSize,
    defaultScale: defaults.fontSizes
  },
  fontWeight: {
    property: 'fontWeight',
    scale: 'fontWeights'
  },
  lineHeight: {
    property: 'lineHeight',
    scale: 'lineHeights'
  },
  letterSpacing: {
    property: 'letterSpacing',
    scale: 'letterSpacings'
  },
  textAlign: true,
  fontStyle: true
}

export const typography = system(config)
export default typography
